import { makeAutoObservable, flow } from "mobx";
import { makePersistable } from 'mobx-persist-store';


//#################################################################################################

class generalStore {

    error = "";
    processing = [];
    boxScreenMode = true;
    adminPanelActiveTab = "1";
    sensorsPanelActiveTab = "1";
    dataLoaderPanelActiveTab = "1";
    controlPanelActiveTab = "1";

    //------------------------------------------------------------------------------------
    constructor() {
        makeAutoObservable(this, { autoBind: true });

        makePersistable(this, { name: 'generalStore', properties: ['processing', 'boxScreenMode', 'error'], storage: window.sessionStorage });
    }    
      
    //------------------------------------------------------------------------------------
    doActionGeneralError = flow(function*(error) {
        this.error =  error;
    });

    //------------------------------------------------------------------------------------
    doActionRemoveGeneralError = flow(function*() {
        this.error =  "";
    });
    
    //------------------------------------------------------------------------------------
    doActionStartService = flow(function*(service) {    
        this.processing = [...this.processing, service];        
    });

    //------------------------------------------------------------------------------------
    doActionEndService = flow(function*(service) {
        this.processing = this.processing.filter(item => item !== service);
    });

    //------------------------------------------------------------------------------------
    doActionSetAdminPanelActiveTab = flow(function*(panel) {
        this.adminPanelActiveTab = panel;
    });

    //------------------------------------------------------------------------------------
    doActionSetSensorsPanelActiveTab = flow(function*(panel) {
        this.sensorsPanelActiveTab = panel;
    });
    
    //------------------------------------------------------------------------------------
    doActionSetDataLoaderPanelActiveTab = flow(function*(panel) {
        this.dataLoaderPanelActiveTab = panel;
    });

    //------------------------------------------------------------------------------------
    doActionSetControlPanelActiveTab = flow(function*(panel) {
        this.controlPanelActiveTab = panel;
    });

    //------------------------------------------------------------------------------------
    doActionChangeScreenMode = flow(function*(boxMode) {
        this.boxScreenMode = boxMode;
    });

}

export default new generalStore()