import React from 'react';
import { Button, Input, Row, Col, Select, Tooltip, DatePicker, message } from 'antd';
import { ConsoleSqlOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";

import "./SensorsStatsFilter.scss";

import esES from 'antd/es/date-picker/locale/es_ES';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

//#################################################################################################

class SensorsStatsFilter extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);

        let currentDate=moment(new Date(), "DD-MM-YYYY");

        this.state = {
            type: -1,
            data: "",
            device: "",
            area: -1,
            date1: currentDate,
            date2: currentDate
        }
    }

    //-----------------------------------------------------------------------------------------------
    componentDidMount() {

        this.props.devicesStore.doActionGetDeviceTypesList();  
        this.props.devicesStore.doActionGetAreasList();

        // eslint-disable-next-line
        if (this.props.sensorsStore.SensorsStatsFilter != undefined) {
            // eslint-disable-next-line
            let currentFilter = this.props.sensorsStore.SensorsStatsFilter;
            
            // eslint-disable-next-line
            if (currentFilter!=undefined) {
                this.setState(currentFilter);
            }
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleReset = () => {
        let currentDate=moment(new Date(), "DD-MM-YYYY");

        this.setState({ type: -1, data: "", device: "", area: -1, date1: currentDate, date2: currentDate }, () => { message.success("El filtro se ha eliminado"); this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleTypeChange = (data) => {
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ type: data.value }, () => { this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleDeviceChange = (event) => {
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ device: event.target.value }, () => { this.doSearch() });
    }
    
    //-----------------------------------------------------------------------------------------------
    handleDataChange = (event) => {
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ data: event.target.value }, () => { this.doSearch() });
    }
    
    //-----------------------------------------------------------------------------------------------
    handleAreaChange = (data) => {    
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ area: data.value }, () => { this.doSearch() });
    }
    
    //-----------------------------------------------------------------------------------------------
    handleDateRangeChange = (dates) => {
        this.setState({date1: dates[0], date2: dates[1]}, () => this.doSearch());
    }
        
    //-----------------------------------------------------------------------------------------------
    doSearch = () => {
        this.props.sensorsStore.doActionGetSensorsStats(this.state);
        window.scrollTo(0, 0);
    }

    //-----------------------------------------------------------------------------------------------
    render() { 
             
        const areas = this.props.devicesStore.areas;
        const types = this.props.devicesStore.deviceTypes;
        
        return (
            <>
                <Row justify="center" className="sensors-stats-filter">
                    <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 4}} className="text-option">
                        <span className="field">Dispositivo</span> <Search placeholder="Buscar por referencia de dispositivo" value={this.props.sensorsStore.sensorsFilter.device} onChange={this.handleDeviceChange} />
                    </Col>                            
                    <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6}} className="text-option">
                        <span className="field">Dato <Tooltip title={<>Permite filtrar por un dato del sensor, para lo cual hay que especificar el filtro de la forma:<br/>DATO CONDICION VALOR<br/><br/>Ejemplos:<br/><i>temperatura&gt;10</i><br/><i>humedad='75%'</i></>}><QuestionCircleOutlined/></Tooltip>
                        </span> <Search placeholder="Buscar en los datos" value={this.state.data} onChange={this.handleDataChange} />
                    </Col>   
                    <Col xs={{ span: 24 }} md={{ span: 7 }} xl={{ span: 4 }} className="select-option">
                        <span className="field">Tipo de dispositivo</span> 
                            <Select labelInValue defaultValue={{value: -1}} value={{value: this.state.type}} onChange={this.handleTypeChange} getPopupContainer={() => document.getElementById('footer')}>
                            <Option key="-1" value={-1}>Todos</Option>

                            {types.map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))}

                        </Select>
                    </Col>                                                                 
                    <Col xs={{ span: 24 }} md={{ span: 7 }} xl={{ span: 4 }} className="select-option">
                        <span className="field">Área geográfica</span> 
                            <Select labelInValue defaultValue={{value: -1}} value={{value: this.state.area}} onChange={this.handleAreaChange} getPopupContainer={() => document.getElementById('footer')}>
                            <Option key="-1" value={-1}>Todas</Option>

                            {areas.map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))}

                        </Select>
                    </Col>     
                    <Col xs={{ span: 24 }} md={{ span: 6}} xl={{ span: 4 }} className="date-option">
                        <span className="field">Período de fechas</span> 
                        <RangePicker picker="month" getPopupContainer={() => document.getElementById('footer')} locale={esES} format='MMM/YYYY' defaultValue={[this.state.date1, this.state.date2]} value={[this.state.date1, this.state.date2]} allowClear={false} onChange={this.handleDateRangeChange}/> 
                    </Col>
                    <Col xs={{ span: 24 }} className="delete-option">
                        <Button type="primary" shape="round" icon={<DeleteOutlined />} title="Eliminar filtro" onClick={this.handleReset}>Borrar</Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default inject('sensorsStore','devicesStore')(observer(SensorsStatsFilter))
