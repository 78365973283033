import React from 'react';
import { Progress, Empty, List, Spin, Col, message, Row, Button, Modal, Popover, Layout, Popconfirm, Tooltip, Image} from 'antd';
import { ReloadOutlined, WifiOutlined, DeploymentUnitOutlined, UserOutlined, VideoCameraOutlined, VideoCameraFilled, EnvironmentFilled, ExperimentOutlined, CloseCircleFilled, EnvironmentOutlined, CloseCircleOutlined, ManOutlined, WomanOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";
import Sticky from 'react-stickynode';
import { reaction } from "mobx";
import InfiniteScroll from 'react-infinite-scroll-component';

import "./Sensors.scss";
import ModalWithMap from "../components/ModalWithMap.js";
import * as Constants from "../constants";
import noImage from "../assets/images/no-image.png";
import errorImage from "../assets/images/error-image.png";
import queuedImage from "../assets/images/queued-image.png";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const { Content } = Layout;

//#################################################################################################

class Sensors extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);

        this.state = {            
            size: 0,
            selectedSensor: null,
            showMap: false,
            showDevice: false,
            loading: true,
            hasMore: true,  
            page: -1 // Inicialmente no hay ninguna página cargada
        };
        this.reactions = [];
    }
  
    //-----------------------------------------------------------------------------------------------
    componentDidMount() {
        
        const sensors = this.props.sensorsStore.sensorsFilter;

        // Esto se hace para evitar conflictos al aplicar un filtro por identificador de dispositivo dado que podrian producirse dos peticiones simultáneas, una por aplicar el filtro y otra por crear el componente        
        // Aún así, si se aplica un filtro y el numero de resultados es cero se repite la petición, pero es un caso especial que no afecta a nada y sólo pasa una vez
        if (sensors==undefined || sensors["list"]==undefined || sensors["list"].length==undefined || sensors["list"].length<=0) {
            this.handleInfiniteOnLoad();
        }

        // IMPORTANTE!
        // Usando reacciones de esta manera evitamos el uso de UNSAFE_componentWillReceiveProps(nextProps)
        this.reactions.push(
            reaction(
            () => this.props.sensorsStore.sensors,
            () => { 
                const sensors = this.props.sensorsStore.sensors;

                if (sensors!=undefined) {
                    let size=0;
        
                    if (sensors.list!=undefined) size=sensors.list.length;
                    this.setState({ size: size });
                
                    // eslint-disable-next-line
                    if (this.props.sensorsStore.resetSensorsList) {
                        this.setState({ page: -1 });
                    }

                    // eslint-disable-next-line
                    if (this.state.hasMore || this.state.page < sensors.page) {
                        this.setState({ loading: false, hasMore: sensors.hasMore, page: sensors.page });
                    }
                }
            })
        );
    }

    //-----------------------------------------------------------------------------------------------
    componentWillUnmount() {
        this.reactions.forEach((dispose) => dispose());
    }

    //-----------------------------------------------------------------------------------------------
    componentDidUpdate() {
        if (this.props.generalStore.error != undefined && this.props.generalStore.error.length > 0) {
            message.error(this.props.generalStore.error,6);
            this.props.generalStore.doActionRemoveGeneralError();            
        }
        else if (this.props.sensorsStore.message != undefined && this.props.sensorsStore.message.length > 0) {
            message.success(this.props.sensorsStore.message);
            this.props.sensorsStore.doActionRemoveMessage();            
            this.setState({selectedSensor: null });
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleInfiniteOnLoad = () => {

        // La primera vez que se crea el componente pasa por aquí...
        let sensorsFilter = {}

        // eslint-disable-next-line
        if (this.props.sensorsStore.sensorsFilter != undefined) {
            sensorsFilter = this.props.sensorsStore.sensorsFilter;
        }

        this.props.sensorsStore.doActionGetSensorsForOrganizationOfUser(false, sensorsFilter, this.state.page + 1);

        this.setState({ loading: true });
    }

    //-----------------------------------------------------------------------------------------------
    handleReplaySensor = (event, sensor) => {

        event.stopPropagation();

        this.props.sensorsStore.doActionReplaySensor(sensor.id);
    }

    //-----------------------------------------------------------------------------------------------
    handleShowDeviceDetail = (sensor) => {
        this.setState({showDevice: true, selectedSensor: sensor });
    }
 
    //-----------------------------------------------------------------------------------------------
    handleCloseDeviceDetail = () => {
        this.setState({showDevice: false, selectedSensor: null });
    };

    //-----------------------------------------------------------------------------------------------
    handleCloseMap = () => {
        this.setState({showMap: false, selectedSensor: null});
    };    
      
    //-----------------------------------------------------------------------------------------------
     handleShowMap = (event, sensor) => {

        event.stopPropagation();
        this.setState({showMap: true, selectedSensor: sensor });
    }

    //-----------------------------------------------------------------------------------------------
    handleRemoveSensor = (event, sensor) => {

        event.stopPropagation();

        this.props.sensorsStore.doActionRemoveSensor(sensor.id);
    }

    //-----------------------------------------------------------------------------------------------
    render() {  
        // ATENCION! Leemos aquí los mensajes de retorno para provocar que estoos eventos sean notificado cuando cambian, si no lo hacemos no recibe la notificación, aunque no los usemos para nada realmente en el render(), se usan en el componentDidUpdate()
        const errorObserver=this.props.generalStore.error; // Se asigna el valor de error a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad
        const messageObserver=this.props.sensorsStore.message; // Se asigna el valor de mensaje a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad

        const sensors = this.props.sensorsStore.sensors.list == undefined ? [] : this.props.sensorsStore.sensors.list;

        const isBoxScreenMode = this.props.generalStore.boxScreenMode;

        let position=null;
        let status=null;
        let markerText="";
        if (this.state.selectedSensor!=null) {
            position = [this.state.selectedSensor.device.gps_lat, this.state.selectedSensor.device.gps_lng];            
            status = this.state.selectedSensor.device.status;
            markerText = this.state.selectedSensor.id;
        }

        return (
            <>
                <div className="sensors">
                <Sticky  innerZ={80} top=".ant-tabs-nav">
                    <Row className={`list-screen-mode-header ${isBoxScreenMode ? 'invisible' : ''}`} >
                        <Col className="ellipsis" md={{ span: 5 }}>Id</Col> 
                        <Col className="ellipsis" md={{ span: 5 }}>Dispositivo</Col>
                        <Col className="ellipsis" md={{ span: 4 }}>Área</Col>
                        <Col className="ellipsis" md={{ span: 4 }}>Obtención</Col>
                        <Col className="ellipsis" md={{ span: 2 }}>Datos</Col>
                        <Col className="ellipsis" md={{ span: 3 }}>Fecha</Col>                                                    
                    </Row>
                </Sticky>

                <InfiniteScroll
                    dataLength={sensors.length}
                    next={this.handleInfiniteOnLoad}
                    scrollThreshold="60%"
                    hasMore={!this.state.loading && this.state.hasMore}
                >
                        <List locale={{ emptyText: (<Empty description="No hay resultados" image={Empty.PRESENTED_IMAGE_SIMPLE} />)}}
                            dataSource={sensors}
                            renderItem={item => (
                                <>
                                    <List.Item className={`${isBoxScreenMode ? 'as-box' : 'as-list'}`} key={item.id}>
                                        <Row className="list-item box-screen-mode" >
                                            <div className="data">
                                                <Row>
                                                    <Col xs={{ span: 24 }}>
                                                        <div className="text-data">
                                                            <p className="title"><DeploymentUnitOutlined /> DATO {item.id}</p> 
                                                            {this.props.usersStore.userLogged.role==Constants.USER_ROLE_SUPERUSER &&
                                                                <Popconfirm onClick={(e) => e.stopPropagation()} placement="topRight" title="¿Estás seguro de que quieres reprocesar este dato?" onCancel={(e) => e.stopPropagation()} onConfirm={(event) => { this.handleReplaySensor(event, item) }} okText="Si" cancelText="No">
                                                                    <Button className="replay-button" type="primary" shape="circle" size="middle" icon={<ReloadOutlined />} title="Reprocesar dato del sensor"/>
                                                                </Popconfirm>
                                                            }
                                                            <p className="device"><VideoCameraOutlined /> Dispositivo {item.device.ref} ({item.device.type.name})</p>
                                                            <div className="image">                                                                
                                                                {item.imageDetectionsFilePath &&
                                                                <>
                                                                    <div className="results">
                                                                        <Tooltip title={item.data.image_detections_esteril+' estériles ('+(100*item.data.image_detections_esteril/item.data.image_detections_total).toFixed(1)+'%)'}>
                                                                            <Progress strokeWidth={14} strokeColor="#FF0000" trailColor="#444444" width={30} type="dashboard" percent={100*item.data.image_detections_esteril/item.data.image_detections_total} format={() => <ExperimentOutlined/>} />
                                                                        </Tooltip>
                                                                        &nbsp;&nbsp;
                                                                        <Tooltip title={item.data.image_detections_silvestre+' silvestres ('+(100*item.data.image_detections_silvestre/item.data.image_detections_total).toFixed(1)+'%)'}>
                                                                            <Progress strokeWidth={14} strokeColor="#FFFF00" trailColor="#444444" width={30} type="dashboard" percent={100*item.data.image_detections_silvestre/item.data.image_detections_total} format={() => <ManOutlined/>} />
                                                                        </Tooltip>
                                                                        &nbsp;&nbsp;
                                                                        <Tooltip title={item.data.image_detections_hembra+' hembras ('+(100*item.data.image_detections_hembra/item.data.image_detections_total).toFixed(1)+'%)'}>
                                                                            <Progress strokeWidth={14} strokeColor="#9999FF" trailColor="#444444" width={30} type="dashboard" percent={100*item.data.image_detections_hembra/item.data.image_detections_total} format={() => <WomanOutlined/>} />
                                                                        </Tooltip>
                                                                    </div>
                                                                    <Image src={APP_CONFIG.IMAGES_REPOSITORY_URL+"/"+item.imageDetectionsFilePath+"?"+moment().startOf('year').seconds(item.saveDate)}/>
                                                                </>
                                                                }
                                                                {!item.imageDetectionsFilePath && item.data.image_process_status=="ERROR" &&
                                                                    <Image src={errorImage}/>
                                                                }
                                                                {!item.imageDetectionsFilePath && item.data.image_process_status=="QUEUED" &&
                                                                    <Image src={queuedImage}/>
                                                                }
                                                                {!item.imageDetectionsFilePath && !item.data.image_process_status &&
                                                                    <Image src={noImage}/>
                                                                }                                                                
                                                            </div>
                                                            {item.userCapture && <p className="capture-user"><UserOutlined /> Obtenido por {item.userCapture.name} {item.userCapture.surname}</p>}
                                                            {!item.userCapture && <p className="capture-user"><WifiOutlined /> Obtenido por transmisión automática</p>}
                                                            <p className="area">{item.device.area.name}</p>
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ span: 21 }}>
                                                        <div className="sensor-data">

                                                        {Object.keys(item.data).map(function(param) {
                                                            if (DATA_DICTIONARY[param]) {
                                                                if (DATA_DICTIONARY[param].values) return(
                                                                    <p title={param} key={param}>{DATA_DICTIONARY[param].name}: <b title={item.data[param]}>{DATA_DICTIONARY[param].values[item.data[param]]}</b></p>
                                                                    )
                                                                else
                                                                return(
                                                                    <p title={param} key={param}>{DATA_DICTIONARY[param].name}: {item.data[param]} {DATA_DICTIONARY[param].units}</p>
                                                                    )                    
                                                                }
                                                            else {
                                                                return(
                                                                    <p title={param} key={param}>{param}: {item.data[param]}</p>
                                                                )
                                                                }                                        
                                                            }
                                                        )}

                                                        </div>
                                                    </Col>
                                                    <Col className="options" xs={{ span: 3 }}>
                                                        <Button type="primary" shape="circle" title="Ver datos del dispositivo" size="middle" icon={<VideoCameraOutlined />} onClick={(event) => { this.handleShowDeviceDetail(item) }} />
                                                        <br/>
                                                        <Button type="primary" shape="circle" title="Ver mapa" size="middle" icon={<EnvironmentOutlined />} onClick={(event) => { this.handleShowMap(event, item) }}/>
                                                        <br/>
                                                        {this.props.usersStore.userLogged.role>=Constants.USER_ROLE_SUPERVISOR &&
                                                            <Popconfirm onClick={(e) => e.stopPropagation()} placement="topRight" title="¿Estás seguro de que quieres eliminar estos datos de sensores?" onCancel={(e) => e.stopPropagation()} onConfirm={(event) => { this.handleRemoveSensor(event, item) }} okText="Si" cancelText="No">
                                                                <Button type="primary" shape="circle" size="middle" icon={<CloseCircleOutlined />} title="Eliminar datos de sensores"/>
                                                            </Popconfirm>
                                                            }
                                                    </Col>     
                                                </Row>
                                                <Row className="dates">
                                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>Obtenido {item.captureDate == undefined ? "no consta" : new Date(item.captureDate).toLocaleDateString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</Col>
                                                        <Col xs={{ span: 24 }} md={{ span: 12 }} className="align-right">Guardado {item.saveDate == undefined ? "no consta" : new Date(item.saveDate).toLocaleDateString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</Col>
                                                </Row>
                                            </div>
                                        </Row>

                                        <Row className="list-item list-screen-mode" >
                                            <Col className="ellipsis" md={{ span: 5 }}>
                                                {item.imageDetectionsFilePath &&
                                                    <Image src={APP_CONFIG.IMAGES_REPOSITORY_URL+"/"+item.imageDetectionsFilePath+"?"+moment().startOf('year').seconds(item.saveDate)}/>
                                                }
                                                {!item.imageDetectionsFilePath && item.data.image_process_status=="ERROR" &&
                                                    <Image src={errorImage}/>
                                                }
                                                {!item.imageDetectionsFilePath && item.data.image_process_status=="QUEUED" &&
                                                    <Image src={queuedImage}/>
                                                }
                                                {!item.imageDetectionsFilePath && !item.data.image_process_status &&
                                                    <Image src={noImage}/>
                                                }                                             
                                                <DeploymentUnitOutlined /> {item.id}
                                            </Col> 
                                            <Col className="ellipsis" md={{ span: 5 }}>{item.device.ref}</Col>
                                            <Col className="ellipsis" md={{ span: 4 }}>{item.device.area.name}</Col>
                                            {item.userCapture && <Col className="ellipsis" md={{ span: 4 }}>{item.userCapture.name} {item.userCapture.surname}</Col>}
                                            {!item.userCapture && <Col className="ellipsis" md={{ span: 4 }}>Transmisión automática</Col>}
                                            <Col className="ellipsis" md={{ span: 2 }}>
                                                {item.data &&
                                                    <Popover placement="topLeft" content=
                                                        {Object.keys(item.data).map(function(param) {
                                                            if (DATA_DICTIONARY[param]) {
                                                                if (DATA_DICTIONARY[param].values) return(
                                                                    <span title={param} key={item.id}>{DATA_DICTIONARY[param].name}: <b title={item.data[param]}>{DATA_DICTIONARY[param].values[item.data[param]]}</b><br/></span>                                                                    
                                                                    )
                                                                else
                                                                return(
                                                                    <span title={param} key={item.id}>{DATA_DICTIONARY[param].name}: {item.data[param]} {DATA_DICTIONARY[param].units}<br/></span>                                                                    
                                                                    )                    
                                                                }
                                                            else {
                                                                return(
                                                                    <span title={param} key={item.id}>{param}: {item.data[param]}<br/></span>                                                                    
                                                                )
                                                                }                                        
                                                            }
                                                        )}>
                                                        <Button type="link">{Object.keys(item.data).length}</Button>
                                                    </Popover>
                                                }
                                            </Col>
                                            <Col className="ellipsis" md={{ span: 2 }}>{item.captureDate == undefined ? "no consta" : new Date(item.captureDate).toLocaleDateString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</Col>
                                            <Col className="options" md={{ span: 2 }}>
                                                <Button className="actionButton" type="link" title="Ver datos del dispositivo" onClick={() => { this.handleShowDeviceDetail(item) }}><VideoCameraFilled/></Button>
                                                <Button className="actionButton" type="link" title="Ver mapa" onClick={(event) => { this.handleShowMap(event, item) }}><EnvironmentFilled/></Button>
                                                {this.props.usersStore.userLogged.role>=Constants.USER_ROLE_SUPERVISOR &&
                                                    <Popconfirm onClick={(e) => e.stopPropagation()} placement="topRight" title="¿Estás seguro de que quieres eliminar estos datos de sensores?" onCancel={(e) => e.stopPropagation()} onConfirm={(event) => { this.handleRemoveSensor(event, item) }} okText="Si" cancelText="No">
                                                        <Button className="actionButton" type="link" shape="round" title="Eliminar datos de sensores"><CloseCircleFilled/></Button>
                                                    </Popconfirm>
                                                }
                                            </Col>
                                        </Row>
                                    </List.Item>
                                </>
                            )}
                        >                            
                            {this.state.loading && this.state.hasMore && (
                                <div className="loading-more">
                                    <Spin size="large" />
                                </div>
                            )}

                        </List>
                    </InfiniteScroll>
                </div>

                <Modal forceRender={true} className="sensors-modal-device-data" visible={this.state.showDevice} title="Datos del dispositivo en el momento de la captura" onCancel={this.handleCloseDeviceDetail}
                    footer={[
                        <Button key="back" onClick={this.handleCloseDeviceDetail}>
                        Cerrar
                        </Button>
                    ]}
                    >

                    <Content className="device-profile">

                     {this.state.selectedSensor &&
                     <>
                        <Row justify="center" align="top">

                            <Col className="device-profile-data" xs={{ span: 24 }} >
                                <div className="text-data">
                                    <p className="title"><VideoCameraOutlined /> DISPOSITIVO {this.state.selectedSensor.device.ref}</p> 
                                    <p className="name">{this.state.selectedSensor.device.type.name}</p>
                                    <p className="area">{this.state.selectedSensor.device.area.name}</p>
                                    <p className="status" style={{ color: `${Constants.DEVICE_STATUS_COLORS[this.state.selectedSensor.device.status]}`}}>&#11044; Estado {Constants.DEVICE_STATUS[this.state.selectedSensor.device.status]}</p>
                                    <div className="params">
                                        {this.state.selectedSensor.device.run_params &&
                                            Object.keys(this.state.selectedSensor.device.run_params).map(param => (
                                                <p key={param}>{param}: {this.state.selectedSensor.device.run_params[param]}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                                
                            </Col>
                        </Row>
                     </>
                     }
                    </Content>

                </Modal>

                <ModalWithMap position={position} status={status} title="Posición del sensor en el momento de la captura de datos" visible={this.state.showMap} markerText={markerText} closeMapFunction={this.handleCloseMap}/>
             </>
        );
    }
}

export default inject('sensorsStore','generalStore','usersStore')(observer(Sensors))
