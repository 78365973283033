import React from "react";
import { Switch, Layout, Row, Col, Form, Input, Button, message } from 'antd';
import { IdcardOutlined, LockOutlined, SyncOutlined, MailOutlined } from '@ant-design/icons';
import cookie from 'react-cookies';
import { observer, inject } from "mobx-react";
import { reaction } from "mobx";

import * as Actions from "../stores/actionFlags.js";

import AuthenticationService from '../services/AuthenticationService';
import * as Constants from "../constants";

import "./Login.scss"
import logoApp from "../assets/images/tiepanel-logo.png"
import logoCompany from "../assets/images/tragsatec-logo.png"
import logoDemeter from "../assets/images/demeter-logo.png"

const { Content } = Layout;

//#################################################################################################

class Login extends React.Component {

  //-----------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.rememberMe = false;

    this.state = {
      showRemindUser: false      
    }

    this.reactions = [];
  }

  //-----------------------------------------------------------------------------------------------
  componentDidMount() {
  
    // IMPORTANTE!
    // Es necesario programar aquí reacciones explicitas porque los cambios en los observables que usamos aquí debidos a hacer login no estan referenciados en el render y por tanto no provocan renders
    // Por ejemplo, cambiar meter mal el nombre del usuario provoca un mensaje de error pero no provoca ningun render, porque en la función render no se accede a ningun observable que cambie cuando eso sucede
    // Asi que no queda más remedio que programar las reacciones explicitamente de esta forma en estos casos

    // The observer HoC automatically subscribes React components to any observables that are used during rendering. 
    // So observer component will only react to observables referred in its render method, not in componentDidMount.

    this.reactions.push(
      reaction(
        () => this.props.usersStore.loggedIn,
        () => { 
          if (this.props.usersStore.loggedIn) {
            if (this.props.usersStore.userLogged.role==Constants.USER_ROLE_API || this.props.usersStore.userLogged.role==Constants.USER_ROLE_IOT) {
              message.error("El rol del usuario no permite el acceso a la consola, sólo algunas llamadas al API");
              this.props.usersStore.doActionLogout();
            }
            else
              // Si la contraseña tiene menos de 6 caracteres entonces solicitarla de nuevo (es un login inicial o se ha autogenerado)
              if (this.props.usersStore.passwordLength < 6) { 
                this.props.history.replace('/firstrun');
              }
              else {
                this.props.history.push('/sensorspanel');
              }
            }
        }
      ),
      reaction(
        () => this.props.generalStore.error || this.props.usersStore.sentUserEmailFlag,
        () => { 
          const sentUserEmailFlag = this.props.usersStore.sentUserEmailFlag;
          const errorMessage = this.props.generalStore.error;

          // eslint-disable-next-line
          if (errorMessage != undefined && errorMessage.length > 0) {

            // Ignorar los errores de tipo 401 que pueden provenir de otros componentes cuando se intentan entrar directamente a una URL no autorizada tecleándola
            if (errorMessage.indexOf("401")<=0) message.error(errorMessage);
            this.props.generalStore.doActionRemoveGeneralError();
          }
          else if (sentUserEmailFlag) {
            message.success("Se han enviado los nuevos datos de acceso al correo electrónico del usuario ");
            this.setState({ showRemindUser:false });
          }
        }
      )
    );
        
    if (!AuthenticationService.isUserLoggedIn()) {      
      let refreshToken = cookie.load(process.env.REACT_APP_COOKIE_REFRESHTOKEN);
      
      if (this.props.location.search.indexOf("&srrtk=")>=0) {
        refreshToken=this.props.location.search.substring(this.props.location.search.indexOf("&srrtk=")+7);
      }

      if (refreshToken != null && refreshToken.length>0) {
        this.props.usersStore.doActionRememberMe(refreshToken);
      }
    }
  }

  //-----------------------------------------------------------------------------------------------
  componentWillUnmount() {
    this.reactions.forEach((dispose) => dispose());
  }

  //-----------------------------------------------------------------------------------------------
  handleRemindUserSubmit = (values) => {
    this.props.usersStore.doActionRemindUser(values.email);
  }

  //-----------------------------------------------------------------------------------------------
  handleLoginSubmit = (values) => {
    this.props.usersStore.doActionLogin(values.username, values.password, this.rememberMe.toString());
  }

   //-----------------------------------------------------------------------------------------------
  handleRememberMeChange = (checked) => {

    this.rememberMe = checked;
  }

  //-----------------------------------------------------------------------------------------------
  render() {
    const isFetchingUser = this.props.generalStore.processing.includes(Actions.LOGIN);
    const isSendingUserEmail = this.props.generalStore.processing.includes(Actions.REMINDUSER);
    
    return (
      <>
        <Layout className="login-layout">
          <Content>
            <div>

              <Row justify="center" align="top">
                <Col className="center-content" xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}>
                  <img className="logo-app" src={logoApp} alt="Logo de TIEPANEL"></img>
                  <a href="https://www.tragsa.es/"><img className="logo-company" src={logoCompany} alt="Logo de Tragsatec"></img></a>
                  <h2 className="app-subtitle">Sistema de Control y Conteo de Insectos Estériles</h2>
                </Col>
              </Row>

              <Row justify="center" align="bottom" className={this.state.showRemindUser ? "invisible" : "visible"}>
                <Col xs={{ span: 18 }} sm={{ span: 12 }} xl={{ span: 8 }}>

                  <Form className="login-form" initialValues={{ remember: true }} onFinish={this.handleLoginSubmit} >

                    <Form.Item className="form-field" name="username" rules={[{ required: true, message: 'Introduce tu nombre de usuario' }]} >
                      <Input prefix={<IdcardOutlined className="login-form-icon" />} placeholder="Usuario" />
                    </Form.Item>

                    <Form.Item className="form-field" name="password" rules={[{ required: true, message: 'Introduce tu contraseña' }]} >
                      <Input.Password prefix={<LockOutlined className="login-form-icon" />} type="password" placeholder="Contraseña" />
                    </Form.Item>

                    <Form.Item className="form-field rememberme" >
                      Recordarme  &nbsp;
                      <Form.Item  name="rememberme"  valuePropName="checked" noStyle>
                        <Switch onChange={this.handleRememberMeChange} checkedChildren="si" unCheckedChildren="no" />
                      </Form.Item>
                    </Form.Item>
 
                   { <Form.Item >
                      {!isFetchingUser && <Button type="primary" htmlType="submit" className="login-form-button">ENTRAR</Button>}
                      {isFetchingUser && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA` }} className="login-form-button"><SyncOutlined spin /></Button>}
                    </Form.Item>} 

                  </Form>

                  <Row justify="center" align="bottom">
                      <Button type="link" className="bottom-link" onClick={() => this.setState({ showRemindUser: true })} >¿Has olvidado tu usuario o contraseña?</Button>
                  </Row>                    

                  <Row justify="center" align="bottom" className="bottom-company-logo">
                    <a href="https://www.tragsa.es/"><img src={logoCompany} alt="Logo de Tragsatec"></img></a>                   
                  </Row>   

                </Col>
              </Row>                    

              <Row justify="center" align="bottom" className={this.state.showRemindUser ? "visible" : "invisible"}>
                <Col xs={{ span: 18 }} sm={{ span: 12 }} xl={{ span: 8 }}>

                  <Form className="reminduser-form" initialValues={{ remember: true }} onFinish={this.handleRemindUserSubmit} >
                    <Form.Item className="form-field" name="email" rules={[{ required: true, type: 'email', message: 'Introduce un correo electrónico válido' }]} >
                      <Input prefix={<MailOutlined className="reminduser-form-icon" />} placeholder="Correo electrónico" />
                    </Form.Item>

                    <Form.Item>
                      {!isSendingUserEmail && <Button type="primary" htmlType="submit" className="remind-form-button">RECORDAR ACCESO</Button>}
                      {isSendingUserEmail && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA` }} className="remind-form-button"><SyncOutlined spin /></Button>}
                    </Form.Item>
                  </Form>

                  <Row justify="center" align="bottom">
                      <Button type="link" className="bottom-link" onClick={() => this.setState({ showRemindUser:false })} >Volver a la pantalla de entrada</Button>
                  </Row>   
                </Col>
              </Row>              


              <Row justify="center" align="top">                
                <Col className="center-content">
                  <br/>
                  <img className="logo-app" src={logoDemeter} alt="Logo de Demeter"></img>
                </Col>
              </Row>

            </div>

          </Content>

        </Layout>
      </>
    );
  }
}

export default inject('usersStore','generalStore')(observer(Login))
