import React from 'react';
import { Row, Col, Form, DatePicker, Input, Tooltip, Button, Modal, Layout, message } from 'antd';
import { QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import esES from 'antd/es/date-picker/locale/es_ES';
import { observer, inject } from "mobx-react";

import AjaxSelect from "./AjaxSelect.js";
import PictureManualUploader from "./PictureManualUploader";

import * as Actions from "../stores/actionFlags";

import "./SingleDataLoader.scss";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const { TextArea } = Input;
const { Content } = Layout;

//#################################################################################################

class SingleDataLoader extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);

        this.formFieldDevice = React.createRef();
        this.formFieldUser = React.createRef();
        this.formFieldImage = React.createRef();
        this.formData = React.createRef();

        this.state={
            showNoDeviceAlert: false,
            showNoUserAlert: false,
            showNoImageAlert: false
        }
    }
  
    //-----------------------------------------------------------------------------------------------
    componentDidUpdate() {

        if (this.props.generalStore.error != undefined && this.props.generalStore.error.length > 0) {
            message.error(this.props.generalStore.error,10);
            this.props.generalStore.doActionRemoveGeneralError();            
        }
        else if (this.props.sensorsStore.message != undefined && this.props.sensorsStore.message.length > 0) {
            message.success(this.props.sensorsStore.message,10);
            this.props.sensorsStore.doActionRemoveMessage();
        }
    }

    //-----------------------------------------------------------------------------------------------
    // ATENCION! Como los campos de 'dispositivo', 'usuario', etc son componentes personalizados, para poder acceder a su valor y validarlos es necesario recurrir a un truco.
    // Lo que hacemos es poner un botón que no hace submit del formulario, si no que comprueba primero los valores de los campos y si son válidos entonces si hace submit.
    // Para acceder a los valores de los campos se referencian estos mediante 'ref' en el formulario y luego se accede a su valor con un metodo 'getFieldValue' programado expresamente dentro de esos componentes.
    handleSubmitButtonClick = () => {
        const device=this.formFieldDevice.current.getFieldValue();
        const user=this.formFieldUser.current.getFieldValue();
        let image=this.formFieldImage.current.getFieldValue();
        
        if (image!=undefined && image.length>0 && image[0].type!="image/jpeg") image=undefined;

        this.setState({ showNoDeviceAlert: (device==undefined), showNoUserAlert: (user==undefined), showNoImageAlert: (image==undefined)})

        let data=this.formData.current.getFieldValue("data");

        try {
            if (data!=null) {
                JSON.parse("{"+data+"}");
            }

            if (device!=undefined && user!=undefined && image!=undefined && this.formData!=undefined) this.formData.current.submit();
        }
        catch (error) {
            message.error("El formato del campo 'datos de sensores adicionales' no es correcto, debe ser un JSON válido");
        }   
    }
    
    //-----------------------------------------------------------------------------------------------
    handleDataSubmit = (values) => {
        
        const formData = new FormData();
        formData.append('user_capture',this.formFieldUser.current.getFieldValue());
        formData.append('date_capture',values.date_capture.format());
        formData.append('image',this.formFieldImage.current.getFieldValue()[0].originFileObj);
        if (values.data!=undefined) formData.append('data',values.data);

        this.props.sensorsStore.doActionUploadSingleSensorData(this.formFieldDevice.current.getFieldValue(), formData);       
        this.formData.current.resetFields()  
    }

    //-----------------------------------------------------------------------------------------------
    render() {        
        // ATENCION! Leemos aquí los mensajes de retorno para provocar que estoos eventos sean notificado cuando cambian, si no lo hacemos no recibe la notificación, aunque no los usemos para nada realmente en el render(), se usan en el componentDidUpdate()
        const errorObserver=this.props.generalStore.error; // Se asigna el valor de error a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad
        const messageObserver=this.props.sensorsStore.message; // Se asigna el valor de mensaje a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad

        const isChangingData = this.props.generalStore.processing.includes(Actions.LOAD_SINGLE_SENSOR_DATA);

        return (        
            <>
                <div className="single-data-loader">
                    <Form ref={this.formData} onFinish={this.handleDataSubmit} layout="vertical">
                        <Row justify="center" align="top">
                            <Col xs={{ span: 22 }} sm={{ span: 18 }} xl={{ span: 10 }} >

                                <div className="description">
                                    <p className="text">Utiliza este formulario para cargar los datos de sensores de un dispositivo individual.</p>                    
                                </div>

                                <Form.Item name="device" label="Dispositivo" className="required">
                                    <AjaxSelect ref={this.formFieldDevice} placeholder="Selecciona el dispositivo" callbackUrl={APP_CONFIG.BACKEND_API_URL+"device/list/for-user-organization/?_orderby=ref_ASC&ref="} optionGenerator={function(item){ return(<Option key={item.id}>{item.ref}</Option>) }}/>
                                    {this.state.showNoDeviceAlert && <div className="ant-form-item-explain"><div role="alert" className="ant-form-item-explain-error">Selecciona el dispositivo</div></div>}
                                </Form.Item>

                                <Form.Item name="user_capture" label="Usuario que recogió la muestra" className="required">
                                    <AjaxSelect ref={this.formFieldUser} placeholder="Selecciona el usuario" callbackUrl={APP_CONFIG.BACKEND_API_URL+"user/list/for-user-organization/?showdisabled=false&_orderby=surname_ASC&text="} optionGenerator={function(item){ return(<Option key={item.id}>{item.name+" "+item.surname+" ("+item.username+")"}</Option>) }}/>
                                    {this.state.showNoUserAlert && <div className="ant-form-item-explain"><div role="alert" className="ant-form-item-explain-error">Selecciona el usuario</div></div>}
                                </Form.Item>

                                <Form.Item name="date_capture" label="Fecha y hora en que se recogió la muestra" rules={[{ required: true, message: 'Selecciona la fecha y hora' }]}>
                                    <DatePicker showTime={true} showNow={true} locale={esES} format='DD/MM/YYYY HH:mm:ss'/>
                                </Form.Item>

                                <Form.Item name="image" label="Imagen de la muestra en JPG" className="required">
                                    <PictureManualUploader ref={this.formFieldImage} maxFiles={1} extension=".jpg"/>
                                    {this.state.showNoImageAlert && <div className="ant-form-item-explain"><div role="alert" className="ant-form-item-explain-error">Selecciona la imagen en formato JPG</div></div>}
                                </Form.Item>

                                <Form.Item name="data" label={<>Datos de sensores adicionales en JSON&nbsp;<Tooltip title={<>Especificar los diferentes datos en formato JSON válido.<br/>Ejemplo:<br/><i>"temperatura":16,</i><br/><i>"humedad":"72%"</i></>}><QuestionCircleOutlined/></Tooltip></>}>
                                    <TextArea  autoSize={{ minRows: 4, maxRows: 20 }} allowClear className="data" >
                                    </TextArea>
                                </Form.Item>

                                <Form.Item className="center-content">
                                    <Button key="submit" type="primary" loading={isChangingData} disabled={isChangingData} shape="round" size="middle" className="submit-form-button" onClick={this.handleSubmitButtonClick}>
                                        CARGAR DATOS
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <Modal forceRender={false}  destroyOnClose={true} className="single-data-loader-modal-loading" visible={isChangingData} centered={true} closable={false} footer={null} title="Cargando datos de sensores">

                    <Content className="modal-loading-content">
                        <div className="loading">
                            <p className="icon">
                                <LoadingOutlined />
                            </p>
                            <p className="text">Procesando imagen y datos, espere por favor....</p>
                        </div>
                    </Content>

                </Modal>    
            </>    
        );
    }
}

export default inject('sensorsStore','generalStore')(observer(SingleDataLoader))
