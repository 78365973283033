import React from 'react';
import { Button, Input, Row, Col, Select, Switch, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";

import "./UsersManagementFilter.scss";
import * as Constants from "../constants";

const { Option } = Select;
const { Search } = Input;

//#################################################################################################

class UsersManagementFilter extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);

        this.state = {
            text: "",
            role: -1,
            showdisabled: false,
        }
    }

    //-----------------------------------------------------------------------------------------------
    componentDidMount() {

        // eslint-disable-next-line
        if (this.props.usersStore.usersFilter != undefined) {
            // eslint-disable-next-line
            let currentFilter = this.props.usersStore.usersFilter;
            
            // eslint-disable-next-line
            if (currentFilter!=undefined) {
                this.setState(currentFilter);
            }
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleReset = () => {
        this.setState({ text: "", role: -1, showdisabled: false }, () => { message.success("El filtro se ha eliminado"); this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleRoleChange = (data) => {    
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ role: data.value }, () => { this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleTextChange = (event) => {
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ text: event.target.value }, () => { this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleShowDisabledChange = (checked) => {
        this.setState({showdisabled: checked}, () => this.doSearch());
    }
    
    //-----------------------------------------------------------------------------------------------
    doSearch = () => {
        this.props.usersStore.doActionGetUsersForOrganizationOfUser(true, this.state, 0);
        window.scrollTo(0, 0);
    }

    //-----------------------------------------------------------------------------------------------
    render() { 
        const currentUser = this.props.usersStore.userLogged;
             
        return (
            <>
                <Row justify="center" className="users-management-filter">
                    <Col xs={{ span: 24 }} md={{ span: 10 }} xl={{ span: 8}} className="text-option">
                        <span className="field">Texto</span> <Search placeholder="Buscar por texto" value={this.state.text} onChange={this.handleTextChange} />
                    </Col>                            
                    <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 6 }} className="role-option">
                        <span className="field">Rol</span> 
                            <Select labelInValue defaultValue={{value: -1}} value={{value: this.state.role}} onChange={this.handleRoleChange} getPopupContainer={() => document.getElementById('footer')}>
                            <Option key="-1" value={-1}>Todos</Option>

                            {Constants.USER_ROLES.map(function(item) {
                                            if (Constants.USER_ROLES.indexOf(item)<Constants.USER_ROLE_SUPERUSER || currentUser.role==Constants.USER_ROLE_SUPERUSER) return(
                                                <Select.Option key={Constants.USER_ROLES.indexOf(item)} value={Constants.USER_ROLES.indexOf(item)}>{item}</Select.Option>
                                            )
                                            }
                            )}

                        </Select>
                    </Col>   
                    <Col xs={{ span: 24 }} md={{ span: 4 }} xl={{ span: 4 }} className="switch-option">
                                <span className="field">Ver desactivados</span> <Switch checked={this.state.showdisabled} checkedChildren="si" unCheckedChildren="no" onChange={this.handleShowDisabledChange}/>
                            </Col>   
                    <Col xs={{ span: 24 }} className="delete-option">
                        <Button type="primary" shape="round" icon={<DeleteOutlined />} title="Eliminar filtro" onClick={this.handleReset}>Borrar</Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default inject('usersStore')(observer(UsersManagementFilter))
