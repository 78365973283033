import React from "react";
import { Layout, Row, Col, Form, Input, Button, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";
import { reaction } from "mobx";

import "./FirstRun.scss"
import logoApp from "../assets/images/tiepanel-logo.png"
import logoCompany from "../assets/images/tragsatec-logo.png"

const { Content } = Layout;

//#################################################################################################

class FirstRun extends React.Component {

  //-----------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.reactions = [];
  }

  //-----------------------------------------------------------------------------------------------
  componentDidMount() {
    this.reactions.push(
      reaction(
        () => this.props.generalStore.error || this.props.usersStore.changedPasswordFlag,
        () => { 
          const changedPasswordFlag = this.props.usersStore.changedPasswordFlag;
          const errorMessage = this.props.generalStore.error;

            /*eslint eqeqeq: 0*/
          if (errorMessage != undefined && errorMessage.length > 0) {
            message.error(errorMessage);
            this.props.generalStore.doActionRemoveGeneralError();
          }
          else if (changedPasswordFlag)
          {
            message.success("Se ha modificado la contraseña2");
            this.props.history.push('/sensorspanel');
          }
        }
      )
    );  
  }

  //-----------------------------------------------------------------------------------------------
  componentWillUnmount() {
    this.reactions.forEach((dispose) => dispose());
  }

  //-----------------------------------------------------------------------------------------------
  handleChangePasswordSubmit = (values) => {
    var validation = true;

    /*eslint eqeqeq: 0*/
    if (values.password != values.repeat_password)  
    {
      message.error("Los valores de la contraseña no coinciden");
      validation = false;
    }
      
    if (!validation)
      return;
    
    this.props.usersStore.doActionChangePassword(values.password);
  }
  
  //-----------------------------------------------------------------------------------------------
  render() {
    const isChangingPassword = this.props.usersStore.isChangingPassword;

    //Volver a la pantalla de inicio si no se está logeado
    const isLoggedIn = this.props.usersStore.loggedIn;

    if (!isLoggedIn) {
      this.props.history.push('/'); 
    }

    return (
      <>
        <Layout className="firstrun-layout">
          <Content>

            <Row justify="center" align="top">
                <Col className="center-content" xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}>
                  <img className="logoApp" src={logoApp} alt="Logo de TIEPANEL"></img>
                  <a href="https://www.tragsa.es/"><img className="logoCompany" src={logoCompany} alt="Logo de Tragsatec"></img></a>
                  <h2 className="app-subtitle">Sistema Inteligente de Reconocimiento de Imágenes de Cultivo</h2>
                </Col>
            </Row>

            <Row justify="center" align="bottom">
              <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 16 }} lg={{ span: 12 }}>

                <Form className="firstrun-form" initialValues={{ remember: true }} onFinish={this.handleChangePasswordSubmit} >

                <p className="app-hello">Hola {this.props.usersStore.userLogged.name}, ahora puedes cambiar tu contraseña autogenerada por una que te resulte más cómoda...</p>

                  <Form.Item className="form-field" name="password" rules={[{ required: true, message: 'Introduce tu nueva contraseña' }, { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }]} >
                    <Input prefix="Nueva contraseña" type="password" placeholder="Nueva contraseña" autoComplete="new-password"/>
                  </Form.Item>

                  <Form.Item className="form-field" name="repeat_password" rules={[{ required: true, message: 'Repite tu nueva contraseña' }, { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }]} >
                    <Input prefix="Repetir contraseña" type="password" placeholder="Repita la contraseña" autoComplete="new-password"/>
                  </Form.Item>

                  <Form.Item>
                    {!isChangingPassword && <Button type="primary" htmlType="submit" className="login-form-button">CAMBIAR CONTRASEÑA</Button>}
                    {isChangingPassword && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA` }} className="login-form-button"><SyncOutlined spin /></Button>}
                  </Form.Item>
                  
                </Form>

              </Col>
            </Row>
          </Content>
          
        </Layout>
      </>
    );
  }
}

export default  inject('usersStore','generalStore')(observer(FirstRun))
