import { makeAutoObservable, flow } from "mobx";
import { makePersistable } from 'mobx-persist-store';
import * as Actions from "./actionFlags.js";

import generalStore from "./generalStore.js";

import { saveDeviceEditService, getNetworkStatusService, getDevicesForOrganizationService, getDeviceTypesListService, getAreasListService, removeDeviceService } from '../services/DeviceService';
import { callServiceEventAware } from ".";

//#################################################################################################

class devicesStore {

    devicesInMap = [];
    devicesInMapFilter = {};
    devices = {};
    devicesFilter = {};
    devicesOrderBy = "ref_ASC";
    resetDevicesList = false;
    areas = [];
    deviceTypes = [];
    networkStatus = [];
    message = "";

    //------------------------------------------------------------------------------------
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })    
        
        makePersistable(this, { name: 'devicesStore', properties: ['devices', 'devicesFilter', 'devicesOrderBy'], storage: window.sessionStorage });
    }    

    //------------------------------------------------------------------------------------
    doActionRemoveMessage = flow(function*() {
        this.message =  "";
    });

    //------------------------------------------------------------------------------------
    doActionGetDevicesForOrganizationOfUser = flow(function*(source, resetList, filter, page ) {

        let pageSize;
        if (source=="devicesmap") pageSize=10000;

        try {
            const response = yield callServiceEventAware(Actions.GET_DEVICES_FOR_ORGANIZATION, getDevicesForOrganizationService, filter, page, this.devicesOrderBy, pageSize);
            
            const devices = response.data;
        
            if (source=="devicesmap") {
                this.devicesInMap=devices;
                this.devicesInMapFilter = filter;
            }
            else {

                let currentDevices;
                let hasMore = true;
          
                // Si hemos cargado la primera página entonces no se agregan los lotes devueltos, en otro caso si
                if (page > 0) {
                  // eslint-disable-next-line
                  currentDevices = this.devices.list;
                  
                  if (devices.length > 0)
                    currentDevices = currentDevices.concat(devices);
                  else
                    hasMore = false; // Si no se devuelven resultados es que se cargaron todos
                }
                else {
                  currentDevices = devices;
                }
                                
                this.resetDevicesList = resetList;
                this.devicesFilter = filter;
                this.devices = { hasMore: hasMore, page: page, list: currentDevices };
            }
        } 
        catch (error) {
            console.log("ERROR EN LLAMADA AL API EN devicesStore.js: "+error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_DEVICES_FOR_ORGANIZATION);
            generalStore.doActionGeneralError("Error accediendo al servidor: "+error.message);
        }
        
    });
      
    //------------------------------------------------------------------------------------
    doActionSaveDeviceEdit = flow(function*(data) {
        try {
            const response = yield callServiceEventAware(Actions.DEVICE_SAVE_DATA, saveDeviceEditService, data);
            const device = response.data;

            if (this.devices.list==undefined) {
                this.devices= { list: [device] };
            }
            else {
                let currentDevices = [device, ...this.devices.list.filter(item => item.id != device.id)];

                // Reordenar la lista porque por defecto insertamos el item modificado al final
                currentDevices.sort(this.sortDevices(this.devicesOrderBy));

                this.message = "Los datos del dispositivo han sido grabados correctamente";
                this.devices = { list: currentDevices };
            }
        }
        catch (error) {
            generalStore.doActionEndService(Actions.DEVICE_SAVE_DATA);
            console.log("ERROR EN LLAMADA AL API EN devicesStore.js: " + error.message);
            console.log(error);
            
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 500) {   
                    if (error.response.data!=null && error.response.data.message!=null && error.response.data.message.toLowerCase()=='ref already exists')
                        generalStore.doActionGeneralError("Ya existe un dispositivo con esa referencia");
                    else
                        generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: "+error.message);
        }
        
    });  

    //------------------------------------------------------------------------------------
    doActionRemoveDevice = flow(function*(data) {
        try {
            yield callServiceEventAware(Actions.DEVICE_REMOVE, removeDeviceService, data);

            if (this.devices.list!=undefined) {
                let currentDevices = [...this.devices.list.filter(item => item.id != data)];

                this.message = "El dispositivo ha sido correctamente eliminado";
                this.devices = { list: currentDevices };
            }
        }
        catch (error) {
            generalStore.doActionEndService(Actions.DEVICE_REMOVE);
            console.log("ERROR EN LLAMADA AL API EN devicesStore.js: " + error.message);
            console.log(error);
            
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 500) {   
                    if (error.response.data!=null && error.response.data.message!=null && error.response.data.message.toLowerCase()=='device has sensor data')
                        generalStore.doActionGeneralError("El dispositivo tiene datos de sensores asociados, no puede ser eliminado. Le recomendamos que en estos casos cambie su estado a 'retirado'");
                    else
                        generalStore.doActionGeneralError("Error al eliminar el dispositivo");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: "+error.message);
        }
        
    });  

    //------------------------------------------------------------------------------------
    doActionGetNetworkStatus = flow(function*() {
        try {
            const response = yield callServiceEventAware(Actions.GET_NETWORK_STATUS, getNetworkStatusService);
            this.networkStatus = response.data;
        }
        catch (error) {
            generalStore.doActionEndService(Actions.GET_NETWORK_STATUS);
            console.log("ERROR EN LLAMADA AL API EN devicesStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //------------------------------------------------------------------------------------
    doActionGetDeviceTypesList = flow(function*() {
        try {
            const response = yield getDeviceTypesListService();
            this.deviceTypes = response.data;
        }
        catch (error) {
            console.log("ERROR EN LLAMADA AL API EN devicesStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //------------------------------------------------------------------------------------
    doActionGetAreasList = flow(function*() {
        try {
            const response = yield getAreasListService();
            this.areas = response.data;
        }
        catch (error) {
            console.log("ERROR EN LLAMADA AL API EN devicesStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });
 
    //------------------------------------------------------------------------------------
    doActionChangeOrderBy = flow(function*(orderBy) {
        this.devicesOrderBy=orderBy;
    });    

    //------------------------------------------------------------------------------------
    sortDevices(sortBy) {         
        return function (a, b) {
            let p = sortBy.split("_");

            if (p[1].toUpperCase() == "ASC")
                return eval('a.'+p[0]+'.toString().localeCompare(b.'+p[0]+'.toString())');
            else
                return eval('b.'+p[0]+'.toString().localeCompare(a.'+p[0]+'.toString())');
        }
    }    
}

export default new devicesStore()
