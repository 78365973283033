import React from 'react';
import { Empty, List, Spin, Col, message, Row, Button, Popover} from 'antd';
import { VideoCameraOutlined, EnvironmentFilled, EnvironmentOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";
import Sticky from 'react-stickynode';
import { reaction } from "mobx";

import InfiniteScroll from 'react-infinite-scroll-component';
import * as Constants from "../constants";

import ModalWithMap from "../components/ModalWithMap.js";
import "./Devices.scss";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

//#################################################################################################

class Devices extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);

        this.state = {            
            size: 0,
            selectedDevice: null,
            showMap: false,
            loading: true,
            hasMore: true,  
            page: -1 // Inicialmente no hay ninguna página cargada
        };
        this.reactions = [];
    }
  
    //-----------------------------------------------------------------------------------------------
    componentDidMount() {
        
        this.props.devicesStore.doActionGetDeviceTypesList();  
        this.props.devicesStore.doActionGetAreasList();  
        
        this.handleInfiniteOnLoad();

        // IMPORTANTE!
        // Usando reacciones de esta manera evitamos el uso de UNSAFE_componentWillReceiveProps(nextProps)
        this.reactions.push(
            reaction(
            () => this.props.devicesStore.devices,
            () => { 
                const devices = this.props.devicesStore.devices;
                if (devices!=undefined) {
                    let size=0;
        
                    if (devices.list!=undefined) size=devices.list.length;
                    this.setState({ size: size });
                
                    // eslint-disable-next-line
                    if (this.props.devicesStore.resetDevicesList) {
                        this.setState({ page: -1 });
                    }
        
                    // eslint-disable-next-line
                    if (this.state.hasMore || this.state.page < devices.page) {
                        this.setState({ loading: false, hasMore: devices.hasMore, page: devices.page });
                    }
                }
            })
        );
    }

    //-----------------------------------------------------------------------------------------------
    componentWillUnmount() {
        this.reactions.forEach((dispose) => dispose());
    }

    //-----------------------------------------------------------------------------------------------
    componentDidUpdate() {

        if (this.props.generalStore.error != undefined && this.props.generalStore.error.length > 0) {
            message.error(this.props.generalStore.error,6);
            this.props.generalStore.doActionRemoveGeneralError();            
        }
        else if (this.props.devicesStore.message != undefined && this.props.devicesStore.message.length > 0) {
            message.success(this.props.devicesStore.message);
            this.props.devicesStore.doActionRemoveMessage();            
            this.setState({selectedDevice: null });
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleInfiniteOnLoad = () => {

        let devicesFilter = {}

        // eslint-disable-next-line
        if (this.props.devicesStore.devicesFilter != undefined) {
            devicesFilter = this.props.devicesStore.devicesFilter;
        }

        this.props.devicesStore.doActionGetDevicesForOrganizationOfUser("deviceslist", false, devicesFilter, this.state.page + 1);

        this.setState({ loading: true });
    }

    //-----------------------------------------------------------------------------------------------
    handleShowDeviceDetail = (item) => {
        let sensorsFilter = {
            id: "",
            data: "",
            device: item.ref,
            area: -1,
            date1: null,
            date2: null,
        }
        this.props.sensorsStore.doActionGetSensorsForOrganizationOfUser(true, sensorsFilter, 0);

        // Esperar un segundo antes de cambiar para que de tiempo a procesar la petición anterior
        setTimeout(function(store){store.doActionSetSensorsPanelActiveTab("2")}, 1000, this.props.generalStore)        

        window.scrollTo(0, 0);
        message.success("Mostrando sensores del dispositivo "+item.ref+", elimine el filtro para verlos todos de nuevo");
    }
      
    //-----------------------------------------------------------------------------------------------
    handleCloseMap = () => {
        this.setState({showMap: false, selectedDevice: null});
    };    
      
    //-----------------------------------------------------------------------------------------------
    handleShowMap = (event, device) => {

        event.stopPropagation();

        this.setState({showMap: true, selectedDevice: device });
    }

    //-----------------------------------------------------------------------------------------------
    render() {  
        // ATENCION! Leemos aquí los mensajes de retorno para provocar que estos eventos sean notificado cuando cambian, si no lo hacemos no recibe la notificación, aunque no los usemos para nada realmente en el render(), se usan en el componentDidUpdate()
        const errorObserver=this.props.generalStore.error; // Se asigna el valor de error a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad
        const messageObserver=this.props.devicesStore.message; // Se asigna el valor de mensaje a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad

        const devices = this.props.devicesStore.devices.list == undefined ? [] : this.props.devicesStore.devices.list;

        const isBoxScreenMode = this.props.generalStore.boxScreenMode;

        let position=null;
        let status=null;
        let markerText="";
        if (this.state.selectedDevice!=null) {
            position = [this.state.selectedDevice.latitude, this.state.selectedDevice.longitude];
            status = this.state.selectedDevice.status;
            markerText = this.state.selectedDevice.ref;
        }

        return (
            <>
                <div className="devices">
                <Sticky  innerZ={80} top=".ant-tabs-nav">
                    <Row className={`list-screen-mode-header ${isBoxScreenMode ? 'invisible' : ''}`} >
                        <Col className="ellipsis" md={{ span: 6 }}>Referencia</Col> 
                        <Col className="ellipsis" md={{ span: 5 }}>Tipo</Col>
                        <Col className="ellipsis" md={{ span: 4 }}>Área</Col>
                        <Col className="ellipsis" md={{ span: 4 }}>Estado</Col>
                        <Col className="ellipsis" md={{ span: 2 }}>Parámetros</Col>
                        <Col className="ellipsis" md={{ span: 3 }}>Último dato</Col>                                                    
                    </Row>
                </Sticky>

                <InfiniteScroll
                    dataLength={devices.length}
                    next={this.handleInfiniteOnLoad}
                    scrollThreshold="60%"
                    hasMore={!this.state.loading && this.state.hasMore}
                >
                        <List locale={{ emptyText: (<Empty description="No hay resultados" image={Empty.PRESENTED_IMAGE_SIMPLE} />)}}
                            dataSource={devices}
                            renderItem={item => (
                                <>
                                    <List.Item className={`${isBoxScreenMode ? 'as-box' : 'as-list'}`} key={item.id} onClick={() => { this.handleShowDeviceDetail(item) }}>
                                        <Row className={`list-item box-screen-mode ${item.disabled ? 'disabled' : ''}`} >
                                            <div className="data">
                                                <Row>
                                                    <Col xs={{ span: 24 }}>
                                                        <div className="text-data">
                                                            <p className="title"><VideoCameraOutlined /> DISPOSITIVO {item.ref}</p> 
                                                            <p className="name">{item.type.name}</p>
                                                            <p className="area">{item.area.name}</p>
                                                            <p className="status" style={{ color: `${Constants.DEVICE_STATUS_COLORS[item.status]}`}}>&#11044; Estado {Constants.DEVICE_STATUS[item.status]}</p>
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ span: 21 }}>
                                                        <div className="params">
                                                            {item.runParams &&
                                                                Object.keys(item.runParams).map(param => (
                                                                    <p key={param}>{param}: {item.runParams[param]}</p>
                                                                ))
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className="options" xs={{ span: 3 }}>
                                                        <Button type="primary" shape="circle" title="Ver mapa" size="middle" icon={<EnvironmentOutlined />} onClick={(event) => { this.handleShowMap(event, item) }}/>
                                                    </Col>     
                                                </Row>
                                                <Row className="dates">
                                                        <Col xs={{ span: 12 }}>Último dato {item.lastConnectionDate == undefined ? "no consta" : new Date(item.lastConnectionDate).toLocaleDateString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</Col>
                                                </Row>
                                            </div>
                                        </Row>

                                        <Row className={`list-item list-screen-mode ${item.disabled ? 'disabled' : ''}`} >
                                        <Col className="ellipsis" md={{ span: 6 }}><VideoCameraOutlined /> {item.ref}</Col> 
                                            <Col className="ellipsis" md={{ span: 5 }}>{item.type.name}</Col>
                                            <Col className="ellipsis" md={{ span: 4 }}>{item.area.name}</Col>
                                            <Col className="ellipsis" md={{ span: 4 }} style={{ color: `${Constants.DEVICE_STATUS_COLORS[item.status]}`}}>&#11044; {Constants.DEVICE_STATUS[item.status]}</Col>
                                            <Col className="ellipsis" md={{ span: 2 }}>
                                                {item.runParams &&
                                                    <Popover placement="topLeft" content={Object.keys(item.runParams).map(param => (
                                                                        <span key={item.id}>{param}: {item.runParams[param]}<br/></span>
                                                                    ))}>
                                                        <Button type="link">{Object.keys(item.runParams).length}</Button>
                                                    </Popover>
                                                }
                                            </Col>
                                            <Col className="ellipsis" md={{ span: 2 }}>{item.lastConnectionDate == undefined ? "no consta" : new Date(item.lastConnectionDate).toLocaleDateString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</Col>
                                            <Col className="options" md={{ span: 1 }}>
                                                <Button className="actionButton" type="link" title="Ver mapa" onClick={(event) => { this.handleShowMap(event, item) }}><EnvironmentFilled/></Button>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                </>
                            )}
                        >                            
                            {this.state.loading && this.state.hasMore && (
                                <div className="loading-more">
                                    <Spin size="large" />
                                </div>
                            )}

                        </List>
                    </InfiniteScroll>
                </div>

                <ModalWithMap position={position} status={status} title="Posición del dispositivo" visible={this.state.showMap} markerText={markerText} closeMapFunction={this.handleCloseMap}/>
            </>
        );
    }
}

export default inject('sensorsStore','devicesStore','generalStore')(observer(Devices))
