import React from 'react';
import { Row, Col, Form, DatePicker, Input, Tooltip, Button, Modal, Layout, message } from 'antd';
import { QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import esES from 'antd/es/date-picker/locale/es_ES';
import { observer, inject } from "mobx-react";

import AjaxSelect from "./AjaxSelect.js";
import PictureManualUploader from "./PictureManualUploader";

import * as Actions from "../stores/actionFlags";

import "./ZipDataLoader.scss";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const { Content } = Layout;

//#################################################################################################

class ZipDataLoader extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);

        this.formFieldUser = React.createRef();
        this.formFieldZip = React.createRef();
        this.formData = React.createRef();

        this.state={
            showNoUserAlert: false,
            showNoZipAlert: false
        }
    }
  
    //-----------------------------------------------------------------------------------------------
    componentDidUpdate() {

        if (this.props.generalStore.error != undefined && this.props.generalStore.error.length > 0) {
            message.error(this.props.generalStore.error,10);
            this.props.generalStore.doActionRemoveGeneralError();            
        }
        else if (this.props.sensorsStore.message != undefined && this.props.sensorsStore.message.length > 0) {
            message.success(this.props.sensorsStore.message,10);
            this.props.sensorsStore.doActionRemoveMessage();
        }
    }

    //-----------------------------------------------------------------------------------------------
    // ATENCION! Como los campos de 'dispositivo', 'usuario', etc son componentes personalizados, para poder acceder a su valor y validarlos es necesario recurrir a un truco.
    // Lo que hacemos es poner un botón que no hace submit del formulario, si no que comprueba primero los valores de los campos y si son válidos entonces si hace submit.
    // Para acceder a los valores de los campos se referencian estos mediante 'ref' en el formulario y luego se accede a su valor con un metodo 'getFieldValue' programado expresamente dentro de esos componentes.
    handleSubmitButtonClick = () => {
        const user=this.formFieldUser.current.getFieldValue();
        
        let zip=this.formFieldZip.current.getFieldValue();
        
        if (zip!=undefined && zip.length>0 && zip[0].type.indexOf("zip")<0) zip=undefined;

        this.setState({ showNoUserAlert: (user==undefined), showNoZipAlert: (zip==undefined)})

        if (user!=undefined && zip!=undefined && this.formData!=undefined) this.formData.current.submit();
    }
    
    //-----------------------------------------------------------------------------------------------
    handleDataSubmit = (values) => {
        
        const submitFormData = new FormData();
        submitFormData.append('user_capture',this.formFieldUser.current.getFieldValue());
        submitFormData.append('date_capture',values.date_capture.format());
        submitFormData.append('zip',this.formFieldZip.current.getFieldValue()[0].originFileObj);
 
        this.props.sensorsStore.doActionUploadZipSensorData(submitFormData);       
        this.formData.current.resetFields()  
    }

    //-----------------------------------------------------------------------------------------------
    render() {        
        // ATENCION! Leemos aquí los mensajes de retorno para provocar que estoos eventos sean notificado cuando cambian, si no lo hacemos no recibe la notificación, aunque no los usemos para nada realmente en el render(), se usan en el componentDidUpdate()
        const errorObserver=this.props.generalStore.error; // Se asigna el valor de error a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad
        const messageObserver=this.props.sensorsStore.message; // Se asigna el valor de mensaje a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad

        const isChangingData = this.props.generalStore.processing.includes(Actions.LOAD_ZIP_SENSOR_DATA);

        // ATENCION! Leemos aquí el valor del mensaje de retorno para provocar que este evento sea notificado cuando cambia, si no lo hacemos no recibe la notificación, aunque no lo usemos para nada realmente
        const message= this.props.sensorsStore.message;
        
        return (        
            <>
                <div className="zip-data-loader">
                    <Form ref={this.formData} onFinish={this.handleDataSubmit} layout="vertical">
                        <Row justify="center" align="top">
                            <Col xs={{ span: 22 }} sm={{ span: 18 }} xl={{ span: 10 }} >

                                <div className="description">
                                    <p className="text">Utiliza este formulario para cargar las fotos de un conjunto de dispositivos simultáneamente.</p>
                                    <p className="text">Para hacerlo, comprime todas las fotos dentro de un archivo ZIP de tal forma que dentro del ZIP <strong>el nombre de cada foto debe de corresponderse con el código del dispositivo</strong> particular del cual provienen los datos.</p>          
                                </div>

                                <Form.Item name="user_capture" label="Usuario que recogió las muestras" className="required">
                                    <AjaxSelect ref={this.formFieldUser} placeholder="Selecciona el usuario" callbackUrl={APP_CONFIG.BACKEND_API_URL+"user/list/for-user-organization/?showdisabled=false&_orderby=surname_ASC&text="} optionGenerator={function(item){ return(<Option key={item.id}>{item.name+" "+item.surname+" ("+item.username+")"}</Option>) }}/>
                                    {this.state.showNoUserAlert && <div className="ant-form-item-explain"><div role="alert" className="ant-form-item-explain-error">Selecciona el usuario</div></div>}
                                </Form.Item>

                                <Form.Item name="date_capture" label="Fecha y hora en que se recogieron las muestras" rules={[{ required: true, message: 'Selecciona la fecha y hora' }]}>
                                    <DatePicker showTime={true} showNow={true} locale={esES} format='DD/MM/YYYY HH:mm:ss'/>
                                </Form.Item>

                                <Form.Item name="image" label="Archivo ZIP con las fotos" className="required">
                                    <PictureManualUploader ref={this.formFieldZip} maxFiles={1} extension=".zip"/>
                                    {this.state.showNoZipAlert && <div className="ant-form-item-explain"><div role="alert" className="ant-form-item-explain-error">Selecciona el archivo ZIP con las fotos</div></div>}
                                </Form.Item>


                                <Form.Item className="center-content">
                                    <Button key="submit" type="primary" loading={isChangingData} disabled={isChangingData} shape="round" size="middle" className="submit-form-button" onClick={this.handleSubmitButtonClick}>
                                        CARGAR DATOS
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <Modal forceRender={false}  destroyOnClose={true} className="zip-data-loader-modal-loading" visible={isChangingData} centered={true} closable={false} footer={null} title="Cargando datos de sensores">

                    <Content className="modal-loading-content">
                        <div className="loading">
                            <p className="icon">
                                <LoadingOutlined />
                            </p>
                            <p className="text">Enviando archivo ZIP, espere por favor....</p>
                        </div>
                    </Content>

                </Modal>    
            </>    
        );
    }
}

export default inject('sensorsStore','generalStore')(observer(ZipDataLoader))
