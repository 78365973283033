import React from "react";
import queryString from 'query-string';
import { Layout, Row, Col, Form, Input, Button, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";
import { reaction } from "mobx";

import * as Actions from "../stores/actionFlags.js";

import "./ResetPassword.scss"
import logoApp from "../assets/images/tiepanel-logo.png"
import logoCompany from "../assets/images/tragsatec-logo.png"

const { Content } = Layout;

//#################################################################################################

class ResetPassword extends React.Component {

//-----------------------------------------------------------------------------------------------
constructor(props) {
  super(props);

  this.token=null;

  this.reactions = [];
}

//-----------------------------------------------------------------------------------------------
  componentDidMount() {
    const params = queryString.parse(this.props.location.search);

    if (params.tk!=undefined) {
      this.token=params.tk;
      this.props.usersStore.doActionGetUserByResetPasswordToken(params.tk);
    }


    // The observer HoC automatically subscribes React components to any observables that are used during rendering. 
    // So observer component will only react to observables referred in its render method, not in componentDidMount.

    this.reactions.push(
      reaction(
        () => this.props.generalStore.error || this.props.usersStore.changedPasswordFlag,
        () => { 
          const changedPasswordFlag = this.props.usersStore.changedPasswordFlag;
          const errorMessage = this.props.generalStore.error;

            /*eslint eqeqeq: 0*/
          if (errorMessage != undefined && errorMessage.length > 0) {
            message.error(errorMessage);
            this.props.generalStore.doActionRemoveGeneralError();
          }
          else if (changedPasswordFlag)
          {
            message.success("Se ha modificado la contraseña");
            this.props.history.push('/');
          }
        }
      )
    ); 
        
  }

  //-----------------------------------------------------------------------------------------------
  componentWillUnmount() {
    this.reactions.forEach((dispose) => dispose());
  }

  //-----------------------------------------------------------------------------------------------
  handleResetPasswordSubmit = (values) => {
    var validation = true;

    /*eslint eqeqeq: 0*/
    if (values.password != values.repeat_password)  
    {
      message.error("Los valores de la contraseña no coinciden");
      validation = false;
    }
      
    if (!validation)
      return;
    
    this.props.usersStore.doActionResetPassword(values.password, this.token);
  }
  
  //-----------------------------------------------------------------------------------------------
  render() {
    const isResetingPassword = this.props.generalStore.processing.includes(Actions.RESET_PASSWORD);
    const isValidatingResetLink = this.props.generalStore.processing.includes(Actions.USER_GET_BY_RESET_PASSWORD_TOKEN);
    
    return (
      <>
        <Layout className="resetpassword-layout">
          <Content>

            <Row justify="center" align="top">
                <Col className="center-content" xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}>
                  <img className="logoApp" src={logoApp} alt="Logo de TIEPANEL"></img>
                  <a href="https://www.tragsa.es/"><img className="logoCompany" src={logoCompany} alt="Logo de Tragsatec"></img></a>
                  <h2 className="app-subtitle">Sistema de Control y Conteo de Insectos Estériles</h2>
                </Col>
            </Row>

            <Row justify="center" align="bottom">
              <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 16 }} lg={{ span: 12 }}>

                <Form className="resetpassword-form" onFinish={this.handleResetPasswordSubmit} >

                  {!this.props.usersStore.resetPasswordUser && isValidatingResetLink &&
                  <>
                    <p className="info-message">Validando enlace de recuperación de contraseña...</p>
                    <p className="info-message-spin"><SyncOutlined spin /></p>
                  </>}

                  {!this.props.usersStore.resetPasswordUser && !isValidatingResetLink &&
                  <>
                    <p className="info-message">Se ha producido un problema con la conexión al servidor</p>
                  </>}

                  {this.props.usersStore.resetPasswordUser && this.props.usersStore.resetPasswordUser=="invalid" && 
                  <>
                    <p className="info-message">El enlace para la recuperación de datos de acceso no es válido o ha caducado.</p>
                  </>}

                  {this.props.usersStore.resetPasswordUser && this.props.usersStore.resetPasswordUser!="invalid" &&
                  <>
                    <p className="app-hello">Hola {this.props.usersStore.resetPasswordUser.name}, utiliza este formulario para cambiar tu contraseña...</p>

                    <Form.Item className="form-field" name="password" rules={[{ required: true, message: 'Introduce tu nueva contraseña' }, { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }]} >
                      <Input prefix="Nueva contraseña" type="password" placeholder="Nueva contraseña" autoComplete="new-password"/>
                    </Form.Item>

                    <Form.Item className="form-field" name="repeat_password" rules={[{ required: true, message: 'Repite tu nueva contraseña' }, { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }]} >
                      <Input prefix="Repetir contraseña" type="password" placeholder="Repita la contraseña" autoComplete="new-password"/>
                    </Form.Item>

                    <Form.Item>
                      {!isResetingPassword && <Button type="primary" htmlType="submit" className="login-form-button">CAMBIAR CONTRASEÑA</Button>}
                      {isResetingPassword && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA` }} className="login-form-button"><SyncOutlined spin /></Button>}
                    </Form.Item>
                  </>}

                </Form>

                <Row justify="center" align="bottom">
                      <Button type="link" className="bottom-link" onClick={() => this.props.history.push('/')} >Volver a la pantalla de entrada</Button>
                </Row> 
              
              </Col>
            </Row>
          </Content>
          
        </Layout>
      </>
    );
  }
}

export default inject('usersStore','generalStore')(observer(ResetPassword))
