import React from "react";
import { Layout, Row, Col, Tabs } from 'antd';
import Sticky from 'react-stickynode';
import { observer, inject } from "mobx-react";

import AppHeader from "../components/AppHeader.js";
import SingleDataLoader from "../components/SingleDataLoader.js";
import ZipDataLoader from "../components/ZipDataLoader.js";
import DetectionDemo from "../components/DetectionDemo.js";

import "./DataLoaderPanel.scss";

const { Footer, Content } = Layout;
const { TabPane } = Tabs;

//#################################################################################################

class DataLoaderPanel extends React.Component {

  //-----------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
  }
      
  //-----------------------------------------------------------------------------------------------
  handleTabChange = (key) => {
    
    this.props.generalStore.doActionSetDataLoaderPanelActiveTab(key);    
  }

//-----------------------------------------------------------------------------------------------
  render() {
    const dataLoaderPanelActiveTab = this.props.generalStore.dataLoaderPanelActiveTab;
    
    // Necesario para hacer el menú de tipo "sticky"
    const renderTabBar = (props, DefaultTabBar) => (
      <Sticky innerZ={90}>
        {({ style }) => (
          <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
        )}
      </Sticky>
    )

    return (
      <>
        <Layout className="data-loader-panel-layout">
          <AppHeader/>

          <Content>
            <Row justify="center" align="top">
              <Col xs={{ span: 24 }}>

                  <Tabs renderTabBar={renderTabBar} className="tab-menu" activeKey={dataLoaderPanelActiveTab} onChange={this.handleTabChange} centered>

                    <TabPane tab="CARGA INDIVIDUAL" key="1">
                      <SingleDataLoader />
                    </TabPane>

                    <TabPane tab="CARGA MASIVA" key="2">
                      <ZipDataLoader />
                    </TabPane>

                    <TabPane tab="DEMO" key="3">
                      <DetectionDemo />
                    </TabPane>

                  </Tabs>
                

              </Col>
            </Row>
          </Content>

          <Footer id="footer">
         
          </Footer>
        </Layout>
      </>
    );
  }
}

export default inject('generalStore')(observer(DataLoaderPanel))
