import React from 'react';
import { Menu, Empty, List, Spin, Col, message, Modal, Row, InputNumber, Button, Form, Layout, Input, Select, Popover, Popconfirm, Tooltip } from 'antd';
import { VideoCameraOutlined, EditOutlined, PlusCircleOutlined, CloseCircleFilled, EnvironmentFilled, CloseCircleOutlined, EnvironmentOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { observer, inject } from "mobx-react";
import Sticky from 'react-stickynode';
import { reaction } from "mobx";

import InfiniteScroll from 'react-infinite-scroll-component';
import * as Constants from "../constants";
import * as Actions from "../stores/actionFlags";

import ModalWithMap from "../components/ModalWithMap.js";
import "./DevicesManagement.scss";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const { Content } = Layout;
const { TextArea } = Input;

//#################################################################################################

class DevicesManagement extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);

        this.editDeviceForm = React.createRef();

        this.state = {
            size: 0,
            showEditor: false,
            showMap: false,
            selectedDevice: null,
            loading: true,
            hasMore: true,  
            page: -1 // Inicialmente no hay ninguna página cargada
        };
        
        this.reactions = [];
    }
  
    //-----------------------------------------------------------------------------------------------
    componentDidMount() {
        
        this.props.devicesStore.doActionGetDeviceTypesList();  
        this.props.devicesStore.doActionGetAreasList();  
        
        this.handleInfiniteOnLoad();

        // IMPORTANTE!
        // Usando reacciones de esta manera evitamos el uso de UNSAFE_componentWillReceiveProps(nextProps)
        this.reactions.push(
            reaction(
            () => this.props.devicesStore.devices,
            () => { 
                const devices = this.props.devicesStore.devices;
                if (devices!=undefined) {
                    let size=0;
        
                    if (devices.list!=undefined) size=devices.list.length;
                    this.setState({ size: size });
                
                    // eslint-disable-next-line
                    if (this.props.devicesStore.resetDevicesList) {
                        this.setState({ page: -1 });
                    }
        
                    // eslint-disable-next-line
                    if (this.state.hasMore || this.state.page < devices.page) {
                        this.setState({ loading: false, hasMore: devices.hasMore, page: devices.page });
                    }
                }
            })
        );
    }

    //-----------------------------------------------------------------------------------------------
    componentWillUnmount() {
        this.reactions.forEach((dispose) => dispose());
    }

    //-----------------------------------------------------------------------------------------------
    componentDidUpdate() {

        if (this.props.generalStore.error != undefined && this.props.generalStore.error.length > 0) {
            message.error(this.props.generalStore.error,6);
            this.props.generalStore.doActionRemoveGeneralError();            
        }
        else if (this.props.devicesStore.message != undefined && this.props.devicesStore.message.length > 0) {
            message.success(this.props.devicesStore.message);
            this.props.devicesStore.doActionRemoveMessage();            
            this.setState({showEditor: false, selectedDevice: null });
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleInfiniteOnLoad = () => {

        let devicesFilter = {}

        // eslint-disable-next-line
        if (this.props.devicesStore.devicesFilter != undefined) {
            devicesFilter = this.props.devicesStore.devicesFilter;
        }
        
        this.props.devicesStore.doActionGetDevicesForOrganizationOfUser("devicesmanagement", false, devicesFilter, this.state.page + 1);

        this.setState({ loading: true });
    }

    //-----------------------------------------------------------------------------------------------
    handleShowDeviceDetail = (item) => {

        let device=null;
        
        if (item!=null) {
            device=Object.assign({}, item);
            device.geoarea=item.area._id;
            device.devicetype=item.type._id;
        }

        this.setState({showEditor: true, selectedDevice: device }, () => { this.editDeviceForm.current.resetFields() });        
    }

    //-----------------------------------------------------------------------------------------------
    handleCancelEdit = () => {
        this.setState({showEditor: false, selectedDevice: null });
    };

    //-----------------------------------------------------------------------------------------------
    handleDeviceEditorSubmit = (values) => {

        this.props.devicesStore.doActionSaveDeviceEdit(values);
    }

    //-----------------------------------------------------------------------------------------------
    handleSaveEdit = () => {
        let params=this.editDeviceForm.current.getFieldValue("params");

        try {
            if (params!=null) {
                JSON.parse("{"+params+"}");
            }

            if (this.editDeviceForm!=null) this.editDeviceForm.current.submit();
        }
        catch (error) {
            message.error("El formato del campo 'parámetros' no es correcto, debe ser un JSON válido");
        }        
    }

    //-----------------------------------------------------------------------------------------------
    handleLocalMenuClick = (event) => {
        if (event.key=="new") {
            this.handleShowDeviceDetail(null);
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleCloseMap = () => {
        this.setState({showMap: false, selectedDevice: null});
    };    
      
    //-----------------------------------------------------------------------------------------------
     handleShowMap = (event, device) => {

        event.stopPropagation();

        this.setState({showMap: true, selectedDevice: device });
    }

    //-----------------------------------------------------------------------------------------------
    handleRemoveDevice = (event, device) => {

        event.stopPropagation();

        this.props.devicesStore.doActionRemoveDevice(device.id);
    }

    //-----------------------------------------------------------------------------------------------
    render() {  
        // ATENCION! Leemos aquí los mensajes de retorno para provocar que estos eventos sean notificados cuando cambian, si no lo hacemos no recibe la notificación, aunque no los usemos para nada realmente en el render(), se usan en el componentDidUpdate()
        const errorObserver=this.props.generalStore.error; // Se asigna el valor de error a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad
        const messageObserver=this.props.devicesStore.message; // Se asigna el valor de mensaje a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad

        const isChangingDeviceData = this.props.generalStore.processing.includes(Actions.DEVICE_SAVE_DATA);

        const devices = this.props.devicesStore.devices.list == undefined ? [] : this.props.devicesStore.devices.list;

        const isBoxScreenMode = this.props.generalStore.boxScreenMode;

        const deviceTypes = this.props.devicesStore.deviceTypes;
        const areas = this.props.devicesStore.areas;

        let position=null;
        let status=null;
        let markerText="";
        
        if (this.state.selectedDevice!=null) {
            position = [this.state.selectedDevice.latitude, this.state.selectedDevice.longitude];
            status = this.state.selectedDevice.status;
            markerText = this.state.selectedDevice.ref;
            
            if (this.state.selectedDevice.runParams!=null) {
                let params="";
                Object.keys(this.state.selectedDevice.runParams).map(param => (
                    params=params+String(param)+": "+String(this.state.selectedDevice.runParams[param])+"\n"
                ))

                params=JSON.stringify(this.state.selectedDevice.runParams, null, " ");
                params=params.replace(/{\n|\n}/g,"");
                params=params.replace(/{}/g,"");

                this.state.selectedDevice.params=params;
            }
        }
        
        return (
            <>
                <div className="devices-management">
                <Sticky  innerZ={80} top=".ant-tabs-nav">
                    <Menu className="local-menu" onClick={this.handleLocalMenuClick} mode="horizontal">
                        <Menu.Item key="new" icon={<PlusCircleOutlined />}>
                            Crear dispositivo
                        </Menu.Item>
                    </Menu>

                    <Row className={`list-screen-mode-header ${isBoxScreenMode ? 'invisible' : ''}`} >
                        <Col className="ellipsis" md={{ span: 6 }}>Referencia</Col> 
                        <Col className="ellipsis" md={{ span: 4 }}>Tipo</Col>
                        <Col className="ellipsis" md={{ span: 3 }}>Área</Col>
                        <Col className="ellipsis" md={{ span: 3 }}>Estado</Col>
                        <Col className="ellipsis" md={{ span: 2 }}>IoT</Col>
                        <Col className="ellipsis" md={{ span: 1 }} lg={{ span: 2 }}>Parámetros</Col>
                        <Col className="ellipsis" md={{ span: 5 }} lg={{ span: 4 }}>Último dato</Col>                                                 
                    </Row>
                </Sticky>

                <InfiniteScroll
                    dataLength={devices.length}
                    next={this.handleInfiniteOnLoad}
                    scrollThreshold="60%"
                    hasMore={!this.state.loading && this.state.hasMore}
                >
                        <List locale={{ emptyText: (<Empty description="No hay resultados" image={Empty.PRESENTED_IMAGE_SIMPLE} />)}}
                            dataSource={devices}
                            renderItem={item => (
                                <>
                                    <List.Item className={`${isBoxScreenMode ? 'as-box' : 'as-list'}`} key={item.id} onClick={() => { this.handleShowDeviceDetail(item) }}>
                                        <Row className={`list-item box-screen-mode ${item.disabled ? 'disabled' : ''}`} >
                                            <div className="data">
                                                <Row>
                                                    <Col xs={{ span: 24 }} >
                                                        <div className="text-data">
                                                            <p className="title"><VideoCameraOutlined /> DISPOSITIVO {item.ref}</p> 
                                                            <p className="name">{item.type.name}</p>
                                                            <p className="area">{item.area.name}</p>
                                                            <p className="status" style={{ color: `${Constants.DEVICE_STATUS_COLORS[item.status]}`}}>&#11044; Estado {Constants.DEVICE_STATUS[item.status]}</p>
                                                            <p className="iot_key" style={{ color: `${item.iotKey == undefined || item.iotKey == "" ? "#7e7e7e" : "#39a939"}`}}>&#11044; Clave IoT {item.iotKey == undefined || item.iotKey == "" ? "vacía" : item.iotKey}</p>
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ span: 21 }} >
                                                        <div className="params">
                                                            {item.runParams &&
                                                                Object.keys(item.runParams).map(param => (
                                                                    <p key={param}>{param}: {item.runParams[param]}</p>
                                                                ))
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className="options" xs={{ span: 3 }} >
                                                        <Button type="primary" shape="circle" title="Ver mapa" size="middle" icon={<EnvironmentOutlined />} onClick={(event) => { this.handleShowMap(event, item) }} />
                                                        <br/>
                                                        <Popconfirm onClick={(e) => e.stopPropagation()} placement="topRight" title="¿Estás seguro de que quieres eliminar este dispositivo?" onCancel={(e) => e.stopPropagation()} onConfirm={(event) => { this.handleRemoveDevice(event, item) }} okText="Si" cancelText="No">
                                                            <Button type="primary" shape="circle" title="Eliminar dispositivo" size="middle" icon={<CloseCircleOutlined />} />
                                                        </Popconfirm>
                                                    </Col>     
                                                </Row>
                                                <Row className="dates">
                                                        <Col xs={{ span: 12 }}>Último dato {item.lastConnectionDate == undefined ? "no consta" : new Date(item.lastConnectionDate).toLocaleDateString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</Col>
                                                </Row>
                                            </div>
                                        </Row>

                                        <Row className={`list-item list-screen-mode ${item.disabled ? 'disabled' : ''}`} >
                                            <Col className="ellipsis" md={{ span: 6 }}><VideoCameraOutlined /> {item.ref}</Col> 
                                            <Col className="ellipsis" md={{ span: 4 }}>{item.type.name}</Col>
                                            <Col className="ellipsis" md={{ span: 3 }}>{item.area.name}</Col>
                                            <Col className="ellipsis" md={{ span: 3 }} style={{ color: `${Constants.DEVICE_STATUS_COLORS[item.status]}`}}>&#11044; {Constants.DEVICE_STATUS[item.status]}</Col>
                                            <Col className="ellipsis" md={{ span: 2 }} style={{ color: `${item.iotKey == undefined || item.iotKey == "" ? "#7e7e7e" : "#39a939"}`}}>&#11044; {item.iotKey == undefined || item.iotKey == "" ? "no" : "sí"}</Col>
                                            <Col className="ellipsis" md={{ span: 1 }} lg={{ span: 2 }}>
                                                {item.runParams &&
                                                    <Popover placement="topLeft" content={Object.keys(item.runParams).map(param => (
                                                                        <span key={item.id}>{param}: {item.runParams[param]}<br/></span>
                                                                    ))}>
                                                        <Button type="link">{Object.keys(item.runParams).length}</Button>
                                                    </Popover>
                                                }
                                            </Col>
                                            <Col className="ellipsis" md={{ span: 3 }}>{item.lastConnectionDate == undefined ? "no constan" : new Date(item.lastConnectionDate).toLocaleDateString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</Col>
                                            <Col className="options" xs={{ span: 2 }} lg={{ span: 1 }}>
                                                <Button className="actionButton" type="link" title="Ver mapa" onClick={(event) => { this.handleShowMap(event, item) }}><EnvironmentFilled/></Button>
                                                <Popconfirm onClick={(e) => e.stopPropagation()} placement="topRight" title="¿Estás seguro de que quieres eliminar este dispositivo?" onCancel={(e) => e.stopPropagation()} onConfirm={(event) => { this.handleRemoveDevice(event, item) }} okText="Si" cancelText="No">
                                                    <Button className="actionButton" type="link" shape="round" title="Eliminar dispositivo"><CloseCircleFilled/></Button>
                                                </Popconfirm>
                                            </Col>     
                                        </Row>
                                    </List.Item>
                                </>
                            )}
                        >                            
                            {this.state.loading && this.state.hasMore && (
                                <div className="loading-more">
                                    <Spin size="large" />
                                </div>
                            )}

                        </List>
                    </InfiniteScroll>
                </div>                    

                <Modal forceRender={true} className="devices-management-modal-device-editor" visible={this.state.showEditor} title={this.state.selectedDevice==null ? "Nuevo dispositivo" : "Editar dispositivo"} onCancel={this.handleCancelEdit}
                    footer={[
                        <Button key="back" onClick={this.handleCancelEdit}>
                        Cerrar
                        </Button>,
                        <Button key="submit" type="primary" loading={isChangingDeviceData} disabled={isChangingDeviceData} onClick={this.handleSaveEdit}>
                        Grabar datos
                        </Button>,
                    ]}
                    >

                    <Form ref={this.editDeviceForm} initialValues={this.state.selectedDevice} onFinish={this.handleDeviceEditorSubmit} layout="vertical">
                        <Content className="device-profile">

                            <Row justify="center" align="top">

                            <Col className="device-profile-data" xs={{ span: 24 }} >

                                <Form.Item name="id" hidden={true} >
                                </Form.Item>

                                <Form.Item name="ref" label="Referencia" rules={[{ required: true, message: 'Introduce una referencia para el dispositivo' }]}>
                                    <Input
                                        placeholder="Referencia del dispositivo"
                                        bordered={true}
                                        suffix={<EditOutlined key="edit" />}
                                    />
                                </Form.Item>

                                <Form.Item name="devicetype" label="Tipo" rules={[{ required: true, message: 'Selecciona el tipo de dispositivo' }]}>
                                    <Select placeholder="Selecciona el tipo">
                                        {deviceTypes.map(item => (
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item> 

                                <Form.Item name="geoarea" label="Área" rules={[{ required: true, message: 'Selecciona el área geográfica' }]}>
                                    <Select placeholder="Selecciona el área geográfica">
                                        {areas.map(item => (
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>                                                                

                                <Form.Item name="status" label="Estado" rules={[{ required: true, message: 'Selecciona el estado del dispositivo' }]}>
                                    <Select placeholder="Selecciona el estado">
                                        
                                        {Constants.DEVICE_STATUS.map(item => (                                          
                                                <Select.Option key={Constants.DEVICE_STATUS.indexOf(item)} value={Constants.DEVICE_STATUS.indexOf(item)}>{item}</Select.Option>                                            
                                        ))}

                                    </Select>
                                </Form.Item>
                                
                                <Form.Item name="iotKey" label="Clave IoT" rules={[{ required: false, message: 'Introduce una clave IoT o dejala vacía para asignar una automática' }]}>
                                    <Input
                                        placeholder=""
                                        bordered={true}
                                        suffix={<EditOutlined key="edit" />}
                                    />
                                </Form.Item>

                                <Form.Item name="params" label={<>Parámetros en JSON&nbsp;<Tooltip title={<>Especificar los diferentes parámetros en formato JSON válido.<br/>Ejemplo:<br/><i>"bateria":100,</i><br/><i>"fabricante":"Siemens"</i></>}><QuestionCircleOutlined/></Tooltip></>}>
                                    <TextArea  autoSize={{ minRows: 4, maxRows: 4 }} allowClear className="params" >
                                    </TextArea>
                                </Form.Item>

                                <Form.Item name="latitude" label="Latitud GPS" rules={[{required: true, message: 'Introduce la coordenada de latitud' }]} >
                                    <InputNumber
                                    style={{
                                        width: 200,
                                      }}
                                    placeholder="Latitud"
                                    bordered={true}
                                    step="0.1"
                                    stringMode
                                    suffix={<EditOutlined key="edit" />}
                                    />
                                </Form.Item>

                                <Form.Item name="longitude" label="Longitud GPS" rules={[{required: true, message: 'Introduce la coordenada de longitud' }]} >
                                    <InputNumber
                                    style={{
                                        width: 200,
                                      }}
                                    placeholder="Longitud"
                                    bordered={true}
                                    step="0.1"
                                    stringMode
                                    suffix={<EditOutlined key="edit" />}
                                    />
                                </Form.Item>

                            </Col>
                            </Row>

                        </Content>

                    </Form>

                </Modal>

                <ModalWithMap position={position} status={status} title="Posición del dispositivo" visible={this.state.showMap} markerText={markerText} closeMapFunction={this.handleCloseMap}/>
            </>
        );
    }
}

export default inject('usersStore','devicesStore','generalStore')(observer(DevicesManagement))
