import axios from 'axios';
import cookie from 'react-cookies';

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';
export const COOKIES_MAXAGE = 365 * 24 * 60 * 60; // 1 año, aunque en realidad la caducidad de la sesión se comprueba en el servidor

class AuthenticationService {

    //-----------------------------------------------------------------------------------------------
    constructor() {
        this.interceptor=null;
    }

    //-----------------------------------------------------------------------------------------------
    init() {
        const token = sessionStorage.getItem("tk");

        // Si se recarga la página se pierden los interceptores de Axios, por eso hay que poner este código aquí para volver a activarlos
        // eslint-disable-next-line
        if (axios.interceptors.request.handlers.length<=0 && token != undefined) {
            console.log("Activando interceptores de Axios");
            this.setupAxiosInterceptors(this.createJWTToken(token));
        }
    }

    //-----------------------------------------------------------------------------------------------
    executeJwtAuthenticationService(username, password, rememberMe) {
        return axios.post(APP_CONFIG.BACKEND_API_URL + "authenticate", { username, password, rememberMe })
    }

    //-----------------------------------------------------------------------------------------------
    registerSuccessfulLoginForJwt(username, token, refreshtoken) {
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        // El token hay que meterlo en sesión por si es necesario relanzar los interceptores de Axios (se necesita el token porque es el que se usa en cada petición, no vale el refreshToken)
        sessionStorage.setItem("tk", token);

        // eslint-disable-next-line
        if (refreshtoken != null) {
            cookie.save(process.env.REACT_APP_COOKIE_REFRESHTOKEN, refreshtoken, { maxAge: COOKIES_MAXAGE, path: '/' })

            // En el caso de usar una App nativa debemos devolver el refreshToken a la App usando un canal porque falla la lectura de las cookies desde la App
            // La App guardará el refreshToken en una shared preference y lo pasará como parámetro en la llamada de la URL de inicio desde la webview
            if (window.FLUTTERCHANNEL_NOTIFICATION!=undefined)
                window.FLUTTERCHANNEL_NOTIFICATION.postMessage(refreshtoken);
        }
        else {
            cookie.remove(process.env.REACT_APP_COOKIE_REFRESHTOKEN, { path: '/' })
        }
        
        this.setupAxiosInterceptors(this.createJWTToken(token))
    }

    //-----------------------------------------------------------------------------------------------
    createJWTToken(token) {
        return 'Bearer ' + token
    }

    //-----------------------------------------------------------------------------------------------
    logout() {
        //  ATENCIÓN! la petición debe hacerse antes de borrar las cookies para que el interceptor de Axios pueda incluir el token en la petición y esta no de error
        const response=axios.get(APP_CONFIG.BACKEND_API_URL + "forgetme").then(function(response) {

            sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
            sessionStorage.removeItem("tk");

            cookie.remove(process.env.REACT_APP_COOKIE_REFRESHTOKEN, { path: '/' })

            if (window.FLUTTERCHANNEL!=undefined)
                window.FLUTTERCHANNEL.postMessage("");
        });
            
        // eslint-disable-next-line
        return response;
    }

    //-----------------------------------------------------------------------------------------------
    rememberMe(refreshtoken) {
        return axios.get(APP_CONFIG.BACKEND_API_URL + "rememberme", {
            headers: { 'Authorization': 'Bearer ' + refreshtoken}
        });
    }

    //-----------------------------------------------------------------------------------------------
    isUserLoggedIn() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    //-----------------------------------------------------------------------------------------------
    getLoggedInUserName() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

    //-----------------------------------------------------------------------------------------------
    setupAxiosInterceptors(token) {

        // Es necesario borrar los interceptores anteriores para que no los dispare varias veces
        if (this.interceptor!=undefined) axios.interceptors.request.eject(this.interceptor);

        this.interceptor=axios.interceptors.request.use(
            (config) => {
                if (this.isUserLoggedIn()) {                    
                    config.headers.authorization = token                    
                }
                return config
            }
        )
    }
}

export default new AuthenticationService()