import React from 'react';
import { Button, Input, Row, Col, Select, Tooltip, DatePicker, message } from 'antd';
import { ConsoleSqlOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";

import "./SensorsFilter.scss";

import AjaxSelect from "./AjaxSelect.js";
import esES from 'antd/es/date-picker/locale/es_ES';

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

//#################################################################################################

class SensorsFilter extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            data: "",
            device: "",
            area: -1,
            type: -1,
            date1: null,
            date2: null,
            userCapture: null
        }
    }

    //-----------------------------------------------------------------------------------------------
    componentDidMount() {

        // eslint-disable-next-line
        if (this.props.sensorsStore.SensorsFilter != undefined) {
            // eslint-disable-next-line
            let currentFilter = this.props.sensorsStore.SensorsFilter;
            
            // eslint-disable-next-line
            if (currentFilter!=undefined) {
                this.setState(currentFilter);
            }
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleReset = () => {
        this.setState({ id: "", data: "", device: "", area: -1, type: -1, date1: null, date2: null, userCapture: null }, () => { message.success("El filtro se ha eliminado"); this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleIdChange = (event) => {
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ id: event.target.value }, () => { this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleDeviceChange = (event) => {
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ device: event.target.value }, () => { this.doSearch() });
    }
    
    //-----------------------------------------------------------------------------------------------
    handleDataChange = (event) => {
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ data: event.target.value }, () => { this.doSearch() });
    }
    
    //-----------------------------------------------------------------------------------------------
    handleAreaChange = (data) => {    
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ area: data.value }, () => { this.doSearch() });
    }
    
    //-----------------------------------------------------------------------------------------------
    handleUserCaptureChange = (data) => {    
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ userCapture: data }, () => { this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleDateRangeChange = (dates) => {
        this.setState({date1: dates[0], date2: dates[1]}, () => this.doSearch());
    }

    //-----------------------------------------------------------------------------------------------
    handleTypeChange = (data) => {    
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ type: data.value }, () => { this.doSearch() });
    }    
        
    //-----------------------------------------------------------------------------------------------
    doSearch = () => {
        this.props.sensorsStore.doActionGetSensorsForOrganizationOfUser(true, this.state, 0);
        window.scrollTo(0, 0);
    }

    //-----------------------------------------------------------------------------------------------
    render() { 
             
        const deviceTypes = this.props.devicesStore.deviceTypes;
        const areas = this.props.devicesStore.areas;
        
        return (
            <>
                <Row justify="center" className="sensors-filter">
                    <Col xs={{ span: 24 }} md={{ span: 10 }} xl={{ span: 6}} className="text-option">
                        <span className="field">Id</span> <Search placeholder="Buscar por identificador" value={this.state.id} onChange={this.handleIdChange} />
                    </Col>                            
                    <Col xs={{ span: 24 }} md={{ span: 10 }} xl={{ span: 6}} className="text-option">
                        <span className="field">Dispositivo</span> <Search placeholder="Buscar por referencia de dispositivo" value={this.props.sensorsStore.sensorsFilter.device} onChange={this.handleDeviceChange} />
                    </Col>                            
                    <Col xs={{ span: 24 }} md={{ span: 10 }} xl={{ span: 6}} className="text-option">
                        <span className="field">Dato <Tooltip title={<>Permite filtrar por un dato del sensor, para lo cual hay que especificar el filtro de la forma:<br/>DATO CONDICION VALOR<br/><br/>Ejemplos:<br/><i>temperatura&gt;10</i><br/><i>humedad='75%'</i></>}><QuestionCircleOutlined/></Tooltip>
                        </span> <Search placeholder="Buscar en los datos" value={this.state.data} onChange={this.handleDataChange} />
                    </Col>                                                
                    <Col xs={{ span: 24 }} md={{ span: 7 }} xl={{ span: 4 }} className="select-option">
                        <span className="field">Área geográfica</span> 
                            <Select labelInValue defaultValue={{value: -1}} value={{value: this.state.area}} onChange={this.handleAreaChange} getPopupContainer={() => document.getElementById('footer')}>
                            <Option key="-1" value={-1}>Todas</Option>

                            {areas.map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))}

                        </Select>
                    </Col>     
                    <Col xs={{ span: 24 }} md={{ span: 7 }} xl={{ span: 4 }} className="select-option">
                        <span className="field">Tipo de dispositivo</span> 
                            <Select labelInValue defaultValue={{value: -1}} value={{value: this.state.type}} onChange={this.handleTypeChange} getPopupContainer={() => document.getElementById('footer')}>
                            <Option key="-1" value={-1}>Todos</Option>

                            {deviceTypes.map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))}

                        </Select>
                    </Col>                    
                    <Col xs={{ span: 24 }} md={{ span: 6}} xl={{ span: 6 }} className="user-capture-option">
                        <span className="field">Usuario que recogió la muestra</span>
                        <AjaxSelect placeholder="Selecciona el usuario" value={this.state.userCapture} popupContainer={() => document.getElementById('footer')} callbackUrl={APP_CONFIG.BACKEND_API_URL+"user/list/for-user-organization/?showdisabled=false&_orderby=surname_ASC&text="} optionGenerator={function(item){ return(<Option key={item.id}>{item.name+" "+item.surname+" ("+item.username+")"}</Option>) }} valueChanged={this.handleUserCaptureChange}/>
                    </Col>     
                    <Col xs={{ span: 24 }} md={{ span: 6}} xl={{ span: 4 }} className="date-option">
                        <span className="field">Período de fechas</span> 
                        <RangePicker getPopupContainer={() => document.getElementById('footer')} locale={esES} format='DD/MM/YYYY' defaultValue={[this.state.date1, this.state.date2]} value={[this.state.date1, this.state.date2]} allowClear={false} onChange={this.handleDateRangeChange}/> 
                    </Col>
                    <Col xs={{ span: 24 }} className="delete-option">
                        <Button type="primary" shape="round" icon={<DeleteOutlined />} title="Eliminar filtro" onClick={this.handleReset}>Borrar</Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default inject('sensorsStore','devicesStore')(observer(SensorsFilter))
