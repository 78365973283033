import React from 'react';
import { Row, Col, message } from 'antd';
import { Pie, Bar } from '@ant-design/charts';
import { observer, inject } from "mobx-react";

import * as Constants from "../constants";

import "./NetworkStatus.scss";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');


//#################################################################################################

class NetworkStatus extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
    }
  
    //-----------------------------------------------------------------------------------------------
    componentDidMount() {

        this.props.devicesStore.doActionGetNetworkStatus(); 
    }

    //-----------------------------------------------------------------------------------------------
    componentDidUpdate() {

        if (this.props.generalStore.error != undefined && this.props.generalStore.error.length > 0) {
            message.error(this.props.generalStore.error,10);
            this.props.generalStore.doActionRemoveGeneralError();            
        }
        else if (this.props.sensorsStore.message != undefined && this.props.sensorsStore.message.length > 0) {
            message.success(this.props.sensorsStore.message,10);
            this.props.sensorsStore.doActionRemoveMessage();
        }
    }


    //-----------------------------------------------------------------------------------------------
    render() {
      // ATENCION! Leemos aquí los mensajes de retorno para provocar que estos eventos sean notificado cuando cambian, si no lo hacemos no recibe la notificación, aunque no los usemos para nada realmente en el render(), se usan en el componentDidUpdate()
      const errorObserver=this.props.generalStore.error; // Se asigna el valor de error a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad
      const messageObserver=this.props.sensorsStore.message; // Se asigna el valor de mensaje a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad

      let dataNetworkStatus=[];
        
      if (this.props.devicesStore.networkStatus.status!=undefined) {
          dataNetworkStatus=[
            {
              type: 'Activos',
              value: this.props.devicesStore.networkStatus.status.activeDevices,
            },
            {
              type: 'Inactivos',
              value: this.props.devicesStore.networkStatus.status.inactiveDevices,
            },
            {
              type: 'Retirados',
              value: this.props.devicesStore.networkStatus.status.retiredDevices,
            },
            {
              type: 'Averiados',
              value: this.props.devicesStore.networkStatus.status.brokenDevices,
            },
            {
              type: 'Con errores',
              value: this.props.devicesStore.networkStatus.status.errorDevices,
            },
            {
              type: 'En mantenimiento',
              value: this.props.devicesStore.networkStatus.status.maintenanceDevices,
            }
          ];
        }
        
        let dataNetworkTypes = [];

        if (this.props.devicesStore.networkStatus.types!=undefined) {
          for (var key in this.props.devicesStore.networkStatus.types) {
              let result = {type: key, value: this.props.devicesStore.networkStatus.types[key]}
              dataNetworkTypes.push(result);
          }
        }

        let configPieStatus = {      
            padding: 0,  
            appendPadding: 50,        
            angleField: 'value',
            colorField: 'type',
            color: Constants.DEVICE_STATUS_COLORS,
            radius: 0.8,
            label: {
              type: 'outer',
              content: '{percentage} {name}',
            },                
            interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
          };

        let configPieTypes = {      
            padding: 0,  
            appendPadding: 50,        
            angleField: 'value',
            colorField: 'type',
            radius: 0.8,
            label: {
              type: 'outer',
              content: '{percentage} {name}',
            },                
            interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
        };
                    
        let configAreas = null;
  
        if (this.props.devicesStore.networkStatus.areas!=undefined) {
          configAreas = {
            data: this.props.devicesStore.networkStatus.areas,
            isStack: true,
            xField: 'value',
            yField: 'label',
            seriesField: 'type',
            height: 600,
            label: {
              position: 'middle',
              layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' },
              ],
            },
          };      
        }
    
        return (        
            <>
                <div className="network-status">

                    {this.props.devicesStore.networkStatus.status &&
                      <Row>
                          <Col xs={{ span: 24 }}>
                            <h2>Total de dispositivos: {this.props.devicesStore.networkStatus.status.activeDevices+this.props.devicesStore.networkStatus.status.inactiveDevices+this.props.devicesStore.networkStatus.status.retiredDevices+this.props.devicesStore.networkStatus.status.brokenDevices+this.props.devicesStore.networkStatus.status.errorDevices+this.props.devicesStore.networkStatus.status.maintenanceDevices}</h2>
                          </Col>              
                      </Row>
                    }
                     
                    <Row >
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <div className="pie-panel-content">          
                                <div className="review-info-panel-data"> 
                                <h1>Estado de los dispositivos</h1>
                                    <div className="review-info-panel-mid">
                                    <Pie {...configPieStatus} data={dataNetworkStatus} />
                                    </div>
                                </div>  
                            </div>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <div className="pie-panel-content">          
                                <div className="review-info-panel-data"> 
                                <h1>Tipos de dispositivos</h1>
                                    <div className="review-info-panel-mid">
                                    <Pie {...configPieTypes} data={dataNetworkTypes} />
                                    </div>
                                </div>  
                            </div>
                        </Col>

                    </Row >

                    {configAreas &&
                      <Row>
                          <Col xs={{ span: 24 }}>
                            <div className="areas-panel-content">    
                                  <div className="stats-area-data"> 
                                    <h1>Estado por áreas geográficas</h1>      
                                    <Bar {...configAreas} />
                                  </div>
                            </div>                      
                          </Col>
                      </Row>
                    }
                </div> 
            </>    
        );
    }
}

export default inject('sensorsStore','devicesStore','generalStore')(observer(NetworkStatus))
