import React from 'react';
import { Row, Col, Form, Table, Image, Button, Modal, Layout, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";

import PictureManualUploader from "./PictureManualUploader";

import * as Actions from "../stores/actionFlags";

import "./DetectionDemo.scss";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const { Content } = Layout;

//#################################################################################################

class DetectionDemo extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
        
        this.formFieldImage = React.createRef();
        this.formData = React.createRef();

        this.state={
            showNoImageAlert: false
        }
    }
  
    //-----------------------------------------------------------------------------------------------
    componentDidUpdate() {

        if (this.props.generalStore.error != undefined && this.props.generalStore.error.length > 0) {
            message.error(this.props.generalStore.error,10);
            this.props.generalStore.doActionRemoveGeneralError();            
        }
        else if (this.props.sensorsStore.message != undefined && this.props.sensorsStore.message.length > 0) {
            message.success(this.props.sensorsStore.message,10);
            this.props.sensorsStore.doActionRemoveMessage();
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleSubmitButtonClick = () => {
        let image=this.formFieldImage.current.getFieldValue();
        
        if (image!=undefined && image.length>0 && image[0].type!="image/jpeg") image=undefined;

        this.setState({ showNoImageAlert: (image==undefined)})

        if (image!=undefined && this.formData!=undefined) this.formData.current.submit();
    }
    
    //-----------------------------------------------------------------------------------------------
    handleDataSubmit = (values) => {
        
        const formData = new FormData();
        formData.append('image',this.formFieldImage.current.getFieldValue()[0].originFileObj);
        this.props.sensorsStore.doActionDemoImageAnalysis(formData);       
        this.formData.current.resetFields();
    }

    //-----------------------------------------------------------------------------------------------
    handleClearDataButtonClick = () => {
        this.props.sensorsStore.demoResult={};
    }

    //-----------------------------------------------------------------------------------------------
    render() {     
        // ATENCION! Leemos aquí los mensajes de retorno para provocar que estoos eventos sean notificado cuando cambian, si no lo hacemos no recibe la notificación, aunque no los usemos para nada realmente en el render(), se usan en el componentDidUpdate()
        const errorObserver=this.props.generalStore.error; // Se asigna el valor de error a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad
        const messageObserver=this.props.sensorsStore.message; // Se asigna el valor de mensaje a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad

        const isChangingData = this.props.generalStore.processing.includes(Actions.LOAD_DEMO_SENSOR_DATA);
        const demoResult = this.props.sensorsStore.demoResult;

        // ATENCION! Leemos aquí el valor del mensaje de retorno para provocar que este evento sea notificado cuando cambia, si no lo hacemos no recibe la notificación, aunque no lo usemos para nada realmente
        const message= this.props.sensorsStore.message;
        
        const resultColumns = [
            {
                title: 'Dato',
                dataIndex: 'dato',
                key: 'data',
            },
            {
                title: 'Número',
                dataIndex: 'numero',
                key: 'count',
            },
            {
                title: 'Porcentaje',
                dataIndex: 'porcentaje',
                key: 'percent',
            },
          ];
          
          const resultData = [
            {
                key: '1',
                dato: 'Moscas totales',
                numero: demoResult.image_detections_total,
                porcentaje: '',
            },
            {
                key: '2',
                dato: 'Hembras',
                numero: demoResult.image_detections_hembra,
                porcentaje: +(demoResult.image_detections_hembra/demoResult.image_detections_total*100).toFixed(2)+'% del total'
            },                                                   
            {
                key: '3',
                dato: 'Machos estériles',
                numero: demoResult.image_detections_esteril,
                porcentaje: +(demoResult.image_detections_esteril/demoResult.image_detections_total*100).toFixed(2)+'% del total'
            },
            {
                key: '4',
                dato: 'Machos silvestres',
                numero: demoResult.image_detections_silvestre,
                porcentaje: +(demoResult.image_detections_silvestre/demoResult.image_detections_total*100).toFixed(2)+'% del total'
            },
          ];          

        return (        
            <>
                <div className="detection-demo">
                    {!demoResult.image_id &&
                    <Form ref={this.formData} onFinish={this.handleDataSubmit} layout="vertical">
                        <Row justify="center" align="top">
                            <Col xs={{ span: 22 }} sm={{ span: 18 }} xl={{ span: 10 }} >

                                <div className="description">
                                    <p className="text">Desde aquí puede subir una imagen para que sea analizada inmediatamente.</p>
                                    <p className="text">Este servicio se proporciona para la realización de demos y no está preparado para ser usado de forma intensiva.</p>
                                </div>

                                <Form.Item name="image" className="required">
                                    <PictureManualUploader ref={this.formFieldImage} maxFiles={1} extension=".jpg"/>
                                    {this.state.showNoImageAlert && <div className="ant-form-item-explain"><div role="alert" className="ant-form-item-explain-error">Selecciona la imagen en formato JPG</div></div>}
                                </Form.Item>

                                <Form.Item className="center-content">
                                    <Button key="submit" type="primary" loading={isChangingData} disabled={isChangingData} shape="round" size="middle" className="submit-form-button" onClick={this.handleSubmitButtonClick}>
                                            ANALIZAR IMAGEN
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    }
                    {demoResult.image_id &&
                        <Row justify="center" align="top">
                            <Col xs={{ span: 22 }} sm={{ span: 18 }} xl={{ span: 10 }} >
                                <div className="description">
                                        <p className="text">Estos son los resultados del procesamiento de la imagen:</p>
                                </div>

                                <Table dataSource={resultData} columns={resultColumns} pagination={false} size="small"/>

                                <Image src={APP_CONFIG.IMAGES_REPOSITORY_URL+"/temp/demo/detections_"+demoResult.image_id+".jpg?"+moment().valueOf()}/>

                                <Form.Item className="center-content">
                                    <Button type="primary" onClick={this.handleClearDataButtonClick} shape="round" size="middle" className="submit-form-button">
                                            ANALIZAR OTRA IMAGEN
                                    </Button>  
                                </Form.Item>                              
                            </Col>
                        </Row>
                    }
                </div>

                <Modal forceRender={false}  destroyOnClose={true} className="detection-demo-data-loader-modal-loading" visible={isChangingData} centered={true} closable={false} footer={null} title="Cargando y procesando imagen">

                    <Content className="modal-loading-content">
                        <div className="loading">
                            <p className="icon">
                                <LoadingOutlined />
                            </p>
                            <p className="text">Procesando imagen, espere por favor....</p>
                        </div>
                    </Content>

                </Modal>    
            </>    
        );
    }
}

export default inject('sensorsStore','generalStore')(observer(DetectionDemo))
