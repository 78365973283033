import React from 'react';
import { Menu, Layout, Row, Col } from 'antd';
import { DashboardOutlined, CloudUploadOutlined, VideoCameraOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";

// ATENCIÓN! Es necesario importar "withRouter" para poder utilizar this.props.history dentro de un componente (acordarse de añadirlo también al final en el export)
import { withRouter } from 'react-router-dom';
import * as Constants from "../constants";

import logoApp from "../assets/images/logo-app.png";
import logoDemeter from "../assets/images/demeter-logo.png"

import "./AppHeader.scss";

const { Header } = Layout;

//#################################################################################################

class AppHeader extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
    }

    //-----------------------------------------------------------------------------------------------
    handleMenuClick = (event) => {
        this.props.history.push('/'+event.key);
        window.scrollTo(0, 0);
    }

    //-----------------------------------------------------------------------------------------------
    render() {
        
        if (!this.props.usersStore.loggedIn) {
            this.props.history.push('/');
        }

        return (
            <>
                <Header className={`header ${this.props.fixed ? 'fixed-position' : ''}`}>

                    <Row justify="left" align="top">
                        <Col xs={{ span: 12 }}>
                            <img className="logo-app" src={logoApp} alt="Logo de TIEPANEL"></img>
                        </Col>
                        <Col className="right-content" xs={{ span: 12 }}>
                            <img className="logo-demeter" src={logoDemeter} alt="Logo de Demeter"></img>
                        </Col>
                    </Row>
                    <Row justify="right" align="top">
                        <Col className="right-content" xs={{ span: 24 }}>
                        
                            <Menu onClick={this.handleMenuClick}  mode="horizontal" >
                                <Menu.Item key="sensorspanel" icon={<VideoCameraOutlined />}>
                                    Sensores
                                </Menu.Item>
                                {this.props.usersStore.userLogged.role!=Constants.USER_ROLE_GUEST && this.props.usersStore.userLogged.role!=Constants.USER_ROLE_API && this.props.usersStore.userLogged.role!=Constants.USER_ROLE_IOT &&
                                    <Menu.Item key="loaddata" icon={<CloudUploadOutlined />}>
                                        Cargar datos
                                    </Menu.Item>
                                }                                
                                {this.props.usersStore.userLogged.role>=Constants.USER_ROLE_SUPERVISOR &&
                                    <Menu.Item key="controlpanel" icon={<DashboardOutlined />}>
                                        Panel de control
                                    </Menu.Item>
                                }                                
                                {this.props.usersStore.userLogged.role>=Constants.USER_ROLE_SUPERVISOR &&
                                    <Menu.Item key="adminpanel" icon={<SettingOutlined />}>
                                        Administración
                                    </Menu.Item>
                                }                                
                                <Menu.Item key="userprofile" icon={<UserOutlined />}>
                                    Datos personales
                                </Menu.Item>
                            </Menu>
                        
                        </Col>
                    </Row>
                </Header>
            </>
        );
    }
}

export default withRouter(inject('usersStore')(observer(AppHeader)))
