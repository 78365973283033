import React from 'react';
import { Select, Spin } from 'antd';

class AjaxSelect extends React.Component {
    
    //-----------------------------------------------------------------------------------------------
    constructor(props) {
      super(props);

      this.state = {
          isFetching: false,
          data: [],
          value: undefined,
      };
    }  

    //-----------------------------------------------------------------------------------------------
    getFieldValue = () => {
      return(this.state.value);
    }

    //-----------------------------------------------------------------------------------------------
    handleSearch = async (value) => {

        this.setState({ isFetching: true });

        if (value) {
            const token = sessionStorage.getItem("tk");
            
            const response=await fetch(this.props.callbackUrl+value, {
                method: 'GET',
                headers: {
                  'Authorization': 'Bearer ' + token
                }
              })
              .then((response) => response.json())
              .catch((error) => console.error('Error obteniendo elementos para el select: ', error));

              this.setState({ data: response });
              this.setState({ isFetching: false });
            }
        else {
              this.setState({ data: [] });
              this.setState({ isFetching: false });
            }

    };
  
    //-----------------------------------------------------------------------------------------------
    handleChange = (value) => {
      this.setState({ value: value }, () => {if (this.props.valueChanged) this.props.valueChanged(value);});
    };

    //-----------------------------------------------------------------------------------------------
    handleChange = (value) => {
      this.setState({ value: value }, () => {if (this.props.valueChanged) this.props.valueChanged(value);});
    };
    
    //-----------------------------------------------------------------------------------------------
    render() {

      const options = this.state.data.map(item => this.props.optionGenerator(item));

      return (
            <Select
            showSearch
            value={this.props.value}
            placeholder={this.props.placeholder}
            style={this.props.style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            notFoundContent={this.state.isFetching ? <Spin size="small" /> : null}
            getPopupContainer={this.props.popupContainer}              
            >
               {options}
            </Select>
      );
    }
  }
  
  export default AjaxSelect