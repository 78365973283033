import React from "react";
import { Select, Popconfirm, Layout, Row, Col, Form, Button, Input, message } from 'antd';
import { SyncOutlined, EditOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { observer, inject } from "mobx-react";
import { reaction } from "mobx";

import * as Actions from "../stores/actionFlags.js";
import * as Constants from "../constants";

import AppHeader from "../components/AppHeader.js";

import "./UserProfile.scss";

const { Footer, Content } = Layout;


//#################################################################################################

class UserProfile extends React.Component {

  //-----------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.reactions = [];
  }

  //-----------------------------------------------------------------------------------------------
  componentDidMount() {

    // Esto no se programa como una reacción, las reacciones se programan para reaccionar a cambios producidos DESPUES de montar el componente, pero el rol es fijo y conocido cuando se monta el componente
    if (this.props.usersStore.userLogged.role==Constants.USER_ROLE_SUPERUSER) {
      this.props.usersStore.doActionGetOrganizationsList();  
    }

    this.reactions.push(    
      reaction(
        () => this.props.generalStore.error || this.props.usersStore.changedUserDataFlag,
        () => { 
          const changedUserDataFlag = this.props.usersStore.changedUserDataFlag;
          const errorMessage = this.props.generalStore.error;

          /*eslint eqeqeq: 0*/
          if (errorMessage != undefined && errorMessage.length > 0) {
            message.error(errorMessage);
            this.props.generalStore.doActionRemoveGeneralError();
          }
          else if (changedUserDataFlag) {
            message.success("Se han guardado los datos del usuario");
          }
        }
      )
    );

  }

  //-----------------------------------------------------------------------------------------------
  componentWillUnmount() {
    this.reactions.forEach((dispose) => dispose());
  }

  //-----------------------------------------------------------------------------------------------
  handleUserProfileSubmit = (values) => {

    if (((values.password != undefined && values.password.length) > 0 || (values.passwordRepeat != undefined && values.passwordRepeat.length > 0)) && (values.password != values.passwordRepeat)) {
      message.error("Los valores de la contraseña no coinciden");
    }
    else {
        this.props.usersStore.doActionChangeUserData(values);
    }

  }

  //-----------------------------------------------------------------------------------------------
  handleLogoutConfirm = () => {
    this.props.usersStore.doActionLogout();

    // No se hace this.props.history.push('/') aquí para que de tiempo a ejecutarse la acción, esta provocará un componentDidUpdate() y allí se comprueba si el usuario no está logueado para volver a la página de login
  }


  //-----------------------------------------------------------------------------------------------
  render() {
    const isChangingUserData = this.props.generalStore.processing.includes(Actions.CHANGE_USER_DATA);

    const userRole = this.props.usersStore.userLogged.role == undefined ? Constants.USER_ROLE_GUEST : this.props.usersStore.userLogged.role;

    const userOrganization = this.props.usersStore.userLogged.organization;

    const organizations = this.props.usersStore.organizations;

    var userData = {
      "username": this.props.usersStore.userLogged.username,
      "name": this.props.usersStore.userLogged.name,
      "surname": this.props.usersStore.userLogged.surname,
      "email": this.props.usersStore.userLogged.email,
      "role": Constants.USER_ROLES[userRole],
      "organizationName": userOrganization ? userOrganization.name : "",
      "organization": userOrganization? userOrganization.id : -1,
      "password": ""
    }

    return (
      <>
        <Layout className="userprofile-layout">
          <AppHeader fixed="true" />

          <Form initialValues={userData} onFinish={this.handleUserProfileSubmit} layout="vertical">
          <Content className="user-profile">

            <Row justify="center" align="top">

              <Col className="user-profile-data" xs={{ span: 22 }} sm={{ span: 18 }} xl={{ span: 10 }} >
                <div className="description">
                  <h2 className="text">DATOS PERSONALES</h2>
                  <p className="text">Utiliza este formulario para modificar tus datos personales, cambiar tu correo electrónico, o tu contraseña de acceso.</p>
                  
                </div>

                  <Form.Item name="username" label="Nombre de usuario" >
                    <Input disabled
                    />
                  </Form.Item>

                  <Form.Item name="role" label="Rol" >
                    <Input disabled
                    />
                  </Form.Item>

                  {userRole!=Constants.USER_ROLE_SUPERUSER && 
                  <Form.Item name="organizationName" label="Organización a la que pertenece" >
                    <Input disabled
                    />
                  </Form.Item>
                  }

                  {userRole==Constants.USER_ROLE_SUPERUSER && 
                    <Form.Item name="organization" label="Organización a la que pertenece" >
                      <Select placeholder="Selecciona una organización">
                        {organizations.map(item => (
                          <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>                   
                   }

                  <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Introduce tu nombre' }]} >
                    <Input
                      placeholder="Nombre de usuario"
                      bordered={true}
                      suffix={<EditOutlined key="edit" />}
                    />
                  </Form.Item>

                  <Form.Item name="surname" label="Apellidos" rules={[{ required: true, message: 'Introduce tus apellidos' }]} >
                    <Input
                      placeholder="Apellidos"
                      bordered={true}
                      suffix={<EditOutlined key="edit" />}
                    />
                  </Form.Item>

                  <Form.Item className="form-field" name="email" label="Correo electrónico" rules={[{ required: true, type: "email", message: 'Introduce un Email válido' }]} >
                    <Input
                      placeholder="Email"
                      bordered={true}
                      suffix={<EditOutlined key="edit" />}
                    />
                  </Form.Item>

                  <Form.Item className="form-field" name="password" label="Cambiar contraseña"  rules={[ { required: false, message: 'Introduce tu nueva contraseña' }, { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }  ]}>
                    <Input.Password
                      placeholder="Cambiar contraseña"
                      rules={[{ required: true, message: 'Introduce tu nueva contraseña' }]}
                      bordered={true}
                      iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      autoComplete="new-password"
                    />
                  </Form.Item>

                  <Form.Item className="form-field" name="passwordRepeat"  rules={[ { required: false, message: 'Repite tu nueva contraseña' }, { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' } ]}>

                    <Input.Password
                      placeholder="Repetir contraseña"
                      rules={[{ required: true, message: 'Repite tu nueva contraseña' }]}
                      bordered={true}
                      iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      autoComplete="new-password"
                    />
                  </Form.Item>
              </Col>
            </Row>


          </Content>

          <Footer id="footer">
            <Form.Item>
                      {!isChangingUserData && <Button type="primary" htmlType="submit" shape="round" size="middle"
                        className="user-profile-form-button">GUARDAR DATOS</Button>}
                      {isChangingUserData && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA`, width: '90px' }} shape="round" className="user-profile-form-button"><SyncOutlined spin /></Button>}
            </Form.Item>

            <Popconfirm placement="top" title="¿Estás seguro de que quieres salir de la aplicación?" onConfirm={this.handleLogoutConfirm} okText="Si" cancelText="No">
              <Button type="primary" shape="round" size="middle" className="user-profile-form-button">CERRAR SESION</Button>
            </Popconfirm>
          </Footer>
          </Form>
        </Layout>
      </>
    );
  }
}

export default inject('usersStore','generalStore')(observer(UserProfile))


