import React from 'react';
import { Modal, Button, Layout } from 'antd';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';

import "./ModalWithMap.scss";

// ATENCIÓN! Para añadir leaflet seguir estas instrucciones: https://stackoverflow.com/questions/40365440/react-leaflet-map-not-correctly-displayed
// Si no se hace así, no se visualizará bien el mapa, es necesario añadir el CSS correcto
import 'leaflet/dist/leaflet.css';

import * as Constants from "../constants";

import defaultIcon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import brokenIcon from "../assets/images/marker-broken.png";
import inactiveIcon from "../assets/images/marker-inactive.png";
import retiredIcon from "../assets/images/marker-retired.png";
import maintenanceIcon from "../assets/images/marker-maintenance.png";
import malfunctionIcon from "../assets/images/marker-malfunction.png";

let DefaultIcon = L.icon({
    iconUrl: defaultIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

let BrokenIcon = L.icon({
    iconUrl: brokenIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

let InactiveIcon = L.icon({
    iconUrl: inactiveIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

let RetiredIcon = L.icon({
    iconUrl: retiredIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

let MaintenanceIcon = L.icon({
    iconUrl: maintenanceIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

let MalfunctionIcon = L.icon({
    iconUrl: malfunctionIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const { Content } = Layout;

//#################################################################################################

class ModalWithMap extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
    }

    //-----------------------------------------------------------------------------------------------
    render() {
        
        let icon = DefaultIcon;

        if (this.props.status==Constants.DEVICE_STATUS_BROKEN) icon=BrokenIcon;
        if (this.props.status==Constants.DEVICE_STATUS_ERROR_CONDITION) icon=MalfunctionIcon;
        if (this.props.status==Constants.DEVICE_STATUS_INACTIVE) icon=InactiveIcon;
        if (this.props.status==Constants.DEVICE_STATUS_RETIRED) icon=RetiredIcon;
        if (this.props.status==Constants.DEVICE_STATUS_MAINTENANCE) icon=MaintenanceIcon;

        return (
            <>
                <Modal forceRender={false}  destroyOnClose={true} className="modal-with-map" visible={this.props.visible} title={this.props.title} onCancel={this.props.closeMapFunction}
                    footer={[
                        <Button key="back" onClick={this.props.closeMapFunction}>Cerrar</Button>,
                    ]}
                    >

                    <Content className="device-leaflet-map-content">
                        <MapContainer center={this.props.position} zoom={14} scrollWheelZoom={true} position="leaflet-bottom leaflet-right">
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            <Marker position={this.props.position} icon={icon}>
                                <Tooltip>{this.props.markerText}</Tooltip>
                            </Marker>
                        </MapContainer>       
                    </Content>
        
                </Modal>
            </>
        );
    }
}

export default ModalWithMap