import React from "react";
import { Layout, Row, Col, Collapse, Button, Tabs, message, Select } from 'antd';
import { AppstoreOutlined, ReloadOutlined, SyncOutlined, UnorderedListOutlined } from '@ant-design/icons';
import Sticky from 'react-stickynode';
import { observer, inject } from "mobx-react";

import AppHeader from "../components/AppHeader.js";
import UsersManagement from "../components/UsersManagement.js";
import UsersManagementFilter from "../components/UsersManagementFilter.js";
import DevicesManagement from "../components/DevicesManagement.js";
import DevicesFilter from "../components/DevicesFilter.js";
import * as Actions from "../stores/actionFlags.js";

import "./AdminPanel.scss";

const { Footer, Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;

//#################################################################################################

class AdminPanel extends React.Component {

  //-----------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      collapsed: "1"
    }
  }
      
  //-----------------------------------------------------------------------------------------------
  handleTabChange = (key) => {

    this.setState({ collapsed: "1" });
    this.props.generalStore.doActionSetAdminPanelActiveTab(key);    
  }

  //-----------------------------------------------------------------------------------------------
  handleFilterCollapsed = (event) => {

    // eslint-disable-next-line
    this.setState({ collapsed: this.state.collapsed == "1" ? "0" : "1" });
  }

  //-----------------------------------------------------------------------------------------------
  handleRefreshData = () => {

    if (this.props.generalStore.adminPanelActiveTab==1) {
      this.props.usersStore.doActionGetUsersForOrganizationOfUser(true, this.props.usersStore.usersFilter, 0);
      message.success("Obteniendo el listado de usuarios...");
      window.scrollTo(0, 0);
    }

    if (this.props.generalStore.adminPanelActiveTab==2) {
      this.props.devicesStore.doActionGetDevicesForOrganizationOfUser("devicesmanagement", true, this.props.devicesStore.devicesFilter, 0);
      message.success("Obteniendo el listado de dispositivos...");
      window.scrollTo(0, 0);
    }
  }

  //-----------------------------------------------------------------------------------------------
  handleChangeScreenMode = (boxMode) => {

    this.props.generalStore.doActionChangeScreenMode(boxMode);
  }

  //-----------------------------------------------------------------------------------------------
  handleChangeOrder = (orderby) => {

    if (this.props.generalStore.adminPanelActiveTab==1) {
      this.props.usersStore.doActionChangeOrderBy(orderby);
      this.props.usersStore.doActionGetUsersForOrganizationOfUser(true, this.props.usersStore.usersFilter, 0);
      message.success("Obteniendo el listado de usuarios...");
      window.scrollTo(0, 0);
    }    

    if (this.props.generalStore.adminPanelActiveTab==2) {
      this.props.devicesStore.doActionChangeOrderBy(orderby);
      this.props.devicesStore.doActionGetDevicesForOrganizationOfUser("devicesmanagement", true, this.props.devicesStore.devicesFilter, 0);
      message.success("Obteniendo el listado de dispositivos...");
      window.scrollTo(0, 0);
    }    
  
  }
  
//-----------------------------------------------------------------------------------------------
  render() {
    const adminPanelActiveTab = this.props.generalStore.adminPanelActiveTab;
    
    const isFetchingData = this.props.generalStore.processing.includes(Actions.GET_USERS_FOR_ORGANIZATION || Actions.GET_DEVICES_FOR_ORGANIZATION);
    const isBoxScreenMode = this.props.generalStore.boxScreenMode;

    // Necesario para hacer el menú de tipo "sticky"
    const renderTabBar = (props, DefaultTabBar) => (
      <Sticky innerZ={90}>
        {({ style }) => (
          <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
        )}
      </Sticky>
    )

    return (
      <>
        <Layout className="admin-panel-layout">
          <AppHeader/>

          <Content>
            <Row justify="center" align="top">
              <Col xs={{ span: 24 }}>

                  <Tabs renderTabBar={renderTabBar} className="tab-menu" activeKey={adminPanelActiveTab} onChange={this.handleTabChange} centered>

                    <TabPane tab="USUARIOS" key="1">
                      <UsersManagement />
                    </TabPane>

                    <TabPane tab="DISPOSITIVOS" key="2">
                      <DevicesManagement />
                    </TabPane>

                  </Tabs>
                

              </Col>
            </Row>
          </Content>

          <Footer id="footer">

          { adminPanelActiveTab == 1 &&
              <Select value={this.props.usersStore.usersOrderBy} placeholder="Ordenar por" size="small" getPopupContainer={() => document.getElementById('footer')} className="order-by-select" onChange={this.handleChangeOrder}>
                    <Select.Option key="1" value="username_ASC">&#9650; login</Select.Option>
                    <Select.Option key="2" value="surname_ASC">&#9650; apellidos</Select.Option>
                    <Select.Option key="3" value="role_ASC">&#9650; rol</Select.Option>
                    <Select.Option key="4" value="email_ASC">&#9650; email</Select.Option>
              </Select>
            }

            { adminPanelActiveTab == 2 &&
              <Select value={this.props.devicesStore.devicesOrderBy} placeholder="Ordenar por" size="small" getPopupContainer={() => document.getElementById('footer')} className="order-by-select" onChange={this.handleChangeOrder}>
                    <Select.Option key="1" value="ref_ASC">&#9650; referencia</Select.Option>
                    <Select.Option key="2" value="type.name_ASC">&#9650; tipo</Select.Option>
                    <Select.Option key="3" value="area.name_ASC">&#9650; area</Select.Option>
                    <Select.Option key="4" value="status_ASC">&#9650; estado</Select.Option>
                    <Select.Option key="5" value="lastConnectionDate_DESC">&#9660; fecha último dato</Select.Option>
              </Select>
            }

            { !isFetchingData && 
              <Button className="reloading" type="primary" shape="circle" size="middle" title="Recargar datos" icon={<ReloadOutlined />} onClick={(event) => { this.handleRefreshData() }} />
            }
            { isFetchingData && 
              <Button className="reloading" type="primary" shape="circle" size="middle" disabled style={{ backgroundColor: `#AAAAAA` }}><SyncOutlined spin /></Button>
            }
            
            { !isBoxScreenMode && 
              <Button className="screen-mode" type="primary" shape="circle" size="middle" title="Cambiar a modo cajas" icon={<AppstoreOutlined />} onClick={(event) => { this.handleChangeScreenMode(true) }} />
            }
            { isBoxScreenMode && 
              <Button className="screen-mode" type="primary" shape="circle" size="middle" title="Cambiar a modo listado" icon={<UnorderedListOutlined />} onClick={(event) => { this.handleChangeScreenMode(false) }} />
            }

            <Collapse className="filter" activeKey={this.state.collapsed} onChange={this.handleFilterCollapsed}>              
              { adminPanelActiveTab == 1 &&
                <Panel header="Filtrar usuarios">
                  <UsersManagementFilter />
                </Panel> 
              }
              { adminPanelActiveTab == 2 &&
                <Panel header="Filtrar dispositivos">
                  <DevicesFilter source="devicesmanagement"/>
                </Panel> 
              } 
            </Collapse>
          </Footer>
        </Layout>
      </>
    );
  }
}

export default inject('generalStore','usersStore','devicesStore')(observer(AdminPanel))
