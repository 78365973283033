import axios from 'axios';

//------------------------------------------------------------------------------------
export const  userByUsernameService = (username) => {
    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"user/by-username/"+username);
    
    return(response);
}

//------------------------------------------------------------------------------------
export const  sendUserByEmailService = (email) => {
    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"user/send-user-by-email/"+email);
    return(response);
}

//------------------------------------------------------------------------------------
export const changeUserDataService = (params) => {

    //Si el email se envia -1 indica que el usuario no lo ha cambiado
    const response = axios.post(APP_CONFIG.BACKEND_API_URL+"user/change-user-data/", params);
    return(response);
    
}

//------------------------------------------------------------------------------------
export const resetPasswordService = (password, token) => {
    const response = axios.post(APP_CONFIG.BACKEND_API_URL+"reset-password", {"password": password, "token" : token});
    return(response);
}

//------------------------------------------------------------------------------------
export const getUserByResetPasswordTokenService = (token) => {
    const response = axios.post(APP_CONFIG.BACKEND_API_URL+"get-user-by-reset-password-token", {"token" : token });
    return(response);
}

//------------------------------------------------------------------------------------
export const changePasswordService = (password) => {

    const response = axios.post(APP_CONFIG.BACKEND_API_URL+"user/change-password/", {"password": password});
    return(response);
}

//------------------------------------------------------------------------------------
export const getOrganizationsListService = () => {
    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"organization/list/");
    return(response);
}

//------------------------------------------------------------------------------------
export const saveUserEditService = (params) => {

    //Si el email se envia -1 indica que el usuario no lo ha cambiado

    if (params.id!=undefined) {
        const response = axios.post(APP_CONFIG.BACKEND_API_URL+"user/"+params.id+"/change-user-data/", params);
        return(response);
    }
    else {
        const response = axios.post(APP_CONFIG.BACKEND_API_URL+"user/new/", params);
        return(response);
    }    
}

//------------------------------------------------------------------------------------
export const getUsersForOrganizationService = (filter, page, order, pageSize) => {

    let params ="";
    
    // eslint-disable-next-line
    if (filter.role!=undefined && filter.role>=0) params=params+"role="+filter.role+"&";
    
    // eslint-disable-next-line
    if (filter.text!=undefined && String(filter.text).length>0) params=params+"text="+filter.text+"&"; 

    // eslint-disable-next-line
    if (filter.showdisabled!=undefined && String(filter.showdisabled).length>0) params=params+"showdisabled="+filter.showdisabled+"&"; 

    // eslint-disable-next-line
    if (pageSize!=undefined) {
        params=params+"_pagesize="+pageSize+"&"; 
    }

    if (order!=undefined) {
        params=params+"_orderby="+order;
    }

    let response = axios.get(APP_CONFIG.BACKEND_API_URL+"user/list/for-user-organization/?_page="+page+"&"+params);

    return(response);
}

//------------------------------------------------------------------------------------
export const changeEnabledUserStateService = (user, disabled) => {
    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"user/"+user.id+"/set-disabled/"+disabled+"/");
    return(response);
}

//------------------------------------------------------------------------------------
export const removeUserService = (id) => {

    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"user/"+id+"/remove/");

    // Devolver la respuesta, aunque para este tipo de operaciones venga vacía, para capturar el error en caso de que se produzca alguno
    return(response);

}
