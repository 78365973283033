
// Declaración de todos los tipos de flags de acciones usados en la aplicacion

export const LOGIN = "LOGIN";
export const REMINDUSER = "REMINDUSER";
export const REMEMBER_ME = "REMEMBER_ME";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const USER_GET_BY_RESET_PASSWORD_TOKEN = "USER_GET_BY_RESET_PASSWORD_TOKEN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CHANGE_USER_DATA = "CHANGE_USER_DATA";
export const GET_USERS_FOR_ORGANIZATION = "GET_USERS_FOR_ORGANIZATION";
export const USER_SAVE_DATA = "USER_SAVE_DATA";
export const USER_REMOVE = "USER_REMOVE";
export const DEVICE_SAVE_DATA = "DEVICE_SAVE_DATA";
export const DEVICE_REMOVE = "DEVICE_REMOVE";
export const GET_DEVICES_FOR_ORGANIZATION = "GET_DEVICES_FOR_ORGANIZATION"
export const GET_SENSORS_FOR_ORGANIZATION = "GET_SENSORS_FOR_ORGANIZATION"
export const LOAD_SINGLE_SENSOR_DATA = "LOAD_SINGLE_SENSOR_DATA"
export const LOAD_ZIP_SENSOR_DATA = "LOAD_ZIP_SENSOR_DATA"
export const LOAD_DEMO_SENSOR_DATA = "LOAD_SEMO_SENSOR_DATA"
export const SENSOR_REMOVE = "SENSOR_REMOVE";
export const SENSOR_REPLAY = "SENSOR_REPLAY";
export const GET_NETWORK_STATUS = "GET_NETWORK_STATUS";
export const GET_SENSORS_STATS = "GET_SENSORS_STATS";

