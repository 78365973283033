
// Declaración de diferentes valores que se usan en la aplicación

// Roles de usuarios 
export const USER_ROLE_GUEST = 0; // Los invitados pueden acceder a la consola pero nada más, ni llamar al API ni cargar datos
export const USER_ROLE_IOT = 1;  // Usuario especial de API que corresponde con las llamadas desde la red de dispositivos IOT de trampas automáticas
export const USER_ROLE_API = 2; // Estos usuarios sólo pueden usar las llamadas al API, no pueden usar la consola
export const USER_ROLE_USER = 3; // Los usuarios pueden hacer llamadas al API y usar la consola, pero no algunas operaciones como borrados
export const USER_ROLE_SUPERVISOR = 4; // Como los usuarios pero además pueden hacer operaciones adicionales como borrados
export const USER_ROLE_ADMINISTRATOR = 5; // Pueden gestionar aspectos de la aplicación para una organización
export const USER_ROLE_SUPERUSER = 6; // Pueden cambiar de organización y gestionar todo para cualquier organización

export const USER_ROLES=["invitado", "iot", "api", "usuario", "supervisor", "administrador", "superusuario"];

export const DEVICE_STATUS_ACTIVE = 0;
export const DEVICE_STATUS_INACTIVE = 1;
export const DEVICE_STATUS_RETIRED = 2;
export const DEVICE_STATUS_BROKEN = 3;
export const DEVICE_STATUS_MAINTENANCE = 4;
export const DEVICE_STATUS_ERROR_CONDITION = 5;

export const DEVICE_STATUS=["activo", "inactivo", "retirado", "averiado", "en mantenimiento", "funcionando con errores"];
export const DEVICE_STATUS_COLORS=["#39a939", "#6e6e6e", "#000000", "#ff0000", "#d98900", "#ffff00"];
