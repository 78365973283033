import React from 'react';
import { Menu, Empty, List, Spin, Col, message, Modal, Row, Switch, Button, Form, Layout, Input, Select, Popconfirm } from 'antd';
import { UserOutlined, EditOutlined, WifiOutlined, EyeInvisibleOutlined, EyeOutlined, PlusCircleOutlined, CloseCircleFilled } from '@ant-design/icons';
import { observer, inject } from "mobx-react";
import Sticky from 'react-stickynode';
import { reaction } from "mobx";

import InfiniteScroll from 'react-infinite-scroll-component';
import * as Constants from "../constants";
import * as Actions from "../stores/actionFlags";

import "./UsersManagement.scss";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const { Content } = Layout;

//#################################################################################################

class UsersManagement extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);

        this.editUserForm = React.createRef();

        this.state = {
            selectedRole: 0,
            size: 0,
            showEditor: false,
            selectedUser: null,
            loading: true,
            hasMore: true,              
            page: -1 // Inicialmente no hay ninguna página cargada
        };
        this.reactions = [];
        
    }
  
    //-----------------------------------------------------------------------------------------------
    componentDidMount() {
        
        this.handleInfiniteOnLoad();

        // IMPORTANTE!
        // Usando reacciones de esta manera evitamos el uso de UNSAFE_componentWillReceiveProps(nextProps)
        this.reactions.push(
            reaction(
            () => this.props.usersStore.users,
            () => { 
                const users = this.props.usersStore.users;
                if (users!=undefined) {
                    let size=0;
        
                    if (users.list!=undefined) size=users.list.length;
                    this.setState({ size: size });
                
                    // eslint-disable-next-line
                    if (this.props.usersStore.resetUsersList) {
                        this.setState({ page: -1 });
                    }
        
                    // eslint-disable-next-line
                    if (this.state.hasMore || this.state.page < users.page) {
                        this.setState({ loading: false, hasMore: users.hasMore, page: users.page });
                    }
                }
            })
        );
    }

    //-----------------------------------------------------------------------------------------------
    componentWillUnmount() {
        this.reactions.forEach((dispose) => dispose());
    }

    //-----------------------------------------------------------------------------------------------
    componentDidUpdate() {

        // IMPORTANTE!
        // Aquí no hace falta programar las reacciones a los cambios en los observables del store explicitamente
        // Esto se debe a que cualquier cambio en un usuario provocan un render del listado, y por tanto se pasa siempre por este código 

        // eslint-disable-next-line
        if (this.props.generalStore.error != undefined && this.props.generalStore.error.length > 0) {
            message.error(this.props.generalStore.error,6);
            this.props.generalStore.doActionRemoveGeneralError();            
        }
        else if (this.props.usersStore.message != undefined && this.props.usersStore.message.length > 0) {
            message.success(this.props.usersStore.message);
            this.props.usersStore.doActionRemoveMessage();            
            this.setState({showEditor: false, selectedUser: null });
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleInfiniteOnLoad = () => {

        let usersFilter = {}

        // eslint-disable-next-line
        if (this.props.usersStore.usersFilter != undefined) {
            usersFilter = this.props.usersStore.usersFilter;
        }

        this.props.usersStore.doActionGetUsersForOrganizationOfUser(false, usersFilter, this.state.page + 1);

        this.setState({ loading: true });
    }

    //-----------------------------------------------------------------------------------------------
    handleRoleChange = (event) => {
        
        this.setState({ selectedRole: event });
    }

    //-----------------------------------------------------------------------------------------------
    handleUserDisabledStateChange = (event, checked, item) => {
        event.stopPropagation();

        this.props.usersStore.doActionChangeEnabledUserState(item, checked);
    }

    //-----------------------------------------------------------------------------------------------
    handleShowUserDetail = (item) => {
        let role=0
        if (item != undefined) role=item.role;
        this.setState({showEditor: true, selectedUser: item, selectedRole:  role}, () => { this.editUserForm.current.resetFields() });        
    }

    //-----------------------------------------------------------------------------------------------
    handleCancelEdit = () => {
        this.setState({showEditor: false, selectedUser: null });
    };
    
    //-----------------------------------------------------------------------------------------------
    handleUserEditorSubmit = (values) => {
        
        if (((values.password != undefined && values.password.length) > 0 || (values.passwordRepeat != undefined && values.passwordRepeat.length > 0)) && (values.password != values.passwordRepeat)) {
            message.error("Los valores de la contraseña no coinciden");            
        }        
        else {
            if (values.password==undefined) values.password = "";            
            this.props.usersStore.doActionSaveUserEdit(values);
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleSaveEdit = () => {
        if (this.editUserForm!=null) this.editUserForm.current.submit();
    }

    //-----------------------------------------------------------------------------------------------
    handleLocalMenuClick = (event) => {
        if (event.key=="new") {
            this.handleShowUserDetail(null);
        }
    }
      
    //-----------------------------------------------------------------------------------------------
    handleRemoveUser = (event, user) => {

        event.stopPropagation();

        this.props.usersStore.doActionRemoveUser(user.id);
    }

    //-----------------------------------------------------------------------------------------------
    render() {         
        const isChangingUserData = this.props.generalStore.processing.includes(Actions.USER_SAVE_DATA);

        const users = this.props.usersStore.users.list == undefined ? [] : this.props.usersStore.users.list;

        const currentUser = this.props.usersStore.userLogged;

        const isBoxScreenMode = this.props.generalStore.boxScreenMode;

        return (
            <>
                <div className="users-management">
                <Sticky  innerZ={80} top=".ant-tabs-nav">
                    <Menu className="local-menu" onClick={this.handleLocalMenuClick} mode="horizontal">
                        <Menu.Item key="new" icon={<PlusCircleOutlined />}>
                            Crear usuario
                        </Menu.Item>
                    </Menu>

                    <Row className={`list-screen-mode-header ${isBoxScreenMode ? 'invisible' : ''}`} >
                        <Col className="ellipsis" md={{ span: 6 }}>Apellidos y nombre</Col> 
                        <Col className="ellipsis" md={{ span: 4 }}>Login</Col>
                        <Col className="ellipsis" md={{ span: 5 }}>Email</Col>
                        <Col className="ellipsis" md={{ span: 3 }}>Rol</Col>
                        <Col className="ellipsis" md={{ span: 3 }}>Último login</Col>                                                 
                        <Col className="ellipsis" md={{ span: 3 }}>Desactivado?</Col>
                    </Row>
                </Sticky>

                <InfiniteScroll
                    dataLength={users.length}
                    next={this.handleInfiniteOnLoad}
                    scrollThreshold="60%"
                    hasMore={!this.state.loading && this.state.hasMore}
                >
                        <List locale={{ emptyText: (<Empty description="No hay resultados" image={Empty.PRESENTED_IMAGE_SIMPLE} />)}}
                            dataSource={users}
                            renderItem={item => (
                                <>
                                    <List.Item className={`${isBoxScreenMode ? 'as-box' : 'as-list'}`} key={item.id} onClick={() => { this.handleShowUserDetail(item) }}>
                                        <Row className={`list-item box-screen-mode ${item.disabled ? 'disabled' : ''} ${Constants.USER_ROLES[item.role]}`} >
                                            <Col className="data" xs={{ span: 20 }} sm={{ span: 21 }}>
                                                <div className="text-data">
                                                    {item.role==Constants.USER_ROLE_IOT && (
                                                        <>
                                                            <p className="title"><WifiOutlined /> {item.username}</p> 
                                                            <p className="username">Usuario especial para conexión con dispositivos IoT remotos</p>
                                                            <p className="role">{Constants.USER_ROLES[item.role]}</p>
                                                        </>
                                                    )}                                                    
                                                    {item.role!=Constants.USER_ROLE_IOT && (
                                                        <>
                                                            <p className="title"><UserOutlined /> {item.surname}, {item.name}</p> 
                                                            <p className="username">{item.username} ({item.email == undefined ? "sin email" : item.email})</p>
                                                            <p className="role">{Constants.USER_ROLES[item.role]}</p>
                                                        </>
                                                    )}
                                                </div>
                                                <Row className="dates">
                                                    <Col xs={{ span: 24 }}>Último login {item.lastLogin == undefined ? "no consta" : new Date(item.lastLogin).toLocaleDateString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</Col>                                                 
                                                </Row>
                                            </Col>
                                            <Col className="options" xs={{ span: 4 }} sm={{ span: 3 }}>
                                            {item.id != currentUser.id &&
                                                <>
                                                <Switch onClick={(checked, event) => { this.handleUserDisabledStateChange(event, checked, item) }} checked={item.disabled} size="default" title="¿Usuario desactivado?" checkedChildren="si" unCheckedChildren="no" />
                                                <br/>
                                                <Popconfirm onClick={(e) => e.stopPropagation()} placement="topRight" title="¿Estás seguro de que quieres eliminar este usuario?" onCancel={(e) => e.stopPropagation()} onConfirm={(event) => { this.handleRemoveUser(event, item) }} okText="Si" cancelText="No">
                                                    <Button type="primary" shape="round" title="Eliminar usuario">Borrar</Button>
                                                </Popconfirm>                                                
                                                </>
                                            }
                                            </Col>
                                        </Row>

                                        <Row className={`list-item list-screen-mode ${item.disabled ? 'disabled' : ''} ${Constants.USER_ROLES[item.role]}`} >
                                            {item.role!=Constants.USER_ROLE_IOT && (
                                                <>
                                                    <Col className="ellipsis" md={{ span: 6 }}><UserOutlined /> {item.surname}, {item.name}</Col> 
                                                    <Col className="ellipsis" md={{ span: 4 }}>{item.username}</Col>
                                                    <Col className="ellipsis" md={{ span: 5 }}>{item.email}</Col>
                                                </>
                                            )}

                                                {item.role==Constants.USER_ROLE_IOT && (
                                                <>
                                                    <Col className="ellipsis" md={{ span: 6 }}><WifiOutlined /> {item.username}</Col> 
                                                    <Col className="ellipsis" md={{ span: 9 }}>Usuario especial para conexión con dispositivos IoT remotos</Col>
                                                </>
                                            )}

                                            <Col className="ellipsis" md={{ span: 3 }}>{Constants.USER_ROLES[item.role]}</Col>
                                            <Col className="ellipsis" md={{ span: 3 }}>{item.lastLogin == undefined ? "no consta" : new Date(item.lastLogin).toLocaleDateString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</Col>                                                 
                                            <Col className="options" md={{ span: 3 }}>
                                            {item.id != currentUser.id &&
                                                <>
                                                    <Switch onClick={(checked, event) => { this.handleUserDisabledStateChange(event, checked, item) }} checked={item.disabled} size="default" title="¿Usuario desactivado?" checkedChildren="si" unCheckedChildren="no" />
                                                    <Popconfirm onClick={(e) => e.stopPropagation()} placement="topRight" title="¿Estás seguro de que quieres eliminar este usuario?" onCancel={(e) => e.stopPropagation()} onConfirm={(event) => { this.handleRemoveUser(event, item) }} okText="Si" cancelText="No">
                                                        <Button className="actionButton" type="link" shape="round" title="Eliminar usuario"><CloseCircleFilled/></Button>
                                                    </Popconfirm>
                                                </>
                                            }
                                            </Col>
                                        </Row>
                                    </List.Item>
                                </>
                            )}
                        >                            
                            {this.state.loading && this.state.hasMore && (
                                <div className="loading-more">
                                    <Spin size="large" />
                                </div>
                            )}

                        </List>
                    </InfiniteScroll>
                </div>


                <Modal forceRender={true} className="user-editor" visible={this.state.showEditor} title={this.state.selectedUser==null ? "Nuevo usuario" : "Editar usuario"} onCancel={this.handleCancelEdit}
                    footer={[
                        <Button key="back" onClick={this.handleCancelEdit}>
                        Cerrar
                        </Button>,
                        <Button key="submit" type="primary" loading={isChangingUserData} disabled={isChangingUserData} onClick={this.handleSaveEdit}>
                        Grabar datos
                        </Button>,
                    ]}
                    >

                    <Form ref={this.editUserForm} initialValues={this.state.selectedUser} onFinish={this.handleUserEditorSubmit} layout="vertical">
                        <Content className="user-profile">

                            <Row justify="center" align="top">

                            <Col className="user-profile-data" xs={{ span: 24 }} >

                                <Form.Item name="id" hidden={true} >
                                </Form.Item>

                                <Form.Item name="username" label="Login" rules={[{ required: true, message: 'Introduce el login del usuario' }]}>
                                    <Input
                                        placeholder="Login de usuario"
                                        bordered={true}
                                        suffix={<EditOutlined key="edit" />}
                                    />
                                </Form.Item>

                                <Form.Item name="role" label="Rol" rules={[{ required: true, message: 'Selecciona el rol del usuario' }]}>
                                    <Select placeholder="Selecciona el rol" onChange={this.handleRoleChange}>
                                        
                                        {Constants.USER_ROLES.map(function(item) {
                                            if (Constants.USER_ROLES.indexOf(item)<Constants.USER_ROLE_SUPERUSER || currentUser.role==Constants.USER_ROLE_SUPERUSER) return(
                                                <Select.Option key={Constants.USER_ROLES.indexOf(item)} value={Constants.USER_ROLES.indexOf(item)}>{item}</Select.Option>
                                            )
                                            }
                                        )}

                                    </Select>
                                </Form.Item>                                
                                {this.state.selectedRole==Constants.USER_ROLE_IOT && (
                                    <span class="iot_message">Rol especial para conexión desde dispositivos IoT remotos. Este rol permite el envío de información por parte de dichos dispositivos, donde todos los datos cargados figurarán como cargados por este usuario. El borrado o desactivación de este usuario puede provocar errores en la recepción de la información de dichos dispositivos</span>
                                )}
                                {this.state.selectedRole!=Constants.USER_ROLE_IOT && (
                                    <>
                                        <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Introduce el nombre' }]} >
                                            <Input
                                            placeholder="Nombre"
                                            bordered={true}
                                            suffix={<EditOutlined key="edit" />}
                                            />
                                        </Form.Item>

                                        <Form.Item name="surname" label="Apellidos" rules={[{ required: true, message: 'Introduce los apellidos' }]} >
                                            <Input
                                            placeholder="Apellidos"
                                            bordered={true}
                                            suffix={<EditOutlined key="edit" />}
                                            />
                                        </Form.Item>

                                        <Form.Item className="form-field" name="email" label="Correo electrónico" rules={[{ required: true, type: "email", message: 'Introduce un Email válido' }]} >
                                            <Input
                                            placeholder="Email"
                                            bordered={true}
                                            suffix={<EditOutlined key="edit" />}
                                            />
                                        </Form.Item>
                                    </>
                                )}

                                <Form.Item className="form-field" name="password" label="Contraseña"  rules={[ { required: false, message: 'Introduce la nueva contraseña' }]}>
                                    <Input.Password
                                    placeholder="Contraseña"
                                    rules={[{ required: true, message: 'Introduce la contraseña' }]}
                                    bordered={true}
                                    iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                                    autoComplete="new-password"
                                    />
                                </Form.Item>

                                <Form.Item className="form-field" name="passwordRepeat"  rules={[ { required: false, message: 'Repite la contraseña' } ]}>

                                    <Input.Password
                                    placeholder="Repetir contraseña"
                                    rules={[{ required: true, message: 'Repite la contraseña' }]}
                                    bordered={true}
                                    iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                                    autoComplete="new-password"
                                    />
                                </Form.Item>
                            </Col>
                            </Row>

                        </Content>

                    </Form>

                </Modal>
            </>
        );
    }
}

export default inject('usersStore','generalStore')(observer(UsersManagement))
