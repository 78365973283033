import React from 'react';
import 'antd/dist/antd.css';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class PictureManualUploader extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
      super(props);

      this.state = {
          previewVisible: false,
          previewImage: '',
          previewTitle: '',
          fileList: [],
      };
    }    

    //-----------------------------------------------------------------------------------------------
    handleCancel = () => this.setState({ previewVisible: false });

    //-----------------------------------------------------------------------------------------------
    handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      this.setState({
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      });
    };

    //-----------------------------------------------------------------------------------------------
    handleBeforeUpload = file => {
      this.setState(state => ({
        fileList: [...state.fileList, file],
      }));
      return false;
    };

    //-----------------------------------------------------------------------------------------------
    handleChange = ({ fileList }) => {
      this.setState({ fileList }, () => {if (this.props.valueChanged) this.props.valueChanged(fileList);});
    }

    //-----------------------------------------------------------------------------------------------
    getFieldValue = () => {

      if (this.state.fileList==undefined || this.state.fileList.length==0) return(undefined);
      
      return(this.state.fileList);
    }

    //-----------------------------------------------------------------------------------------------
    render() {
      const { previewVisible, previewImage, fileList, previewTitle } = this.state;
      
      const uploadButton = (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Cargar archivo</div>
        </div>
      );

      return (
        <>
          <Upload 
            accept={this.props.extension}
            listType="picture-card"
            fileList={fileList}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            beforeUpload={this.handleBeforeUpload}
          >
            {fileList.length >= this.props.maxFiles ? null : uploadButton}
          </Upload>

          <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.handleCancel}>
            <img alt="image" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </>
      );
    }
}

export default PictureManualUploader