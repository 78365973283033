import axios from 'axios';

//------------------------------------------------------------------------------------
export const saveDeviceEditService = (params) => {

    if (params.id!=undefined) {
        const response = axios.post(APP_CONFIG.BACKEND_API_URL+"device/"+params.id+"/change-device-data/", params);
        return(response);
    }
    else {
        const response = axios.post(APP_CONFIG.BACKEND_API_URL+"device/new/", params);
        return(response);
    }    
}

//------------------------------------------------------------------------------------
export const removeDeviceService = (id) => {

    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"device/"+id+"/remove/");

    // Devolver la respuesta, aunque para este tipo de operaciones venga vacía, para capturar el error en caso de que se produzca alguno
    return(response);
}

//------------------------------------------------------------------------------------
export const getDevicesForOrganizationService = (filter, page, order, pageSize) => {

    let params ="";
    
    // eslint-disable-next-line
    if (filter.ref!=undefined && String(filter.ref).length>0) params=params+"ref="+filter.ref+"&";

    // eslint-disable-next-line
    if (filter.params!=undefined && String(filter.params).length>0) params=params+"params="+filter.params+"&"; 

    // eslint-disable-next-line
    if (filter.type!=undefined && String(filter.type).length>0 && String(filter.type)!="-1") params=params+"type="+filter.type+"&"; 

    // eslint-disable-next-line
    if (filter.area!=undefined && String(filter.area).length>0 && String(filter.area)!="-1") params=params+"area="+filter.area+"&"; 

    // eslint-disable-next-line
    if (filter.status!=undefined && String(filter.status).length>0 && String(filter.status)!="-1") params=params+"status="+filter.status+"&"; 

    if (pageSize!=undefined) {
        params=params+"_pagesize="+pageSize+"&";
    }

    if (order!=undefined) {
        params=params+"_orderby="+order;
    }

    let response = axios.get(APP_CONFIG.BACKEND_API_URL+"device/list/for-user-organization/?_page="+page+"&"+params);

    return(response);
}

//------------------------------------------------------------------------------------
export const getDeviceTypesListService = () => {
    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"device/type/list/");
    return(response);
}

//------------------------------------------------------------------------------------
export const getAreasListService = () => {
    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"area/list/");
    return(response);
}

//------------------------------------------------------------------------------------
export const getNetworkStatusService = () => {
    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"network/status/");
    return(response);
}