import { makeAutoObservable, flow } from "mobx";
import { makePersistable } from 'mobx-persist-store';
import * as Actions from "./actionFlags.js";

import generalStore from "./generalStore.js";

import { saveUserEditService, changeEnabledUserStateService, resetPasswordService, changePasswordService, getUserByResetPasswordTokenService, changeUserDataService, removeUserService, sendUserByEmailService, getOrganizationsListService, getUsersForOrganizationService } from '../services/UserService';
import { callServiceEventAware } from ".";
import AuthenticationService from '../services/AuthenticationService';

//#################################################################################################

class usersStore {

    userLogged = {};
    resetPasswordUser = undefined;
    loggedIn = false;
    sentUserEmailFlag = false;
    changedPasswordFlag = false;
    changedUserDataFlag = false;
    passwordLength = 0; // La longitud de la password no se puede obtener de los datos del usuario porque está encriptada
    organizations = [];
    users = {};
    usersFilter = {};
    usersOrderBy = "surname_ASC";
    resetUsersList = false;
    message = "";

    //------------------------------------------------------------------------------------
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })    
        
        makePersistable(this, { name: 'usersStore', properties: ['userLogged', 'loggedIn', 'organizations', 'users', 'usersFilter', 'usersOrderBy', 'passwordLength'], storage: window.sessionStorage });
    }    

    //------------------------------------------------------------------------------------
    doActionRemoveMessage = flow(function*() {
        this.message =  "";
    });
    
    //------------------------------------------------------------------------------------
    doActionLogin = flow(function*(username, password, rememberMe) {
  
        try {
            this.loggedIn = false;

            // IMPORTANTE: el orden de ejecución de las 3 siguientes sentencias es muy importante, deben ejecutarse en ese orden
            const response = yield callServiceEventAware(Actions.LOGIN, AuthenticationService.executeJwtAuthenticationService, username, password, rememberMe);        

            this.loggedIn = true;
            this.userLogged = response.data.user;
            this.passwordLength = password.length;
            
            // Al hacer login se obtiene tanto un token para usar durante la sesión en curso como un refrehtoken para poder usar a largo plazo (en sesiones persistentes cuando se activa el 'rememberme')
            // El refreshtoken sólo se recibe si se ha activado la opción de "rememberme"
            AuthenticationService.registerSuccessfulLoginForJwt(username, response.data.token, response.data.refreshToken);
          }
          catch (error) {
            generalStore.doActionEndService(Actions.LOGIN);
        
            // eslint-disable-next-line 
            if (error.response != null && error.response.status == 401) {
                generalStore.doActionGeneralError("Usuario o contraseña incorrecta");
            }
            else {
                console.log("ERROR EN LLAMADA AL API EN usersStore.js: " + error.message);
                console.log(error);
                generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
            }
          }

    });
  
    //------------------------------------------------------------------------------------
    doActionRememberMe = flow(function*(refreshToken) {

        try {
            // IMPORTANTE: el orden de ejecución de las 3 siguientes sentencias es muy importante, deben ejecutarse en ese orden
            const response = yield callServiceEventAware(Actions.REMEMBER_ME, AuthenticationService.rememberMe, refreshToken);            
            this.loggedIn = true;
            this.userLogged = response.data.user;

            // Asignamos una longitud de password superior al límite impuesto para que no piense que estamos en una primera ejecución
            this.passwordLength = 100; 
            
            // Al hacer login se obtiene tanto un token para usar durante la sesión en curso como un refreshtoken para poder usar a largo plazo (en sesiones persistentes cuando se activa el 'rememberme')
            // El refreshtoken sólo se recibe si se ha activado la opción de "rememberme"
            AuthenticationService.registerSuccessfulLoginForJwt(this.userLogged.username, response.data.token, response.data.refreshToken);
        }
        catch (error) {
            generalStore.doActionEndService(Actions.REMEMBER_ME);
        
            // eslint-disable-next-line
            if (error.response != null && error.response.status == 401) {
                generalStore.doActionGeneralError("Su sesión de trabajo ha caducado, vuelva a entrar para continuar");
                AuthenticationService.logout();
            }
            else {
                console.log("ERROR EN LLAMADA AL API EN usersStore.js: " + error.message);
                console.log(error);
                generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
            }
        }
    });
    
    //------------------------------------------------------------------------------------
    doActionRemindUser = flow(function*(email) {

        try {
            this.sentUserEmailFlag = false;

            yield callServiceEventAware(Actions.REMINDUSER, sendUserByEmailService, email);

            this.sentUserEmailFlag = true;
        }
        catch (error) {
            generalStore.doActionEndService(Actions.REMINDUSER);
            console.log("ERROR EN LLAMADA AL API EN usersStore.js: " + error.message);
            console.log(error);
        
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 404) {
                    generalStore.doActionGeneralError("No existe ningún usuario con ese correo electrónico");
                }
                // eslint-disable-next-line
                else if (error.response.status == 412) {
                    generalStore.doActionGeneralError("No se ha encontrado ningún usuario con ese correo");
                }    
                else {
                    // eslint-disable-next-line
                    if (error.response.status == 503) {
                        generalStore.doActionGeneralError("No es posible conectar con el servidor de correo. Inténtelo más tarde.");
                    }
                    else {
                        generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                    }
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: "+error.message);
        }
    });
  
    //------------------------------------------------------------------------------------
    doActionLogout = flow(function*() {
        try {
            AuthenticationService.logout();
        
            this.userLogged = {};
            this.loggedIn = false;
        }
        catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //------------------------------------------------------------------------------------
    doActionChangeUserData = flow(function*(data) {

        try {

            this.changedUserDataFlag = false;

            const response = yield callServiceEventAware(Actions.CHANGE_USER_DATA, changeUserDataService, data);
            const user = response.data;

            if (this.users.list==undefined) {
                this.changedUserDataFlag = true;
                this.userLogged = user;
            }
            else {
                let currentUsers = [user, ...this.users.list.filter(item => item.id != user.id)];
        
                // Reordenar la lista porque por defecto insertamos el item modificado al final
                currentUsers.sort(this.sortUsers(this.usersOrderBy));

                this.userLogged = user
                this.changedUserDataFlag = true;
                this.users = currentUsers;
            }
        }
        catch (error) {
            generalStore.doActionEndService(Actions.CHANGE_USER_DATA);
            console.log("ERROR EN LLAMADA AL API EN usersStore.js: " + error.message);
            console.log(error);
            
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 500) {
                    generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: "+error.message);
        
        }
        
    });
  
    //------------------------------------------------------------------------------------
    doActionChangePassword = flow(function*(password) {

        try {
            this.changedPasswordFlag = false;
            yield callServiceEventAware(Actions.CHANGE_PASSWORD, changePasswordService, password);
            this.changedPasswordFlag = true;
        }
        catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
            generalStore.doActionEndService(Actions.CHANGE_PASSWORD);
        }
    });

    //------------------------------------------------------------------------------------
    doActionResetPassword = flow(function*(password, token) {

        try {
            this.changedPasswordFlag = false;
            const response = yield callServiceEventAware(Actions.RESET_PASSWORD, resetPasswordService, password, token);
            this.changedPasswordFlag = true;
            this.resetPasswordUser = response.data;
        }
        catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js: "+error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: "+error.message);            
            generalStore.doActionEndService(Actions.RESET_PASSWORD);        
        }
    });

    //------------------------------------------------------------------------------------
    doActionGetUserByResetPasswordToken = flow(function*(token) {

        try {
            const response = yield callServiceEventAware(Actions.USER_GET_BY_RESET_PASSWORD_TOKEN, getUserByResetPasswordTokenService, token);
            this.resetPasswordUser = response.data;
        }
        catch (error) {
            generalStore.doActionEndService(Actions.USER_GET_BY_RESET_PASSWORD_TOKEN);            

            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 401) {   
                    this.resetPasswordUser = "invalid";
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                    console.log("ERROR EN LLAMADA AL API EN userStore.js: " + error.message);
                    console.log(error);
                }
            }
            else {
                generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
                console.log("ERROR EN LLAMADA AL API EN userStore.js: " + error.message);
                console.log(error);
            }            
        }
    });    
        
    //------------------------------------------------------------------------------------
    doActionGetOrganizationsList = flow(function*() {
        try {
            const response = yield getOrganizationsListService();
            this.organizations = response.data;
        }
        catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });
    
    //------------------------------------------------------------------------------------
    doActionGetUsersForOrganizationOfUser = flow(function*(resetList, filter, page) {

        try {
            const response = yield callServiceEventAware(Actions.GET_USERS_FOR_ORGANIZATION, getUsersForOrganizationService, filter, page, this.usersOrderBy);
            
            const users = response.data;
        
            let currentUsers;
            let hasMore = true;
      
            // Si hemos cargado la primera página entonces no se agregan los lotes devueltos, en otro caso si
            if (page > 0) {
              // eslint-disable-next-line
              currentUsers = this.users.list;
      
              if (users.length > 0)
                currentUsers = currentUsers.concat(users);
              else
                hasMore = false; // Si no se devuelven resultados es que se cargaron todos
            }
            else
              currentUsers = users;
      
            this.resetUsersList = resetList;
            this.usersFilter = filter;
            this.users = { hasMore: hasMore, page: page, list: currentUsers };
        } 
            catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js: "+error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_USERS_FOR_ORGANIZATION);
            generalStore.doActionGeneralError("Error accediendo al servidor: "+error.message);
        }
        
    });
    
    //------------------------------------------------------------------------------------
    doActionChangeEnabledUserState = flow(function*(user, disabled) {

        try {
            changeEnabledUserStateService(user, disabled);
            
            const status = disabled ? "desactivado" : "activado";

            if (this.users.list==undefined)
                this.message = "El usuario ha sido "+status;
            else {
                let modifiedUser = this.users.list.filter(item => item.id == user.id)[0];
                modifiedUser.disabled = disabled;

                let currentUsers = [modifiedUser, ...this.users.list.filter(item => item.id != user.id)];

                // Reordenar la lista porque por defecto insertamos el item modificado al final
                currentUsers.sort(this.sortUsers(this.usersOrderBy));

                this.message = "El usuario ha sido "+status;
                this.users = { list: currentUsers };
            }
        } 
        catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js: "+error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: "+error.message);
        }
    });
  
    //------------------------------------------------------------------------------------
    doActionSaveUserEdit = flow(function*(data) {
        try {
            const response = yield callServiceEventAware(Actions.USER_SAVE_DATA, saveUserEditService, data);
            const user = response.data;
            let currentUsers = [];

            if (this.users.list==undefined) {
                this.users = { list: [user] };
            }
            else {
                currentUsers = [user, ...this.users.list.filter(item => item.id != user.id)];

            // Reordenar la lista porque por defecto insertamos el item modificado al final
            currentUsers.sort(this.sortUsers(this.usersOrderBy));

            this.message = "Los datos del usuario han sido grabados correctamente";
            this.users = { list: currentUsers };
            }
        }
        catch (error) {
            generalStore.doActionEndService(Actions.USER_SAVE_DATA);
            console.log("ERROR EN LLAMADA AL API EN usersStore.js: " + error.message);
            console.log(error);
            
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 500) {   
                    if (error.response.data!=null && error.response.data.message!=null && error.response.data.message.toLowerCase()=='username already exists')
                        generalStore.doActionGeneralError("Ya existe un usuario con ese login");
                    else if (error.response.data!=null && error.response.data.message!=null && error.response.data.message.toLowerCase()=='email already exists')
                        generalStore.doActionGeneralError("Ya existe un usuario con ese email");
                    else
                        generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: "+error.message);
        }
        
    });  
 
    //------------------------------------------------------------------------------------
    doActionRemoveUser = flow(function*(data) {
        try {
            yield callServiceEventAware(Actions.USER_REMOVE, removeUserService, data);

            if (this.users.list!=undefined) {
                let currentUsers = [...this.users.list.filter(item => item.id != data)];

                this.message = "El usuario ha sido correctamente eliminado";
                this.users = { list: currentUsers };
            }
        }
        catch (error) {
            generalStore.doActionEndService(Actions.USER_REMOVE);
            console.log("ERROR EN LLAMADA AL API EN usersStore.js: " + error.message);
            console.log(error);
            
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 500) {   
                    generalStore.doActionGeneralError("Error al eliminar el usuario");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: "+error.message);
        }
        
    });      

    //------------------------------------------------------------------------------------
    doActionChangeOrderBy = flow(function*(orderBy) {
        this.usersOrderBy=orderBy;
    });     


    //------------------------------------------------------------------------------------
    sortUsers(sortBy) {         
        return function (a, b) {
            let p = sortBy.split("_");

            if (p[1].toUpperCase() == "ASC")
                return eval('a.'+p[0]+'.toString().localeCompare(b.'+p[0]+'.toString())');
            else
                return eval('b.'+p[0]+'.toString().localeCompare(a.'+p[0]+'.toString())');
        }
    }
}

export default new usersStore()
