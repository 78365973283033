import { makeAutoObservable, flow } from "mobx";
import { makePersistable } from 'mobx-persist-store';
import * as Actions from "./actionFlags.js";

import generalStore from "./generalStore.js";

import { getSensorsStatsService, downloadCSVService, getSensorsForOrganizationService, uploadSingleSensorDataService, uploadZipSensorDataService, replaySensorService, removeSensorService, demoImageAnalysisService } from '../services/SensorService';
import { callServiceEventAware } from ".";

//#################################################################################################

class sensorsStore {

    sensors = {}; 
    sensorsFilter = {};
    sensorsStatsFilter = {};
    demoResult = {};
    sensorsOrderBy = "saveDate_DESC";
    resetSensorsList = false;
    sensorsStats = [];
    message = "";

    //------------------------------------------------------------------------------------
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })    
        
        makePersistable(this, { name: 'sensorsStore', properties: ['sensors', 'sensorsFilter', 'sensorsOrderBy'], storage: window.sessionStorage });
    }    

    //------------------------------------------------------------------------------------
    doActionRemoveMessage = flow(function*() {
        this.message =  "";
    });
            
    //------------------------------------------------------------------------------------
    doActionGetSensorsForOrganizationOfUser = flow(function*(resetList, filter, page, pageSize) {
        
        try {
            const response = yield callServiceEventAware(Actions.GET_SENSORS_FOR_ORGANIZATION, getSensorsForOrganizationService, filter, page, this.sensorsOrderBy, pageSize);
            
            const sensors = response.data;
        
            let currentSensors;
            let hasMore = true;
      
            // Si hemos cargado la primera página entonces no se agregan los elementos devueltos, en otro caso si
            if (page > 0) {
              // eslint-disable-next-line
              currentSensors = this.sensors.list;
      
              if (sensors.length > 0)
                currentSensors = currentSensors.concat(sensors);
              else
                hasMore = false; // Si no se devuelven resultados es que se cargaron todos
            }
            else
              currentSensors = sensors;
      
            this.resetSensorsList = resetList;
            this.sensorsFilter = filter;
            this.sensors = { hasMore: hasMore, page: page, list: currentSensors };
        } 
            catch (error) {
            console.log("ERROR EN LLAMADA AL API EN sensorsStore.js: "+error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_SENSORS_FOR_ORGANIZATION);
            generalStore.doActionGeneralError("Error accediendo al servidor: "+error.message);
        }
        
    });

    //------------------------------------------------------------------------------------
    doActionDownloadCSV = flow(function*(filter) {
        
        try {
            const response = yield downloadCSVService(filter);
        } 
            catch (error) {
            console.log("ERROR EN LLAMADA AL API EN sensorsStore.js: "+error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: "+error.message);
        }
        
    });

    //------------------------------------------------------------------------------------
    doActionUploadSingleSensorData = flow(function*(idDevice, data) {
        try {
            const response = yield callServiceEventAware(Actions.LOAD_SINGLE_SENSOR_DATA, uploadSingleSensorDataService, idDevice, data);
            
            const sensor = response.data;

            if (this.sensors.list==undefined) {
                this.sensors= { list: [sensor] };
            }
            else {
                let currentSensors = [sensor, ...this.sensors.list.filter(item => item.id != sensor.id)];

                // Reordenar la lista porque por defecto insertamos el item modificado al final
                currentSensors.sort(this.sortSensors(this.sensorsOrderBy));

                this.sensors = { list: currentSensors };
            }

            this.message = "Los datos del sensor han sido grabados correctamente";
        }
        catch (error) {
            generalStore.doActionEndService(Actions.LOAD_SINGLE_SENSOR_DATA);
            console.log("ERROR EN LLAMADA AL API EN sensorsStore.js: " + error.message);
            console.log(error);
            
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 500) {   
                        generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: "+error.message);
        }
        
    });  

    //------------------------------------------------------------------------------------
    doActionUploadZipSensorData = flow(function*(data) {
        try {
            const response = yield callServiceEventAware(Actions.LOAD_ZIP_SENSOR_DATA, uploadZipSensorDataService, data);

            const sensors = response.data;

            this.message = "Los datos de los sensores han sido grabados correctamente, las imágenes se colocarán en la cola para su procesado, esto puede llevar algún tiempo";
            if (this.sensors.list==undefined) {
                this.sensors= { list: [sensors] };
            }
            else {
                let currentSensors = [...sensors, ...this.sensors.list];

                // Reordenar la lista porque por defecto insertamos el item modificado al final
                currentSensors.sort(this.sortSensors(this.sensorsOrderBy));

                this.sensors = { list: currentSensors };
            }
        }
        catch (error) {
            generalStore.doActionEndService(Actions.LOAD_ZIP_SENSOR_DATA);
            console.log("ERROR EN LLAMADA AL API EN sensorsStore.js: " + error.message);
            console.log(error);
            
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 500) {   
                    if (error.response.data!=null && error.response.data.message!=null && error.response.data.message.toLowerCase().startsWith('device with this ref not found')) {                    
                        let device=error.response.data.message.substring(error.response.data.message.indexOf(":")+1);

                        generalStore.doActionGeneralError("Una de las imágenes en el ZIP tiene una referencia de dispositivo errónea ("+device+"), no se ha cargado ningún dato. Corrija el problema y vuelva a intentarlo.");
                    } 
                    else
                        generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error, es posible que el archivo que intenta cargar sea demasiado grande, el tamaño máximo es de 1Gb");
        }
        
    });  

    //------------------------------------------------------------------------------------
    doActionRemoveSensor = flow(function*(data) {
        try {
            yield callServiceEventAware(Actions.SENSOR_REMOVE, removeSensorService, data);

            if (this.sensors.list!=undefined) {
                let currentSensors = [...this.sensors.list.filter(item => item.id != data)];

                this.message = "Los datos de sensores han sido correctamente eliminados";
                this.sensors = { list: currentSensors };
            }

        }
        catch (error) {
            generalStore.doActionEndService(Actions.SENSOR_REMOVE);
            console.log("ERROR EN LLAMADA AL API EN sensorsStore.js: " + error.message);
            console.log(error);
            
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 500) {   
                    generalStore.doActionGeneralError("Error al eliminar los datos de sensores");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: "+error.message);
        }
        
    }); 

    //------------------------------------------------------------------------------------
    doActionReplaySensor = flow(function*(data) {
        try {
            const response = yield callServiceEventAware(Actions.SENSOR_REPLAY, replaySensorService, data);

            const sensor = response.data;

            let currentSensors = [sensor, ...this.sensors.list.filter(item => item.id != sensor.id)];

            // Reordenar la lista porque por defecto insertamos el item modificado al final
            currentSensors.sort(this.sortSensors(this.sensorsOrderBy));

            this.sensors = { list: currentSensors };

            this.message = "Se ha solicitado correctamente el reprocesamiento de los datos del sensor, esto puede llevar algún tiempo";
        }
        catch (error) {
            generalStore.doActionEndService(Actions.SENSOR_REPLAY);
            console.log("ERROR EN LLAMADA AL API EN sensorsStore.js: " + error.message);
            console.log(error);
            
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 500) {   
                    generalStore.doActionGeneralError("Error al solicitar el reprocesamiento de los datos de sensores");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: "+error.message);
        }
        
    }); 

    //------------------------------------------------------------------------------------
    doActionDemoImageAnalysis = flow(function*(data) {
        try {
            const response = yield callServiceEventAware(Actions.LOAD_DEMO_SENSOR_DATA, demoImageAnalysisService, data);
            
            this.demoResult = response.data;

            this.message = "La imagen ha sido analizada correctamente";
        }
        catch (error) {
            generalStore.doActionEndService(Actions.LOAD_DEMO_SENSOR_DATA);
            console.log("ERROR EN LLAMADA AL API EN sensorsStore.js: " + error.message);
            console.log(error);
            
            // eslint-disable-next-line
            if (error.response!= null) {
                // eslint-disable-next-line
                if (error.response.status == 500) {   
                        generalStore.doActionGeneralError("Error al analizar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: "+error.message);
        }
        
    });  

    //------------------------------------------------------------------------------------
    doActionChangeOrderBy = flow(function*(orderBy) {
        this.sensorsOrderBy=orderBy;
    });    

    //------------------------------------------------------------------------------------
    sortSensors(sortBy) {         
        return function (a, b) {
            let p = sortBy.split("_");

            if (p[1].toUpperCase() == "ASC")
                return eval('a.'+p[0]+'.toString().localeCompare(b.'+p[0]+'.toString())');
            else
                return eval('b.'+p[0]+'.toString().localeCompare(a.'+p[0]+'.toString())');
        }
    }    

    //------------------------------------------------------------------------------------
    doActionGetSensorsStats = flow(function*(filter) {
        
        try {
            const response = yield callServiceEventAware(Actions.GET_SENSORS_STATS, getSensorsStatsService, filter);
            this.sensorsStats = response.data;

            this.sensorsStatsFilter = filter;
        }
        catch (error) {
            generalStore.doActionEndService(Actions.GET_SENSORS_STATS);
            console.log("ERROR EN LLAMADA AL API EN devicesStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });    
}

export default new sensorsStore()
