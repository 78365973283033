import React from 'react';

// Librería para enrutamiento de las vistas
import ReactDOM from 'react-dom';

// Librería para soporte de MobX
import { configure } from "mobx";
import { Provider } from "mobx-react";

// Archivos CSS de estilo generales aplicables a toda la aplicación
import 'antd/dist/antd.css';
import './index.scss';
import Routing from './Routing.js';
import AuthenticationService from './services/AuthenticationService';

import rootStore from "./stores"; //Por defecto cojerá el index.js en esa carpeta

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

console.log("REACTJS ENVIRONMENT: " + process.env.NODE_ENV);

AuthenticationService.init();

// Parámetros de configuración de MobX
configure({
  enforceActions: "always",
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: false,
  disableErrorBoundaries: false
});

// ATENCION! No se pueden configurar aquí los parámetros globales de persistencia de mobx-persist-store con configurePersistable
// Esto es debido a que para que funcionen debería hacerse antes de la creación de cualquier store y es complicado hacerlo, así que mejor se declaran en cada store específicamente

//------------------------------------------------------------------------------------

// ATENCIÓN!!! La configuración de los diferentes documentos mostrados se hace en el archivo publico "config.js" que se carga aquí
// Este método permite cambiar parámetros de configuración si necesidad de hacer un build de la aplicación ReactJS de nuevo

const scriptConfig = document.createElement("script");
scriptConfig.src = process.env.REACT_APP_CONFIG_FILE;
scriptConfig.async = true;
document.body.appendChild(scriptConfig);

const scriptDict = document.createElement("script");
scriptDict.src = "./config-common.js";
scriptDict.async = true;
document.body.appendChild(scriptDict);


scriptConfig.onload = () => {
  ReactDOM.render(
    // Se rodea toda la aplicación con los stores que puedan acceder a ellos desde cualquier parte de la misma
    <Provider {...rootStore}>
      <Routing />
    </Provider>,
    document.getElementById('root')
  )
}
