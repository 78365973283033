import React from 'react';
import { Button, Input, Row, Col, Select, message, Tooltip } from 'antd';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";

import "./DevicesFilter.scss";
import * as Constants from "../constants";

const { Option } = Select;
const { Search } = Input;

//#################################################################################################

class DevicesFilter extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);

        this.state = {
            ref: "",
            params: "",
            status: -1,
            type: -1,
            area: -1
        }

    }

    //-----------------------------------------------------------------------------------------------
    componentDidMount() {
        // IMPORTANTE! ATENCION!
        // Este filtro se usa en DOS pestañas de la misma pantalla (listado de dispositivos y mapa de dispositivos), y para que funcione bien y haga dos
        // veces el componentDidMount cada uno de estos dos componentes debe de tener una clave "key" diferente, en otro caso no funcionaría
        // (sólo entraría una vez por este método).

        // por lo que el cambio de pestaña no provoca una llamada a componentDidMount      
        if (this.props.source=="devicesmap") {
            // eslint-disable-next-line
            if (this.props.devicesStore.devicesInMapFilter != undefined) {
                
                let currentFilter = this.props.devicesStore.devicesInMapFilter;
                
                // eslint-disable-next-line
                if (currentFilter!=undefined) {
                    this.setState(currentFilter);
                }
            }
        }
        else {
            // eslint-disable-next-line
            if (this.props.devicesStore.devicesFilter != undefined) {
                
                let currentFilter = this.props.devicesStore.devicesFilter;
                
                // eslint-disable-next-line
                if (currentFilter!=undefined) {
                    this.setState(currentFilter);
                }
            }
        } 
    }

    //-----------------------------------------------------------------------------------------------
    handleReset = () => {
        this.setState({ ref: "", params: "", status: -1, type: -1, area: -1 }, () => { message.success("El filtro se ha eliminado"); this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleRefChange = (event) => {
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ ref: event.target.value }, () => { this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleParamsChange = (event) => {
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ params: event.target.value }, () => { this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleStatusChange = (data) => {    
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ status: data.value }, () => { this.doSearch() });
    }
    
    //-----------------------------------------------------------------------------------------------
    handleAreaChange = (data) => {    
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ area: data.value }, () => { this.doSearch() });
    }

    //-----------------------------------------------------------------------------------------------
    handleTypeChange = (data) => {    
        // OJO! El callback de setState se llama cuando se hace efectivo el cambio en el estado, si no se hace así al leer el estado desde doSearch no estaría actualizado
        this.setState({ type: data.value }, () => { this.doSearch() });
    }

    
    //-----------------------------------------------------------------------------------------------
    doSearch = () => {        
        
        this.props.devicesStore.doActionGetDevicesForOrganizationOfUser(this.props.source, true, this.state, 0);
        window.scrollTo(0, 0);
    }

    //-----------------------------------------------------------------------------------------------
    render() { 
             
        const deviceTypes = this.props.devicesStore.deviceTypes;
        const areas = this.props.devicesStore.areas;

        return (
            <>
                <Row justify="center" className="devices-management-filter">
                    <Col xs={{ span: 24 }} md={{ span: 10 }} xl={{ span: 6}} className="text-option">
                        <span className="field">Referencia</span> <Search placeholder="Buscar por referencia" value={this.state.ref} onChange={this.handleRefChange} />
                    </Col>                            
                    <Col xs={{ span: 24 }} md={{ span: 10 }} xl={{ span: 6}} className="text-option">
                        <span className="field">Parámetro <Tooltip title={<>Permite filtrar por un parámetro del dispositivo, para lo cual hay que especificar el filtro de la forma:<br/>PARAMETRO CONDICION VALOR<br/><br/>Ejemplos:<br/><i>bateria&gt;100</i><br/><i>fabricante='Siemens'</i></>}><QuestionCircleOutlined/></Tooltip>
                        </span> <Search placeholder="Buscar en los parámetros" value={this.state.params} onChange={this.handleParamsChange} />
                    </Col>                            
                    <Col xs={{ span: 24 }} md={{ span: 7 }} xl={{ span: 4 }} className="select-option">
                        <span className="field">Tipo</span> 
                            <Select labelInValue defaultValue={{value: -1}} value={{value: this.state.type}} onChange={this.handleTypeChange} getPopupContainer={() => document.getElementById('footer')}>
                            <Option key="-1" value={-1}>Todos</Option>

                            {deviceTypes.map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))}

                        </Select>
                    </Col>   
                    <Col xs={{ span: 24 }} md={{ span: 7 }} xl={{ span: 4 }} className="select-option">
                        <span className="field">Área geográfica</span> 
                            <Select labelInValue defaultValue={{value: -1}} value={{value: this.state.area}} onChange={this.handleAreaChange} getPopupContainer={() => document.getElementById('footer')}>
                            <Option key="-1" value={-1}>Todas</Option>

                            {areas.map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))}

                        </Select>
                    </Col>   
                    <Col xs={{ span: 24 }} md={{ span: 7 }} xl={{ span: 3 }} className="select-option">
                        <span className="field">Estado</span> 
                            <Select labelInValue defaultValue={{value: -1}} value={{value: this.state.status}} onChange={this.handleStatusChange} getPopupContainer={() => document.getElementById('footer')}>
                            <Option key="-1" value={-1}>Todos</Option>

                            {Constants.DEVICE_STATUS.map(item => (
                                    <Select.Option key={Constants.DEVICE_STATUS.indexOf(item)} value={Constants.DEVICE_STATUS.indexOf(item)}>{item}</Select.Option>
                            ))}

                        </Select>
                    </Col>   
                    <Col xs={{ span: 24 }} className="delete-option">
                        <Button type="primary" shape="round" icon={<DeleteOutlined />} title="Eliminar filtro" onClick={this.handleReset}>Borrar</Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default inject('devicesStore','generalStore')(observer(DevicesFilter))
