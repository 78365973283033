import React from "react";
import { Layout, Row, Col, Collapse, Button, Tabs, message, Select, Modal } from 'antd';
import { AppstoreOutlined, ReloadOutlined, SyncOutlined, UnorderedListOutlined, DownloadOutlined } from '@ant-design/icons';
import Sticky from 'react-stickynode';
import { observer, inject } from "mobx-react";

import AppHeader from "../components/AppHeader.js";
import Devices from "../components/Devices.js";
import Sensors from "../components/Sensors.js";
import DevicesMap from "../components/DevicesMap.js";
import DevicesFilter from "../components/DevicesFilter.js";
import SensorsFilter from "../components/SensorsFilter.js";
import * as Actions from "../stores/actionFlags.js";

import * as Constants from "../constants";

import "./SensorsPanel.scss";

const { Footer, Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;

//#################################################################################################

class SensorsPanel extends React.Component {

  //-----------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      collapsed: "1"
    }
  }
      
  //-----------------------------------------------------------------------------------------------
  handleTabChange = (key) => {

    this.setState({ collapsed: "1" });
    this.props.generalStore.doActionSetSensorsPanelActiveTab(key);    
  }

  //-----------------------------------------------------------------------------------------------
  handleFilterCollapsed = (event) => {

    // eslint-disable-next-line
    this.setState({ collapsed: this.state.collapsed == "1" ? "0" : "1" });
  }

  //-----------------------------------------------------------------------------------------------
  handleRefreshData = () => {
    if (this.props.generalStore.sensorsPanelActiveTab==1) {
        this.props.devicesStore.doActionGetDevicesForOrganizationOfUser("deviceslist", true, this.props.devicesStore.devicesFilter, 0);
        message.success("Obteniendo el listado de dispositivos...");
        window.scrollTo(0, 0);
    }

    if (this.props.generalStore.sensorsPanelActiveTab==2) {
        this.props.sensorsStore.doActionGetSensorsForOrganizationOfUser(true, this.props.sensorsStore.sensorsFilter, 0);
        message.success("Obteniendo el listado de datos de sensores...");
        window.scrollTo(0, 0);
    }
  }

  //-----------------------------------------------------------------------------------------------
  handleChangeScreenMode = (boxMode) => {

    this.props.generalStore.doActionChangeScreenMode(boxMode);
  }

  //-----------------------------------------------------------------------------------------------
  handleChangeOrder = (orderby) => {

    if (this.props.generalStore.sensorsPanelActiveTab==1) {
      this.props.devicesStore.doActionChangeOrderBy(orderby);
      this.props.devicesStore.doActionGetDevicesForOrganizationOfUser("deviceslist", true, this.props.devicesStore.devicesFilter, 0);
      message.success("Obteniendo el listado de dispositivos...");
      window.scrollTo(0, 0);
    }    
  
    if (this.props.generalStore.sensorsPanelActiveTab==2) {
      this.props.sensorsStore.doActionChangeOrderBy(orderby);
      this.props.sensorsStore.doActionGetSensorsForOrganizationOfUser(true, this.props.sensorsStore.sensorsFilter, 0);
      message.success("Obteniendo el listado de datos de sensores...");
      window.scrollTo(0, 0);
    }        
  }

  //-----------------------------------------------------------------------------------------------
  handleDownloadCSV = () => {

    Modal.confirm({
        title: 'SOLICITUD DE DESCARGA DE INFORMACIÓN',
        content: (
          <div>
          <p>Has solicitado una descarga de información con los datos del actual listado.</p>
          <p>Para ello el sistema generará un archivo CSV que puede ser abierto en Excel con todos los datos necesarios de acuerdo a la información contenida en el actual listado.</p>
          <p>Se te comunicará el enlace de la descarga en tu dirección de email cuando termine el proceso.</p>
          </div>
        ),
        okText: 'Continuar',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => { 
          this.props.sensorsStore.doActionDownloadCSV(this.props.sensorsStore.sensorsFilter);        
        },
        onCancel: () => { }
    });
  }

//-----------------------------------------------------------------------------------------------
  render() {
    const sensorsPanelActiveTab = this.props.generalStore.sensorsPanelActiveTab;
    
    const isFetchingData = this.props.generalStore.processing.includes(Actions.GET_DEVICES_FOR_ORGANIZATION || Actions.GET_SENSORS_FOR_ORGANIZATION);
    const isBoxScreenMode = this.props.generalStore.boxScreenMode;

    // Necesario para hacer el menú de tipo "sticky"
    const renderTabBar = (props, DefaultTabBar) => (
      <Sticky innerZ={90}>
        {({ style }) => (
          <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
        )}
      </Sticky>
    )

    return (
      <>
        <Layout className="sensors-panel-layout">
          <AppHeader/>

          <Content>
            <Row justify="center" align="top">
              <Col xs={{ span: 24 }}>

                  <Tabs renderTabBar={renderTabBar} className="tab-menu" activeKey={sensorsPanelActiveTab} onChange={this.handleTabChange} centered>

                    <TabPane tab="DISPOSITIVOS" key="1">
                      <Devices />
                    </TabPane>

                    <TabPane tab="SENSORES" key="2">
                      <Sensors />
                    </TabPane>

                    <TabPane tab="MAPA" key="3">
                      <DevicesMap />
                    </TabPane>

                  </Tabs>
                

              </Col>
            </Row>
          </Content>

          <Footer id="footer">

          { sensorsPanelActiveTab == 1 &&
            <Select value={this.props.devicesStore.devicesOrderBy} placeholder="Ordenar por" size="small"  getPopupContainer={() => document.getElementById('footer')} className="order-by-select" onChange={this.handleChangeOrder}>
              <Select.Option key="1" value="ref_ASC">&#9650; referencia</Select.Option>
              <Select.Option key="2" value="type.name_ASC">&#9650; tipo</Select.Option>
              <Select.Option key="3" value="area.name_ASC">&#9650; area</Select.Option>
              <Select.Option key="4" value="status_ASC">&#9650; estado</Select.Option>
              <Select.Option key="5" value="lastConnectionDate_DESC">&#9660; fecha último dato</Select.Option>
            </Select>
          }

          { sensorsPanelActiveTab == 2 &&
            <Select value={this.props.sensorsStore.sensorsOrderBy} placeholder="Ordenar por" size="small" getPopupContainer={() => document.getElementById('footer')} className="order-by-select" onChange={this.handleChangeOrder}>
              <Select.Option key="1" value="saveDate_DESC">&#9660; fecha último dato</Select.Option>
              <Select.Option key="2" value="id_DESC">&#9660; id</Select.Option>
              <Select.Option key="3" value="device.ref_ASC">&#9650; dispositivo</Select.Option>
              <Select.Option key="4" value="device.area.name_ASC">&#9650; area</Select.Option>
            </Select>
          }

          { !isFetchingData && sensorsPanelActiveTab != 3 &&
            <Button className="reloading" type="primary" shape="circle" size="middle" title="Recargar datos" icon={<ReloadOutlined />} onClick={(event) => { this.handleRefreshData() }} />
          }
          { isFetchingData && sensorsPanelActiveTab != 3 &&
            <Button className="reloading" type="primary" shape="circle" size="middle" disabled style={{ backgroundColor: `#AAAAAA` }}><SyncOutlined spin /></Button>
          }
          
          { !isBoxScreenMode && sensorsPanelActiveTab != 3 &&
            <Button className="screen-mode" type="primary" shape="circle" size="middle" title="Cambiar a modo cajas" icon={<AppstoreOutlined />} onClick={(event) => { this.handleChangeScreenMode(true) }} />
          }
          { isBoxScreenMode && sensorsPanelActiveTab != 3 &&
            <Button className="screen-mode" type="primary" shape="circle" size="middle" title="Cambiar a modo listado" icon={<UnorderedListOutlined />} onClick={(event) => { this.handleChangeScreenMode(false) }} />
          }

          { sensorsPanelActiveTab==2 && this.props.usersStore.userLogged.role>=Constants.USER_ROLE_ADMINISTRATOR &&
            <Button className="download" type="primary" shape="circle" size="middle" title="Descargar CSV con datos del listado" icon={<DownloadOutlined />} onClick={(event) => { this.handleDownloadCSV() }} />
          }            

            <Collapse className="filter" activeKey={this.state.collapsed} onChange={this.handleFilterCollapsed}>              
              {// eslint-disable-next-line
                sensorsPanelActiveTab == 1 &&
                <Panel header="Filtrar dispositivos">
                  <DevicesFilter key="1" source="deviceslist" />
                </Panel> 
              }
              {// eslint-disable-next-line
                sensorsPanelActiveTab == 2 &&
                <Panel header="Filtrar datos de sensores">
                  <SensorsFilter />
                </Panel> 
              } 
              {// eslint-disable-next-line
                sensorsPanelActiveTab == 3 &&
                <Panel header="Filtrar dispositivos">
                  <DevicesFilter key="2" source="devicesmap" />
                </Panel> 
              }
            </Collapse>
          </Footer>
        </Layout>
      </>
    );
  }
}

export default inject('usersStore', 'generalStore','devicesStore','sensorsStore')(observer(SensorsPanel))
