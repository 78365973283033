import React from 'react';
import { Col, Button, Row, message} from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import { MapContainer, MapConsumer, TileLayer, Popup, Marker, Tooltip} from 'react-leaflet';

import { observer, inject } from "mobx-react";

import "./DevicesMap.scss";
import 'leaflet/dist/leaflet.css';

import * as Constants from "../constants";

import defaultIcon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import brokenIcon from "../assets/images/marker-broken.png";
import inactiveIcon from "../assets/images/marker-inactive.png";
import retiredIcon from "../assets/images/marker-retired.png";
import maintenanceIcon from "../assets/images/marker-maintenance.png";
import malfunctionIcon from "../assets/images/marker-malfunction.png";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

let DefaultIcon = L.icon({
    iconUrl: defaultIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

let BrokenIcon = L.icon({
    iconUrl: brokenIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

let InactiveIcon = L.icon({
    iconUrl: inactiveIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

let RetiredIcon = L.icon({
    iconUrl: retiredIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

let MaintenanceIcon = L.icon({
    iconUrl: maintenanceIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

let MalfunctionIcon = L.icon({
    iconUrl: malfunctionIcon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

//#################################################################################################

class DevicesMap extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
    }
  
    //-----------------------------------------------------------------------------------------------
    componentDidMount() {
        
        this.props.devicesStore.doActionGetDeviceTypesList();  
        this.props.devicesStore.doActionGetAreasList();

        if (this.props.devicesStore.devicesFilter.area==undefined || this.props.devicesStore.devicesFilter.area<0) {
            message.error("Para utilizar el mapa recomendamos filtrar por área geográfica");
        }
    }

    //-----------------------------------------------------------------------------------------------
    componentDidUpdate() {

        if (this.props.generalStore.error != undefined && this.props.generalStore.error.length > 0) {
            message.error(this.props.generalStore.error,6);
            this.props.generalStore.doActionRemoveGeneralError();            
        }
        else if (this.props.devicesStore.message != undefined && this.props.devicesStore.message.length > 0) {
            message.success(this.props.devicesStore.message);
            this.props.devicesStore.doActionRemoveMessage();
        }
    }

    //-----------------------------------------------------------------------------------------------
    handleShowDeviceDetail = (item) => {
        let sensorsFilter = {
            id: "",
            data: "",
            device: item.ref,
            area: -1,
            date1: null,
            date2: null,
        }

        this.props.sensorsStore.doActionGetSensorsForOrganizationOfUser(true, sensorsFilter, 0);

        // Esperar un segundo antes de cambiar para que de tiempo a procesar la petición anterior
        setTimeout(function(store){store.doActionSetSensorsPanelActiveTab("2")}, 1000, this.props.generalStore)  

        window.scrollTo(0, 0);
        message.success("Mostrando sensores del dispositivo "+item.ref+", elimine el filtro para verlos todos de nuevo");
    }


    //-----------------------------------------------------------------------------------------------
    handleMapBoundsChange = (map) => {
        
        let p1=map.getBounds().getSouthWest();
        let p2=map.getBounds().getNorthEast();
        // No hacemos nada con esto (de momento)
    }  

    //-----------------------------------------------------------------------------------------------
    doRefresh = () => {    
        // Esto no lo usamos porque en realidad hemos cargado todos los dispositivos en el mapa directamente al ser pocos
    }

    //-----------------------------------------------------------------------------------------------
    render() {        

        let devicesInMap = this.props.devicesStore.devicesInMap == undefined ? [] : this.props.devicesStore.devicesInMap;

        return (
        
        <div className="devices-map">
            <Row>
                <Col xs={{ span: 24 }}>
                    <div className="map-panel-content">
                    <MapContainer center={[39.47450412424775, -0.3760601581288725]} zoom={7} scrollWheelZoom={true} position="leaflet-bottom leaflet-right">
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        {devicesInMap.map((deviceData) => {
                                               
                            let position = [deviceData.latitude, deviceData.longitude];
                            
                            let icon = DefaultIcon;

                            if (deviceData.status==Constants.DEVICE_STATUS_BROKEN) icon=BrokenIcon;
                            if (deviceData.status==Constants.DEVICE_STATUS_ERROR_CONDITION) icon=MalfunctionIcon;
                            if (deviceData.status==Constants.DEVICE_STATUS_INACTIVE) icon=InactiveIcon;
                            if (deviceData.status==Constants.DEVICE_STATUS_RETIRED) icon=RetiredIcon;
                            if (deviceData.status==Constants.DEVICE_STATUS_MAINTENANCE) icon=MaintenanceIcon;

                            return(                                
                                <Marker key={deviceData.id} position={position} icon={icon} attribution="fff">
                                <Tooltip>{deviceData.ref} {deviceData.status!=Constants.DEVICE_STATUS_ACTIVE ? "("+Constants.DEVICE_STATUS[deviceData.status]+")":""}</Tooltip>
                                <Popup>
                                <div className="data">
                                    <p className="title"><VideoCameraOutlined /> <Button type="link" onClick={() => {this.handleShowDeviceDetail(deviceData)}}>DISPOSITIVO {deviceData.ref}</Button></p> 
                                    <p className="name">{deviceData.type.name}</p>                                    
                                    <p className="area">{deviceData.area.name}</p>
                                    <p className="status" style={{ color: `${Constants.DEVICE_STATUS_COLORS[deviceData.status]}`}}>&#11044; Estado {Constants.DEVICE_STATUS[deviceData.status]}</p>
                                    <div className="params">
                                        {deviceData.runParams &&
                                            Object.keys(deviceData.runParams).map(param => (
                                                <p key={param}>{param}: {deviceData.runParams[param]}</p>
                                            ))
                                        }
                                    </div>
                                    <div className="date">Último dato {deviceData.lastConnectionDate == undefined ? "no consta" : new Date(deviceData.lastConnectionDate).toLocaleDateString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</div>
                                </div>
                                </Popup>
                                </Marker>);
                            }
                        )}

                        <MapConsumer>
                        {(map) => {

                            if (this.mapControl==undefined) {
                                this.mapControl=map;                          
                                map.on("moveend", (e) => this.handleMapBoundsChange(map));
                            }

                            return null;
                        }}
                        </MapConsumer>
                    </MapContainer>
                    </div>
                </Col>
            </Row>
        </div>
        );
    }
}

export default inject('sensorsStore','devicesStore','generalStore')(observer(DevicesMap))
