import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');
//------------------------------------------------------------------------------------
export const uploadSingleSensorDataService = (idDevice, params) => {

    const response = axios.post(APP_CONFIG.BACKEND_API_URL+"device/"+idDevice+"/load-sensor-data/", params);

    return(response);
}

//------------------------------------------------------------------------------------
export const uploadZipSensorDataService = (params) => {

    const response = axios.post(APP_CONFIG.BACKEND_API_URL+"sensor/load-zip-data/", params);

    return(response);
}

//------------------------------------------------------------------------------------
export const removeSensorService = (id) => {

    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"sensor/"+id+"/remove/");

    // Devolver la respuesta, aunque para este tipo de operaciones venga vacía, para capturar el error en caso de que se produzca alguno
    return(response);
}

//------------------------------------------------------------------------------------
export const replaySensorService = (id) => {

    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"sensor/"+id+"/replay/");

    // Devolver la respuesta, aunque para este tipo de operaciones venga vacía, para capturar el error en caso de que se produzca alguno
    return(response);
}

//------------------------------------------------------------------------------------
export const demoImageAnalysisService = (params) => {

    const response = axios.post(APP_CONFIG.BACKEND_API_URL+"demo/", params);

    return(response);
}

//------------------------------------------------------------------------------------
export const getSensorsForOrganizationService = (filter, page, order, pageSize) => {

    let params ="";    

    // eslint-disable-next-line
    if (filter.id!=undefined && String(filter.id).length>0) params=params+"id="+filter.id+"&";

    // eslint-disable-next-line
    if (filter.data!=undefined && String(filter.data).length>0) params=params+"data="+filter.data+"&"; 

    // eslint-disable-next-line
    if (filter.device!=undefined && String(filter.device).length>0) params=params+"device="+filter.device+"&";

    // eslint-disable-next-line
    if (filter.userCapture!=undefined && String(filter.userCapture).length>0) params=params+"user_capture="+filter.userCapture+"&";

    // eslint-disable-next-line
    if (filter.type!=undefined && String(filter.type).length>0 && String(filter.type)!="-1") params=params+"type="+filter.type+"&"; 

    // eslint-disable-next-line
    if (filter.area!=undefined && String(filter.area).length>0 && String(filter.area)!="-1") params=params+"area="+filter.area+"&"; 

    if (filter.date1!=undefined) params=params+"date1="+moment(filter.date1).format("DD-MM-YYYY")+"&";
    
    if (filter.date2!=undefined) params=params+"date2="+moment(filter.date2).format("DD-MM-YYYY")+"&";
        
    if (pageSize!=undefined) {
        params=params+"_pagesize="+pageSize+"&";
    }

    if (order!=undefined) {
        params=params+"_orderby="+order;
    }

    let response = axios.get(APP_CONFIG.BACKEND_API_URL+"sensor/list/for-user-organization/?_page="+page+"&"+params);

    return(response);
}

//------------------------------------------------------------------------------------
export const downloadCSVService = (filter) => {

    let params ="";    

    // eslint-disable-next-line
    if (filter.id!=undefined && String(filter.id).length>0) params=params+"id="+filter.id+"&";

    // eslint-disable-next-line
    if (filter.data!=undefined && String(filter.data).length>0) params=params+"data="+filter.data+"&"; 

    // eslint-disable-next-line
    if (filter.device!=undefined && String(filter.device).length>0) params=params+"device="+filter.device+"&";

    // eslint-disable-next-line
    if (filter.userCapture!=undefined && String(filter.userCapture).length>0) params=params+"user_capture="+filter.userCapture+"&";

    // eslint-disable-next-line
    if (filter.type!=undefined && String(filter.type).length>0 && String(filter.type)!="-1") params=params+"type="+filter.type+"&"; 

    // eslint-disable-next-line
    if (filter.area!=undefined && String(filter.area).length>0 && String(filter.area)!="-1") params=params+"area="+filter.area+"&"; 

    if (filter.date1!=undefined) params=params+"date1="+moment(filter.date1).format("DD-MM-YYYY")+"&";
    
    if (filter.date2!=undefined) params=params+"date2="+moment(filter.date2).format("DD-MM-YYYY")+"&";
        
    let response = axios.get(APP_CONFIG.BACKEND_API_URL+"sensor/download-csv/?"+params);

    return(response);
}

//------------------------------------------------------------------------------------
export const getSensorsStatsService = (filter) => {
    
    let params ="";    

    if (filter!=undefined) {
        // eslint-disable-next-line
        if (filter.device!=undefined && String(filter.device).length>0) params=params+"device="+filter.device+"&";

        // eslint-disable-next-line
        if (filter.data!=undefined && String(filter.data).length>0) params=params+"data="+filter.data+"&"; 

        // eslint-disable-next-line
        if (filter.type!=undefined && String(filter.type).length>0 && String(filter.type)!="-1") params=params+"type="+filter.type+"&"; 

        // eslint-disable-next-line
        if (filter.area!=undefined && String(filter.area).length>0 && String(filter.area)!="-1") params=params+"area="+filter.area+"&"; 

        if (filter.date1!=undefined) params=params+"date1="+moment(filter.date1).format("DD-MM-YYYY")+"&";
    
        if (filter.date2!=undefined) params=params+"date2="+moment(filter.date2).format("DD-MM-YYYY")+"&";    
    }

    const response = axios.get(APP_CONFIG.BACKEND_API_URL+"sensors/stats/?"+params);

    return(response);
}