import React from 'react';
import { Row, Col, message } from 'antd';
import { Pie, Column, Bar } from '@ant-design/plots';
import { observer, inject } from "mobx-react";

import "./SensorsStats.scss";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');


//#################################################################################################

class SensorsStats extends React.Component {

    //-----------------------------------------------------------------------------------------------
    constructor(props) {
        super(props);
    }
  
    //-----------------------------------------------------------------------------------------------
    componentDidMount() {

      let currentDate=moment(new Date(), "DD-MM-YYYY");

      let filter = {
          type: -1,
          data: "",
          device: "",
          area: -1,
          date1: currentDate,
          date2: currentDate
      }

        this.props.sensorsStore.doActionGetSensorsStats(filter);
    }

    //-----------------------------------------------------------------------------------------------
    componentDidUpdate() {

        if (this.props.generalStore.error != undefined && this.props.generalStore.error.length > 0) {
            message.error(this.props.generalStore.error,10);
            this.props.generalStore.doActionRemoveGeneralError();            
        }
        else if (this.props.sensorsStore.message != undefined && this.props.sensorsStore.message.length > 0) {
            message.success(this.props.sensorsStore.message,10);
            this.props.sensorsStore.doActionRemoveMessage();
        }
    }


    //-----------------------------------------------------------------------------------------------
    render() {  
        // ATENCION! Leemos aquí los mensajes de retorno para provocar que estoos eventos sean notificado cuando cambian, si no lo hacemos no recibe la notificación, aunque no los usemos para nada realmente en el render(), se usan en el componentDidUpdate()
        const errorObserver=this.props.generalStore.error; // Se asigna el valor de error a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad
        const messageObserver=this.props.sensorsStore.message; // Se asigna el valor de mensaje a una variable no usada en el render porque en caso contrario esta clase no se registra como observador de dicha propiedad

        let totalsByInsectStats=[];

        if (this.props.sensorsStore.sensorsStats.totalsByInsect!=undefined) {
          totalsByInsectStats=[
            {
              type: 'Estériles',
              value: this.props.sensorsStore.sensorsStats.totalsByInsect.sterile,
            },
            {
              type: 'Silvestres',
              value: this.props.sensorsStore.sensorsStats.totalsByInsect.wild,
            },
            {
              type: 'Hembras',
              value: this.props.sensorsStore.sensorsStats.totalsByInsect.female,
            }
          ];
        }
        
        let totalsByTypesStats = [];

        if (this.props.sensorsStore.sensorsStats.totalsByType!=undefined) {
          for (var key in this.props.sensorsStore.sensorsStats.totalsByType) {            
              let result = {type: key, value: this.props.sensorsStore.sensorsStats.totalsByType[key]}
              totalsByTypesStats.push(result);
          }
        }

        let configPie = {      
            padding: 0,  
            appendPadding: 50,        
            angleField: 'value',
            colorField: 'type',
            radius: 0.8,
            label: {
              type: 'outer',
              content: '{percentage} {name}',
            },                
            interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
        };
                    
        let configWeeks = null;
        if (this.props.sensorsStore.sensorsStats.totalsByWeek!=undefined) {

          let totalsByWeek = this.props.sensorsStore.sensorsStats.totalsByWeek.slice();
          totalsByWeek.sort(function (a, b) {
            return a.order.localeCompare(b.order);
          });

          configWeeks = {
            data: totalsByWeek,
            isStack: true,
            xField: 'date',
            yField: 'value',
            seriesField: 'insect',
            tooltip: {
              formatter: (data) => {
                return { name: data.insect, value: data.value };
              },
            },
            isGroup: true,
            columnStyle: {
              radius: [20, 20, 0, 0],
            },            
            label: {
              position: 'middle',
              layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' },
              ],
            },
            xAxis: {
              label: {
                autoRotate: false,
              },
            },
            slider: {
              start: 0,
              end: 1,
            },            
          };      
        }

        let configAreas = null;
  
        if (this.props.sensorsStore.sensorsStats.totalsByArea!=undefined) {
          configAreas = {
            data: this.props.sensorsStore.sensorsStats.totalsByArea,
            isStack: true,
            xField: 'value',
            yField: 'label',
            seriesField: 'type',
            height: 600,
            isPercent: true,
            tooltip: {
              formatter: (data) => {
                let val="0";
                if (!isNaN(data.value)) val=data.value.toFixed(2)*100;
                return { name: data.type, value: val+"%" };
              },
            },            
            label: {
              position: 'middle',
              content: (item) => {
                return item.value.toFixed(2)*100+"%";
              },
              layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' },
              ],
            },
          };      
        }        
    
        return (        
            <>
                <div className="sensors-stats">

                    {this.props.sensorsStore.sensorsStats.totalsByInsect &&
                      <Row>
                          <Col xs={{ span: 24 }}>
                            <h2>Total de capturas: {this.props.sensorsStore.sensorsStats.totalsByInsect.total}</h2>
                            <h2>Total datos recibidos: {this.props.sensorsStore.sensorsStats.totalsByInsect.count} 
                            { this.props.sensorsStore.sensorsStats.totalsByInsect.count>0 &&
                              <span> ({Number((this.props.sensorsStore.sensorsStats.totalsByInsect.total/this.props.sensorsStore.sensorsStats.totalsByInsect.count).toFixed(2))} capturas de media)</span>
                            }

                            </h2>
                          </Col>              
                      </Row>
                    }
                     
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <div className="pie-panel-content">          
                                <div className="review-info-panel-data"> 
                                <h1>Capturas por tipo de insecto</h1>
                                    <div className="review-info-panel-mid">
                                    <Pie {...configPie} data={totalsByInsectStats} />
                                    </div>
                                </div>  
                            </div>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <div className="pie-panel-content">          
                                <div className="review-info-panel-data"> 
                                <h1>Capturas por tipo de dispositivo</h1>
                                    <div className="review-info-panel-mid">
                                    <Pie {...configPie} data={totalsByTypesStats} />
                                    </div>
                                </div>  
                            </div>
                        </Col>

                    </Row >

                    {configWeeks &&
                      <Row>
                          <Col xs={{ span: 24 }}>
                            <div className="areas-panel-content">    
                                  <div className="stats-area-data"> 
                                    <h1>Capturas por semana</h1>      
                                    <Column {...configWeeks} />
                                  </div>
                            </div>                      
                          </Col>
                      </Row>
                    }

                    {configAreas &&
                      <Row>
                          <Col xs={{ span: 24 }}>
                            <div className="areas-panel-content">    
                                  <div className="stats-area-data"> 
                                    <h1>Capturas por áreas geográficas</h1>      
                                    <Bar {...configAreas} />
                                  </div>
                            </div>                      
                          </Col>
                      </Row>
                    }                    
                </div> 
            </>    
        );
    }
}

export default inject('sensorsStore','devicesStore','generalStore')(observer(SensorsStats))
