import React from "react";
import { Layout, Row, Col, Tabs, Button, Collapse, message } from 'antd';
import { ReloadOutlined, SyncOutlined } from '@ant-design/icons';
import Sticky from 'react-stickynode';
import { observer, inject } from "mobx-react";

import AppHeader from "../components/AppHeader.js";
import NetworkStatus from "../components/NetworkStatus.js";
import SensorsStats from "../components/SensorsStats.js";
import SensorsStatsFilter from "../components/SensorsStatsFilter.js";
import * as Actions from "../stores/actionFlags.js";

import "./ControlPanel.scss";

const { Footer, Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;

//#################################################################################################

class ControlPanel extends React.Component {

  //-----------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      collapsed: "1"
    }    
  }
      
  //-----------------------------------------------------------------------------------------------
  handleTabChange = (key) => {
    
    this.props.generalStore.doActionSetControlPanelActiveTab(key);    
  }

  //-----------------------------------------------------------------------------------------------
  handleFilterCollapsed = (event) => {

    // eslint-disable-next-line
    this.setState({ collapsed: this.state.collapsed == "1" ? "0" : "1" });
  }
    
  //-----------------------------------------------------------------------------------------------
  handleRefreshData = () => {

    if (this.props.generalStore.controlPanelActiveTab==1) {
      this.props.devicesStore.doActionGetNetworkStatus();
      message.success("Obteniendo los datos de la red...");
      window.scrollTo(0, 0);
    }

    if (this.props.generalStore.controlPanelActiveTab==2) {
      this.props.sensorsStore.doActionGetSensorsStats(this.props.sensorsStore.sensorsStatsFilter);
      message.success("Obteniendo los datos de los sensores...");
      window.scrollTo(0, 0);
    }
  }  

//-----------------------------------------------------------------------------------------------
  render() {
    const controlPanelActiveTab = this.props.generalStore.controlPanelActiveTab;
    
    const isFetchingData = this.props.generalStore.processing.includes(Actions.GET_NETWORK_STATUS || Actions.GET_SENSORS_STATS);

    // Necesario para hacer el menú de tipo "sticky"
    const renderTabBar = (props, DefaultTabBar) => (
      <Sticky innerZ={90}>
        {({ style }) => (
          <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
        )}
      </Sticky>
    )

    return (
      <>
        <Layout className="control-panel-layout">
          <AppHeader/>

          <Content>
            <Row justify="center" align="top">
              <Col xs={{ span: 24 }}>

                  <Tabs renderTabBar={renderTabBar} className="tab-menu" activeKey={controlPanelActiveTab} onChange={this.handleTabChange} centered>

                    <TabPane tab="ESTADO DE LA RED" key="1">
                      <NetworkStatus />
                    </TabPane>

                    <TabPane tab="DATOS RECOGIDOS" key="2">
                      <SensorsStats />
                    </TabPane>

                  </Tabs>
                

              </Col>
            </Row>
          </Content>

          <Footer id="footer">

          { !isFetchingData &&
            <Button className="reloading" type="primary" shape="circle" size="middle" title="Recargar datos" icon={<ReloadOutlined />} onClick={(event) => { this.handleRefreshData() }} />
          }
          { isFetchingData &&
            <Button className="reloading" type="primary" shape="circle" size="middle" disabled style={{ backgroundColor: `#AAAAAA` }}><SyncOutlined spin /></Button>
          }       

          <Collapse className="filter" activeKey={this.state.collapsed} onChange={this.handleFilterCollapsed}>              
              {// eslint-disable-next-line
                controlPanelActiveTab == 2 &&
                <Panel header="Filtrar datos">
                  <SensorsStatsFilter />
                </Panel> 
              } 
            </Collapse>            
          </Footer>
        </Layout>
      </>
    );
  }
}

export default inject('generalStore','devicesStore', 'sensorsStore')(observer(ControlPanel))
