import generalStore from "./generalStore.js";
import usersStore from "./usersStore.js";
import devicesStore from "./devicesStore.js";
import sensorsStore from "./sensorsStore.js";

// Definición de todas los stores usados por Mobx en la aplicación

// El propósito de los stores es mantener el estado global de la aplicación, 
// Cada store se ocupa del control de una porción del estado de la aplicación

const rootStore = {
  generalStore,
  usersStore,
  devicesStore,
  sensorsStore,
};

export default rootStore;

//------------------------------------------------------------------------------------
// Esta función se usa para enviar eventos de inicio y fin de la petición asíncrona
// De esta manera es posible, por ejemplo, controlar un spinner, al iniciar la funcion se envia un flag y al terminar se envia de nuevo
export async function callServiceEventAware(flag, service, ...args) {

  generalStore.doActionStartService(flag);
  const response = await service(...args);
  generalStore.doActionEndService(flag);

  return response;
}
